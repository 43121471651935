import React, {
  useState,
  useEffect,
  Children,
  cloneElement,
  ReactElement,
  isValidElement,
} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleLeft, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
import classes from './carousel.module.scss';
import classesColor from '../../shared/sharedColorConfigurations.module.scss';
interface CarouselProps {
  children: React.ReactNode;
  autoPlay?: boolean;
  interval?: number;
  itemsPerSlide?: number;
  manualNav?: boolean;
}
const Carousel: React.FC<CarouselProps> = ({
  children,
  autoPlay = true,
  interval = 3000,
  itemsPerSlide = 3,
  manualNav = true,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const childrenArray = Children.toArray(children);
  const groupedChildren = [];

  for (let i = 0; i < childrenArray.length; i += itemsPerSlide) {
    groupedChildren.push(childrenArray.slice(i, i + itemsPerSlide));
  }

  useEffect(() => {
    if (autoPlay) {
      const timer = setInterval(() => {
        setActiveIndex(current => (current === groupedChildren.length - 1 ? 0 : current + 1));
      }, interval);

      return () => clearInterval(timer);
    }
  }, [autoPlay, interval, groupedChildren.length]);

  const goToIndex = (newIndex: number) => {
    setActiveIndex(newIndex);
  };

  const goToNext = () => {
    setActiveIndex(current => (current === groupedChildren.length - 1 ? 0 : current + 1));
  };

  const goToPrevious = () => {
    setActiveIndex(current => (current === 0 ? groupedChildren.length - 1 : current - 1));
  };

  return (
    <div className={classes.carouselContainer}>
      <div
        className={classes.carouselInner + ' my-4'}
        style={{width: `100%`}} // Adjust width dynamically
      >
        {groupedChildren.map((group, index) => (
          <div
            key={index}
            style={{
              transition: 'transform 0.7s ease-in-out',
              opacity: index === activeIndex ? 1 : 0, // Smooth transition based on active index
              visibility: index === activeIndex ? 'visible' : 'hidden',
              position: index === activeIndex ? 'relative' : 'absolute',
            }}
            className={`${classes.carouselItemWrapper} my-2`}>
            {group.map((child, idx) =>
              isValidElement(child)
                ? cloneElement(child as ReactElement, {
                    className: `${child.props.className || ''} ${classes.carouselItem}`,
                  })
                : child,
            )}
          </div>
        ))}
      </div>

      {manualNav && (
        <>
          <span onClick={goToPrevious} className=" carousel-control-prev carousel-control">
            <FontAwesomeIcon
              className={classes.sidebarColor}
              icon={faChevronCircleLeft}
              style={{position: 'absolute', left: 0, cursor: 'pointer'}}
            />
          </span>
          <span onClick={goToNext} className="carousel-control-next carousel-control">
            <FontAwesomeIcon
              className={classes.sidebarColor}
              icon={faChevronCircleRight}
              style={{position: 'absolute', right: 0, cursor: 'pointer'}}
            />
          </span>
          <div className={`${classes.carouselIndicators} my-4`}>
            {groupedChildren.map((_, index) => (
              <button
                key={index}
                onClick={() => goToIndex(index)}
                className={`${classes.indicator} ${index === activeIndex ? classes.active : ''}`}
              />
            ))}
          </div>
          <div className={classes.carouselCounter}>
            {`${activeIndex + 1} / ${groupedChildren.length}`}
          </div>
        </>
      )}
    </div>
  );
};

export default Carousel;
