import {AppDispatch} from '../../store';
import axios from '../../axios';
import {userGroupSlice} from '../../store/account/slices/userGroup.slice';
import {AddUserToGroupDto} from '../../models/strict/accountStrictModel';
import {strict} from 'assert';
const usergroupApiUrl = '/api/usergroup';
export const AddUserToGroup = (newEn: AddUserToGroupDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(usergroupApiUrl + '/AddUserToGroup', newEn);
      if (response.status >= 300) {
        dispatch(userGroupSlice.actions.userGroupFetchingError);
      } else {
        dispatch(userGroupSlice.actions.userGroupFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const DeleteUserGroup = (groupId: string, userId?: string, email?: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axios.delete(usergroupApiUrl + '/RemoveUserFromGroup', {
        params: {
          email: email,
          userId: userId,
          groupId: groupId,
        },
      });
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};

// export const UpdateStatusUserGroup = (userGroup: userGroupDto) => {
//   return async (dispatch: AppDispatch) => {
//     try {
//       const response = await axios.put('api/userGroup', userGroup);
//       if (response.status >= 300) {
//         dispatch(userGroupSlice.actions.userGroupFetchingError);
//       } else {
//         dispatch(userGroupSlice.actions.userGroupFetchingSuccess(response.data));
//       }
//     } catch (e) {
//       console.log('Error Login', e);
//     }
//   };
// };
// export const UpdateUserGroup = (userGroup: userGroupDto) => {
//   return async (dispatch: AppDispatch) => {
//     try {
//       const response = await axios.put('api/userGroup', userGroup);
//       if (response.status >= 300) {
//         dispatch(userGroupSlice.actions.userGroupFetchingError);
//       } else {
//         dispatch(userGroupSlice.actions.userGroupFetchingSuccess(response.data));
//       }
//     } catch (e) {
//       console.log('Error Login', e);
//     }
//   };
// };
