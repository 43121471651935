/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ChangeEvent, useEffect, useState} from 'react';

import {TrainingObject} from '../../../../models/strict/sessionStrictModel';
import {SetDto, TrainingDto} from '../../../../models/trainingModel';
import {TrainingList} from '../../trainings/trainingsList.component';
import AddEditTrainingComponent from '../../trainings/addEditTraining/addEditTraining.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
export interface AddEditSetComponentProperties {
  SetInput: SetDto;
  saveHandler: (params: any) => any;
}
export default function AddEditSetComponent({
  SetInput,
  saveHandler,
}: AddEditSetComponentProperties) {
  const [set, setSet] = useState<SetDto>(SetInput);
  const [newTraining, setNewTraining] = useState<TrainingDto>(new TrainingObject());
  useEffect(() => {
    setSet(SetInput);
  }, [SetInput]);
  return (
    <div key={set.id} className="">
      <AddEditTrainingComponent
        trainingInput={newTraining}
        saveHandler={training => {
          setSet(prevSet => ({
            ...prevSet,
            trainings: [...(prevSet.trainings || []), training],
          }));
          setNewTraining(new TrainingObject());
        }}
      />
      {set.trainings && (
        <TrainingList
          list={set.trainings}
          clickDeleteHandler={(training, index) => {
            console.log(index);
            setSet(prevState => ({
              ...prevState,
              trainings: prevState.trainings!.filter((_, i) => i !== index),
            }));
          }}></TrainingList>
      )}
      {set.trainings.length > 0 && (
        <div
          className={classesModal.addBtn}
          style={{width: '100%'}}
          onClick={() => {
            saveHandler(set);
            setSet(prevSet => ({
              ...prevSet,
              trainings: [],
            }));
          }}>
          +
        </div>
      )}
    </div>
  );
}
