import React, {createContext, useContext, useState, ReactNode} from 'react';
import {ThemeName} from './themeProvider';

interface ThemeContextType {
  theme: ThemeName;
  toggleTheme: (themeName: ThemeName) => void;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
