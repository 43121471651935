import React, {ChangeEvent, useState, useRef, useEffect} from 'react';
import {FoodDTO, Macronutrients} from '../../../../models/mealModel';
import {EditMacronutrientsComponent} from '../../macronutries/editMacronutrientsComponent';
import {useTranslation} from 'react-i18next';
import modalClasses from './../../../common/modal/styles/modalShared.module.scss';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import {AddUpdatePortionsComponent} from '../addUpdatePortions/addUpdatePortions.component';
import ModalWrapper from '../../../common/modal/modalWrapper/modalWrapper.component';
import classColor from '../../../shared/sharedColorConfigurations.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBarcode,
  faBuilding,
  faBuildingColumns,
  faCopy,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import FoodInput from '../foodInput/foodInput';
import TranslationWrapperComponent from '../../../translation/translationWrapper.component';
import {TranslationDto} from '../../../../models/strict/translationStrictModel';
import {Prev} from 'react-bootstrap/esm/PageItem';
import {FoodConstructorComponent} from '../../foodConstructor/foodConstructor.component';
import classes from './AddEditFoodComponent.module.scss';
import BarCodeReader from '../../../common/barCodeReader/barCodeReader.component';
import {getFoodByCode, getFoodByCodeExternalService} from '../../../../actions/meals/food.actions';
import {useAppDispatch} from '../../../../hooks/redux';
import {MacronutrientsObject} from '../../../../models/strict/mealStrictModel';
import ConfirmModal from '../../../common/modal/confirmAction.component';
import colorClasses from '../../../shared/sharedColorConfigurations.module.scss';
import {HelperSection} from '../../../common/helperSection/helperSection.component';
export interface AddEditFoodComponentProperties {
  food: FoodDTO;
  barCode?: string | undefined;
  saveHandler: (params: FoodDTO, translation: TranslationDto | undefined) => void;
  macronutrients?: Macronutrients;
}

// Define the type of the ref for EditMacronutrientsComponent
interface EditMacronutrientsComponentRef {
  getData: () => Macronutrients;
  setData: (macronutients: Macronutrients) => void;
}

export function AddEditFoodComponent({
  food,
  barCode,
  saveHandler,
  macronutrients,
}: AddEditFoodComponentProperties) {
  const [currentFood, setCurrentFood] = useState<FoodDTO>(food);
  const [isModal, setModal] = useState<boolean>(false);
  const [isBarcode, setIsBarCode] = useState<boolean>(false);
  const [isThirdPartyFood, setIsThirdPartyFood] = useState<boolean>(false);
  const [isConstruct, setIsConstruct] = useState<boolean>();
  const [translation, setTranslation] = useState<TranslationDto>();
  const dispatch = useAppDispatch();
  // Define the ref with the correct type instead of using `any`
  const macronutrientsChildComponentRef = useRef<EditMacronutrientsComponentRef>(null);

  function Save() {
    const childData = macronutrientsChildComponentRef.current?.getData();
    currentFood.macronutrients = childData;

    saveHandler(currentFood, translation);
  }
  useEffect(() => {
    setTranslation(new TranslationDto('', []));
  }, []);
  useEffect(() => {
    setCurrentFood(prev => ({...prev, barCode: barCode}));
  }, [barCode]);
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentFood(prev => ({...prev, [event.target.name]: event.target.value}));
  };
  const addPortion = (value: number) => {
    setCurrentFood(prevFood => ({
      ...prevFood,
      portions: prevFood.portions
        ? prevFood.portions.includes(value)
          ? prevFood.portions
          : [...prevFood.portions, value]
        : [value],
    }));
  };

  const removePortion = (value: number) => {
    setCurrentFood(prevFood => ({
      ...prevFood,
      portions: prevFood.portions ? prevFood.portions.filter(p => p !== value) : [],
    }));
  };
  useEffect(() => {
    if (macronutrients != null) {
      macronutrientsChildComponentRef.current?.setData(macronutrients);
    }
  }, [macronutrients]);
  const {t} = useTranslation();

  return (
    <div className="relative col-12 border-primary-custom pt-4  darkModalBackground color-white custom-border   px-4">
      {isModal && (
        <ModalWrapper
          handleClose={() => {
            setModal(false);
          }}
          isOpen={isModal}>
          <div
            style={{minHeight: 300}}
            className="relative w-[222px] col-12 border-primary-custom pt-4 mb-0 pb-4 darkModalBackground color-white custom-border   px-4">
            <h1>{t('CopyMacro')}</h1>
            <HelperSection helperValue={'HelperForCopy'}></HelperSection>
            <FoodInput
              getNewFood={(foodNew: FoodDTO) => {
                if (foodNew?.macronutrients != undefined && foodNew.macronutrients != null) {
                  macronutrientsChildComponentRef.current?.setData(foodNew.macronutrients);
                  console.log(foodNew);
                }
                setModal(false);
              }}
            />
          </div>
        </ModalWrapper>
      )}
      {isThirdPartyFood && (
        <ConfirmModal
          isOpen={isThirdPartyFood}
          handleAgree={() => {
            setIsThirdPartyFood(false);
          }}
          textConfirmation="ConfirmationAddThirdPartyFood"
          handleClose={() => {
            setIsThirdPartyFood(false);
            macronutrientsChildComponentRef.current?.setData(new MacronutrientsObject());
            setIsBarCode(false);
          }}></ConfirmModal>
      )}
      {isBarcode && (
        <ModalWrapper
          handleClose={() => {
            setIsBarCode(false);
          }}
          isOpen={isBarcode}>
          <BarCodeReader
            onScan={(code: string) => {
              setIsBarCode(false);
              dispatch(getFoodByCode(code)).then(result => {
                if (result != null && typeof result === 'object') {
                  setCurrentFood(prev => ({...prev, barCode: code}));
                } else {
                  dispatch(getFoodByCodeExternalService(code)).then(parsedNewProduct => {
                    setIsBarCode(false);
                    macronutrientsChildComponentRef.current?.setData(
                      new MacronutrientsObject(
                        parsedNewProduct?.data.product.nutriments['proteins_100g'],
                        parsedNewProduct?.data.product.nutriments['fat_100g'],
                        parsedNewProduct?.data.product.nutriments['carbohydrates_100g'],
                        parsedNewProduct?.data.product.nutriments['energy-kcal_100g'],
                      ),
                    );

                    setIsThirdPartyFood(true);
                  });
                }
              });
            }}
          />
        </ModalWrapper>
      )}
      {isConstruct && (
        <ModalWrapper
          handleClose={() => {
            setIsConstruct(false);
          }}
          isOpen={isConstruct}>
          <FoodConstructorComponent
            calculateHandler={f => {
              macronutrientsChildComponentRef.current?.setData(f);
              setIsConstruct(false);
            }}
          />
        </ModalWrapper>
      )}
      <span
        key={food.id}
        onClick={event => {
          event.stopPropagation();
        }}
        className="">
        <form onSubmit={Save}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 'auto',
                width: 'fit-content',
                right: '10px',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              className="mb-4">
              <button
                className={classes.createButton + ' mr-2'}
                onClick={event => {
                  event.preventDefault();
                  setModal(true);
                }}>
                <FontAwesomeIcon className="mx-2" icon={faCopy}></FontAwesomeIcon>
                {t('Copy')}
              </button>
              <button
                className={classes.createButton + ' ml-2'}
                onClick={event => {
                  event.preventDefault();
                  setIsConstruct(true);
                }}>
                <FontAwesomeIcon className="mx-2" icon={faLayerGroup}></FontAwesomeIcon>
                {t('Constructor')}
              </button>
            </div>
          </div>
          <div style={{color: 'var( --textColor)', textAlign: 'left'}}>
            <div className="row">
              <div className="col-6">
                <label htmlFor="name" className="mr-2 mb-2">
                  {t('Name')}
                </label>
                <input
                  type="text"
                  id="name"
                  className={classesModal.inputModal + '  w-full outline-0 '}
                  name="name"
                  onChange={changeHandler}
                />
              </div>{' '}
              <div className="col-6">
                <label htmlFor="name" className="mr-2 mb-2">
                  {t('Brand')}
                </label>
                <input
                  type="text"
                  id="brandName"
                  className={classesModal.inputModal + '  w-full outline-0 '}
                  name="brandName"
                  onChange={changeHandler}
                />
              </div>
            </div>

            <div>
              <label htmlFor="description" className="mr-2 my-2">
                {t('Description')}
              </label>
              <input
                type="text"
                id="description"
                className={classesModal.inputModal + '  w-full outline-0 '}
                name="description"
                onChange={changeHandler}
              />
            </div>
            {food.macronutrients && (
              <EditMacronutrientsComponent
                ref={macronutrientsChildComponentRef}
                macronutrients={food.macronutrients}
              />
            )}
            <div className="row col-12">
              <div className="col-6">
                <label htmlFor="name" className="mr-2 mb-2">
                  {t('Barcode')}
                </label>
                <input
                  type="text"
                  id="barcode"
                  className={classesModal.inputModal + '  w-full outline-0 '}
                  name="barcode"
                  value={currentFood.barCode}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-6">
                <button
                  style={{
                    minWidth: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                  className={classesModal.addBtn}
                  onClick={() => {
                    setIsBarCode(true);
                  }}>
                  <FontAwesomeIcon icon={faBarcode} style={{color: 'white'}}></FontAwesomeIcon>
                  <p className="my-2" style={{color: 'white'}}>
                    {t('ScanBarCode')}
                  </p>
                </button>
              </div>
            </div>
            {translation && (
              <TranslationWrapperComponent
                translation={translation}
                onAddEditTranslationHandler={newItem => {
                  setTranslation(prev => {
                    const index = prev?.translationDatas.findIndex(f => f.locale == newItem.locale);
                    if (index == -1) {
                      prev?.translationDatas.push(newItem);
                      return {
                        translationDatas: prev?.translationDatas ?? [],
                        id: prev?.id ?? '',
                      };
                    } else {
                      return {
                        translationDatas:
                          prev?.translationDatas.map(f =>
                            f.locale == newItem.locale ? newItem : f,
                          ) ?? [],
                        id: prev?.id ?? '',
                      };
                    }
                  });
                }}
                onRemoveTranslationHandler={removedItem => {
                  setTranslation(prev => ({
                    ...prev,
                    translationDatas:
                      prev?.translationDatas.filter(item => item.locale !== removedItem.locale) ||
                      [],
                    id: prev?.id ?? '',
                  }));
                }}
              />
            )}
            <AddUpdatePortionsComponent
              portions={currentFood.portions ?? []}
              addHandler={addPortion}
              removeHandler={removePortion}
            />
          </div>
        </form>
        <span className={modalClasses.saveBtn + ' mt-4'} onClick={Save}>
          +
        </span>
      </span>
    </div>
  );
}
