import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TranslationDataDto, TranslationDto} from '../../models/strict/translationStrictModel';
import TranslationItemComponent from './translationItem/translationItem.component';
import TranslationAddEditComponent from './translationAdd/translationAddEdit.component';
import colorClasses from '../shared/sharedColorConfigurations.module.scss';
import {HelperSection} from '../common/helperSection/helperSection.component';
export interface TranslationWrapperComponentProperties {
  translation: TranslationDto;
  onAddEditTranslationHandler: (translation: TranslationDataDto) => void;
  onRemoveTranslationHandler: (translation: TranslationDataDto) => void;
}

export default function TranslationWrapperComponent({
  translation,
  onAddEditTranslationHandler,
  onRemoveTranslationHandler,
}: TranslationWrapperComponentProperties) {
  const {t} = useTranslation();
  const [addedTranslation, setAddedTranslation] = useState<TranslationDataDto>(
    new TranslationDataDto(undefined, '', '', ''),
  );
  return (
    <div
      className="my-4 relative"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%',
      }}>
      <HelperSection helperValue={'Translations'}></HelperSection>
      {translation?.translationDatas?.map((f, index) => {
        return (
          <div key={index}>
            <TranslationItemComponent
              translation={f}
              onEditTranslationHandler={(editedTranslation: TranslationDataDto) => {
                onAddEditTranslationHandler(editedTranslation);
              }}
              onRemoveTranslationHandler={(deletedTranslation: TranslationDataDto) => {
                onRemoveTranslationHandler(deletedTranslation);
              }}></TranslationItemComponent>
          </div>
        );
      })}
      <TranslationAddEditComponent
        translation={addedTranslation}
        onAddEditTranslationHandler={(addTranslation: TranslationDataDto) => {
          onAddEditTranslationHandler(addTranslation);
          setAddedTranslation(new TranslationDataDto(undefined, '', '', ''));
        }}
      />
    </div>
  );
}
