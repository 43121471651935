import React, {ChangeEvent, useEffect, useState} from 'react';
import {RestaurantPortionDTO} from '../../../models/mealModel';
import {RestaurantPortionComponent} from './RestaurantPortionComponent/restaurantPortions.component';
export interface RestaurantPortionListProperties {
  list?: RestaurantPortionDTO[];
}
export function RestaurantPortionListComponent({list}: RestaurantPortionListProperties) {
  const [value, setValue] = useState('');

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }
  useEffect(() => {
    console.log(list);
  }, []);
  function renderRestaurantPortionList() {
    if (list == null || list.length === 0) {
      return <p className="text-center">No results!</p>;
    }

    return list.map(restaurantPortion => (
      <RestaurantPortionComponent
        key={restaurantPortion.id}
        restaurantPortion={restaurantPortion}
      />
    ));
  }

  return <div className="mb-4 relative">{<ul>{renderRestaurantPortionList()}</ul>}</div>;
}
