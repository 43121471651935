import {AppDispatch} from '../../store';
import axios from '../../axios';
import {queries} from '@testing-library/react';
import {sessionSlice} from '../../store/trainings/slices/session.slice';
import {CreateSessionDto, SessionDto} from '../../models/trainingModel';
import {DateTime} from 'luxon';
import {TrainingApi} from './constant';
export const SESSION_API_URL = TrainingApi.TrainingApiPrefix + '/sessions';
export const getSessions = (
  userId?: string,
  from?: string,
  to?: string,
  options?: {signal?: AbortSignal},
  count?: number,
  currentPage?: number,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<SessionDto[]>(SESSION_API_URL + '/filter', {
        paginationRequest: {
          currentPage: currentPage ?? 1,
          pageSize: count ?? 50,
        },
        from: from,
        to: to,
        userId: userId,
      });
      if (response.status >= 300) {
        dispatch(sessionSlice.actions.sessionFetchingError);
      } else {
        console.log(response.data);
        dispatch(sessionSlice.actions.sessionsFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getSession = (sessionId: string, options?: {signal?: AbortSignal}) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<SessionDto>(SESSION_API_URL + '/' + sessionId, {
        signal: options?.signal,
      });
      return response.data;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getSessionsStreak = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<number>(SESSION_API_URL + '/' + 'GetSessionStreak', {});
      if (response.status >= 300) {
        dispatch(sessionSlice.actions.sessionFetchingError);
      } else {
        console.log(response.data);
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const deleteSession = (sessionId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete(SESSION_API_URL + '/' + sessionId);
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addSessions = (session: CreateSessionDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      session.dateTime = DateTime.now().toUTC().toISO()!;
      const response = await axios.post<SessionDto[]>(SESSION_API_URL, session);
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
