import {AppDispatch} from '../../store';
import axios from '../../axios';
import {TranslationDataDto, TranslationDto} from '../../models/strict/translationStrictModel';

const translationApiUrl = '/api/translation';
const foodApiUrl = '/api/mealapi/foods/';

export const addTranslationToFood = (foodId: string, translationDto: TranslationDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(`${foodApiUrl}/addTranslation/${foodId}`, translationDto);
      return response.data;
    } catch (e) {
      console.log('Error adding translation', e);
      return null;
    }
  };
};

export const addOrUpdateTranslationData = (
  foodId: string,
  translationDataDto: TranslationDataDto,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put(
        `${translationApiUrl}/addUpdateTranslationData/${foodId}`,
        translationDataDto,
      );
      return response.data;
    } catch (e) {
      console.log('Error updating translation data', e);
      return null;
    }
  };
};
