import React, {ChangeEvent, useEffect, useState} from 'react';

import {RestaurantDTO} from '../../../models/mealModel';
import CityInput from '../../common/cityInput.component';
export interface AddEditRestaurantComponentProperties {
  restaurant: RestaurantDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
}
export default function AddEditRestaurantComponent({
  restaurant,
  saveHandler,
}: AddEditRestaurantComponentProperties) {
  const [foodRestaurant, setFoodRestaurant] = useState<RestaurantDTO>(restaurant);
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(foodRestaurant);
    setFoodRestaurant(prev => ({...prev, [event.target.name]: event.target.value}));
  };
  const setCityOfRestaurant = (event: ChangeEvent<HTMLInputElement>) => {
    setFoodRestaurant(previous => ({
      ...previous,
      location: {
        ...previous?.location,
        city: event.target.value,
      },
    }));
  };
  return (
    <div>
      <li
        key={restaurant.id}
        // onClick={() => navigate(`/airport/${airport.id}`)}
        className="cursor-pointer hover:bg-gray-500 hover:text-white py-2 px-4">
        <div>
          <label htmlFor="name" className="mr-2">
            name
          </label>
          <input type="text" id="name" className="border" name="name" onChange={changeHandler} />
        </div>
        <div>
          <label htmlFor="description" className="mr-2">
            description
          </label>
          <input
            type="text"
            id="description"
            className="border"
            name="description"
            onChange={changeHandler}
          />
        </div>
        <div>
          <label htmlFor="description" className="mr-2">
            city
          </label>
          <input
            type="text"
            id="city"
            className="border"
            name="city"
            onChange={setCityOfRestaurant}
          />
        </div>
        {/* //<CityInput PushCity={setCityOfRestaurant} /> */}
        <span
          onClick={() => {
            saveHandler(foodRestaurant);
          }}>
          Save
        </span>
      </li>
    </div>
  );
}
