// Core i18next library.
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import enTranslation from '../public/locales/en/translation.json';
import uaTranslation from '../public/locales/ua/translation.json';
i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    resources: {
      en: {translation: enTranslation}, // English translation
      fr: {translation: uaTranslation}, // French translation
    },
    lng: 'en', // Default language
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
  });

export default i18n;
