import React, {ReactNode, useEffect, useState} from 'react';
import {ThemeContext} from './themeContext';
import {useAppDispatch} from '../../../hooks/redux';
import {selectCurrentUserId} from '../../../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import {SetThemeDto} from '../../../models/strict/accountStrictModel';
import {updateTheme} from '../../../actions/account/account.actions';

interface ThemeProviderProps {
  children: ReactNode;
}
const themes = ['light', 'dark', 'forest', 'royal', 'solar', 'ocean', 'bloody'] as const;
export type ThemeName = (typeof themes)[number];
export const ThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {
  const [theme, setTheme] = useState<ThemeName>('bloody');
  const storageVault = 'theme';
  const dispatch = useAppDispatch();
  const currentUserId = useSelector(selectCurrentUserId);
  useEffect(() => {
    const localTheme = localStorage.getItem(storageVault) as ThemeName | null;
    if (localTheme && themes.includes(localTheme)) {
      document.documentElement.setAttribute('data-theme', localTheme);
      setTheme(localTheme);
    }
  }, []);

  const toggleTheme = (themeName: ThemeName) => {
    document.documentElement.setAttribute('data-theme', themeName);
    localStorage.setItem(storageVault, themeName);
    dispatch(updateTheme(new SetThemeDto(currentUserId, themeName)));
    setTheme(themeName);
  };

  return <ThemeContext.Provider value={{theme, toggleTheme}}>{children}</ThemeContext.Provider>;
};
