import React, {ChangeEvent, useEffect, useState} from 'react';
import classes from './restaurantPortion.module.css';
import {RestaurantPortionDTO} from '../../../../models/mealModel';
import {MacronutrientsComponent} from '../../common/macronutrientsComponent/macronutrients.component';
export interface RestaurantPortionComponentProperties {
  restaurantPortion?: RestaurantPortionDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickHandler?: (params: any) => any;
  weight?: number;
}
export function RestaurantPortionComponent({
  restaurantPortion,
  onClickHandler,
  weight,
}: RestaurantPortionComponentProperties) {
  if (restaurantPortion != null) {
    return (
      <span
        key={restaurantPortion.id}
        onClick={() => {
          if (onClickHandler != null) {
            onClickHandler(restaurantPortion);
          }
        }}
        className="cursor-pointer hover:bg-gray-500 hover:text-white py-2 px-4">
        <span style={{margin: 10}} className={classes.RestaurantPortionName}>
          {restaurantPortion.food!.name}{' '}
          <span style={{margin: 10}} className={classes.RestaurantPortionDescription}>
            {restaurantPortion.food!.description}
          </span>
        </span>
        <MacronutrientsComponent
          macronutrients={restaurantPortion.food?.macronutrients}
          weight={restaurantPortion.weight}
        />
      </span>
    );
  } else {
    return <span> </span>;
  }
}
