import React, {ChangeEvent, useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './helperSection.module.scss';
export interface HelperSectionProperties {
  helperValue: string;
}

export function HelperSection({helperValue}: HelperSectionProperties) {
  const {t} = useTranslation();

  return <h3 className={classes.helperTitle}>{t(helperValue)}</h3>;
}
