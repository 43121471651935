import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '../../../../hooks/debounce';
import axios from '../../../../axios';
import classes from './trainingDescriptionInput.module.scss';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import {TrainingDescriptionDto} from '../../../../models/trainingModel';
import {getTrainingDescriptions} from '../../../../actions/trainings/trainingDescription.action';
import {TrainingDescriptionObject} from '../../../../models/strict/sessionStrictModel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import classesComponent from '../../../shared/sharedComponent.module.scss';
//  '../../../../../../../shared/sharedComponent.module.scss';
export interface TrainingDescriptionInputProperties {
  getNewtrainingDescription: (params: TrainingDescriptionDto) => void;
  restaurantId?: string;
  userId?: string;
}
export default function TrainingDescriptionInput({
  getNewtrainingDescription,
}: TrainingDescriptionInputProperties) {
  const [value, setValue] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const debounced = useDebounce<string>(value, 500);
  const [isSearch, setIsSearch] = useState(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const {trainingDescriptions} = useAppSelector(state => state.trainingDescriptionReducer);
  async function searchtrainingDescriptions(search: string) {
    dispatch(getTrainingDescriptions(search));
  }

  useEffect(() => {
    if (debounced.length >= 3) {
      setIsSearch(true);
      searchtrainingDescriptions(debounced).then(() => {
        setIsSearch(false);
        setDropdown(true);
      });
    } else {
      setDropdown(false);
    }
  }, [debounced]);

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    if (isSelected) {
      setIsSelected(false);
    }
  }

  function renderDropdown() {
    if (trainingDescriptions.length === 0 && !isSearch) {
      return <p className="text-center">No results!</p>;
    }
    if (trainingDescriptions.length === 0 && isSearch) {
      return <p>load</p>;
    }

    if (!isSelected) {
      return trainingDescriptions.map(trainingDescription => (
        <div
          key={trainingDescription.id}
          onClick={() => {
            if (trainingDescription != null && trainingDescription.name) {
              getNewtrainingDescription(trainingDescription);
              setValue(trainingDescription.name);
              setIsSelected(true);
            }
          }}
          className="cursor-pointer primaryBackground hover:bg-gray-500 hover:text-white py-2 px-4">
          <span style={{color: 'white', textTransform: 'uppercase'}}>
            {trainingDescription.name}
          </span>
          <div className={classes.trainingDescriptionDescription + ' w-full color-grey'}>
            {trainingDescription.description}
          </div>
        </div>
      ));
    } else {
      return <></>;
    }
  }
  const {t} = useTranslation();
  return (
    <div className="mb-4 relative">
      <label htmlFor="weight" className="my-4">
        {t('Training')}
      </label>
      <input
        className={classesComponent.transparentInputGrey + '   px-4 py-2 w-full outline-0 h-[42px]'}
        type="text"
        onChange={changeHandler}
        value={value}
        placeholder="Search for training ..."
      />
      {value?.length > 3 && trainingDescriptions.length == 0 && (
        <span
          onClick={() => {
            const newTrainingDescription = new TrainingDescriptionObject();
            newTrainingDescription.name = value;
            getNewtrainingDescription(newTrainingDescription);
          }}>
          <div>
            <FontAwesomeIcon icon={faSave} />
          </div>
        </span>
      )}
      {dropdown && (
        <div className={classes.cus + ' ' + classes.dropdownItems}>{renderDropdown()}</div>
      )}
    </div>
  );
}
