import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import MealList from '../../components/meals/meals/mealList';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getMeals} from '../../actions/meals/meal.action';
import {useSelector} from 'react-redux';
import {TrainingPlanDto} from '../../models/trainingModel';
import {
  addTrainingPlans,
  deleteTrainingPlan,
  getTrainingPlan,
  getTrainingPlans,
} from '../../actions/trainings/trainingPlan.action';
import TrainingPlanWrapper from '../../components/trainings/trainingPlan/trainingPlanWrapper/trainingPlanWrapper.component';
import GlobalLoader from '../../components/common/loader/globalLoader/globalLoader.component';
import {selectCurrentUserId} from '../../store/account/slices/login.slice';
import classes from './trainingPlan.module.scss';
function TrainingPlanPage() {
  const dispatch = useAppDispatch();
  const [trainingPlans, SetTrainingPlans] = useState<TrainingPlanDto[]>();
  const userId = useSelector(selectCurrentUserId);
  const currentUserId = useSelector(selectCurrentUserId);
  const [IsLoading, setIsLoading] = useState<boolean>(false);
  const refreshTrainingPlan = () => {
    setIsLoading(true);
    dispatch(getTrainingPlans()).then(result => {
      setIsLoading(false);
      SetTrainingPlans(result);
    });
  };
  useEffect(() => {
    refreshTrainingPlan();
  }, []);

  if (IsLoading) {
    return <GlobalLoader></GlobalLoader>;
  }
  return (
    <div
      className={classes.wrapper}
      style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <TrainingPlanWrapper
        IsShowAddIcon={true}
        list={trainingPlans}
        onDeleteTrainingPlanHandler={trainingPlanId => {
          setIsLoading(true);
          dispatch(deleteTrainingPlan(trainingPlanId)).then(f => {
            refreshTrainingPlan();
          });
        }}
        onSelectTrainingPlanHandler={function (trainingPlan: TrainingPlanDto): void {
          throw new Error('Function not implemented.');
        }}
      />
    </div>
  );
}

export default TrainingPlanPage;
