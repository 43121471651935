import React from 'react';
import './globalLoader.component.scss'; // Import CSS for styling the loader

const GlobalLoader = () => {
  return (
    <div className="loader-container primaryBackground">
      <div className="loader"></div>
    </div>
  );
};

export default GlobalLoader;
