import {UserInfoDTO} from '../models/accountModel';

export function getLastDate(record: Record<string, number>): string | null {
  let lastDate: string | null = null;

  for (const date in record) {
    if (!lastDate || date > lastDate) {
      lastDate = date;
    }
  }

  return lastDate;
}
export function getLastDateForUser(user: UserInfoDTO): string | null {
  if (user.weights != null) {
    return getLastDate(user.weights);
  }
  return null;
}
