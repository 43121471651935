import React from 'react';
import styles from './circleInfoPanel.module.scss'; // Import the CSS module
import CircleProgress from '../circleProgress/circleProgress.component';
import classesColor from '../../shared/sharedColorConfigurations.module.scss';

interface CircleInfoPanelProps {
  value: number;
  maxValue: number;
  text: string;
  size: number;
}

export default function CircleInfoPanel({value, maxValue, text, size}: CircleInfoPanelProps) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <CircleProgress value={value} maxValue={maxValue} text={text} size={size}></CircleProgress>
    </div>
  );
}
