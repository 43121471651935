import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {AuthenticationTokenModel, UserInfoDTO} from '../../../models/accountModel';
import {RootState} from '../..';
import {UserInfoObject} from '../../../models/strict/accountStrictModel';
interface userState {
  user: UserInfoDTO;
  users: UserInfoDTO[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: userState = {
  user: new UserInfoObject(),
  users: [],
  count: 0,
  loading: false,
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    usersFetching(state) {
      state.loading = true;
    },
    usesrFetchingSuccess(state, action: PayloadAction<UserInfoDTO[]>) {
      state.error = '';
      state.users = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    usersFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    userFetching(state) {
      state.loading = true;
    },
    userFetchingSuccess(state, action: PayloadAction<UserInfoDTO>) {
      state.error = '';
      state.user = action.payload;
      state.loading = false;
    },
    userFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export const selectUsers = (state: RootState) => state.userReducer.users;
export const selectUser = (state: RootState) => state.userReducer.user;

export default userSlice.reducer;
