import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {ScheduledTrainingPlanDto} from '../../../models/trainingModel';
import {ScheduledTrainingPlanObject} from '../../../models/strict/sessionStrictModel';
interface ScheduledTrainingPlanState {
  ScheduledTrainingPlan: ScheduledTrainingPlanDto;
  ScheduledTrainingPlans: ScheduledTrainingPlanDto[];
  ScheduledTrainingPlansContainer: ScheduledTrainingPlanDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: ScheduledTrainingPlanState = {
  ScheduledTrainingPlan: new ScheduledTrainingPlanObject(),
  ScheduledTrainingPlans: [],
  ScheduledTrainingPlansContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const scheduledTrainingPlanSlice = createSlice({
  name: 'ScheduledTrainingPlan',
  initialState,
  reducers: {
    ScheduledTrainingPlanFetching(state) {
      state.loading = true;
    },
    ScheduledTrainingPlanFetchingSuccess(state, action: PayloadAction<ScheduledTrainingPlanDto>) {
      state.error = '';
      state.ScheduledTrainingPlan = action.payload;
      state.loading = false;
    },
    ScheduledTrainingPlanFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    ScheduledTrainingPlansFetchingSuccess(
      state,
      action: PayloadAction<ScheduledTrainingPlanDto[]>,
    ) {
      state.error = '';
      state.ScheduledTrainingPlans = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    ScheduledTrainingPlansFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default scheduledTrainingPlanSlice.reducer;
