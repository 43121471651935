import {
  DateTimePickerSlotsComponentsProps,
  TimePickerSlotsComponentsProps,
} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';

export class DateInputProps {
  static readonly timeSlots: TimePickerSlotsComponentsProps<DateTime> = {
    textField: {
      style: {color: 'white'},
      sx: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white',
        },
        '& .MuiInputBase-input, .MuiInputLabel-root ,  .MuiIconButton-root': {
          color: 'white',
        },
      },
    },
  };
  static readonly dateTimeSlots: DateTimePickerSlotsComponentsProps<DateTime> = {
    textField: {
      style: {color: 'white'},
      sx: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white',
        },
        '& .MuiInputBase-input, .MuiInputLabel-root ,  .MuiIconButton-root': {
          color: 'white',
        },
      },
    },
  };
}
