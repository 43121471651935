import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import MealList from '../../components/meals/meals/mealList';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getMeals} from '../../actions/meals/meal.action';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../store/account/slices/login.slice';
import {getCurrentUserInfo} from '../../actions/account/account.actions';
import {NavigationCalendar} from '../../components/navigation/navigationCalendar';
import {DateTime} from 'luxon';
import AddWeightUserComponent from '../../components/account/weight/addWeightUser.component';
import {shortCommonDateFormat} from '../../constants/common/dateFormat';
import {getLastDateForUser} from '../../utils/accountUtil';
import {MealDTO} from '../../models/mealModel';
import {groupBy} from '../../utils/collectionUtils';
import {MealDayPerPage} from '../../models/strict/mealStrictModel';
import {CalorieDeficitComponent} from '../../components/meals/common/calorieDeficitComponent/calorieDeficit.component';
import ModalWrapper from '../../components/common/modal/modalWrapper/modalWrapper.component';
import {CreateSessionDto, SessionDto, TrainingPlanDto} from '../../models/trainingModel';
import {CreateSessionObject, TrainingPlanObject} from '../../models/strict/sessionStrictModel';
import AddEditTrainingPlanComponent from '../../components/trainings/trainingPlan/addEditTrainingPlan.component';
import {
  addTrainingPlans,
  getTrainingPlan,
  getTrainingPlans,
} from '../../actions/trainings/trainingPlan.action';
import TrainingPlanComponent from '../../components/trainings/trainingPlan/trainingPlanComponent/trainingPlan.component';

import TrainingComponent from '../../components/trainings/trainingPlan/training/training.component';
import {addSessions} from '../../actions/trainings/session.action';
import ManuallyTrainingComponent from '../../components/trainings/realTimeTraining/manuallyTraining/manuallyTraining.component';
import {TrainingPlanList} from '../../components/trainings/trainingPlan/trainingPlanList/trainingPlanList.component';
import {useTranslation} from 'react-i18next';
import {TrainingKeys} from '../../constants/training/trainingKeys';
import {SessionState} from 'http2';
import {StateOfTraining} from '../../models/common/stateOfTraining';
import classes from '../../components/shared/sharedButton.module.scss';
import sharedColor from '../../components/shared/sharedColorConfigurations.module.scss';
function RealTimeTrainingPage() {
  const [isManualy, SetIsManualy] = useState<boolean>(false);
  const [trainingPlans, SetTrainingPlan] = useState<TrainingPlanDto[]>();
  const [selectedTrainingPlan, SetSelectedTrainingPlan] = useState<TrainingPlanDto>();
  const [unfinishedLastSession, setUnfinishedLastSession] = useState<StateOfTraining | undefined>(
    undefined,
  );
  const [isContinue, setIsContinue] = useState<boolean>(false);
  const userId = useSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  const retrieveLastSession = () => {
    const sessionStorage = localStorage.getItem(TrainingKeys.sessionKey);
    if (sessionStorage != null) {
      return JSON.parse(sessionStorage);
    }
  };
  const clearLastSession = () => {
    setUnfinishedLastSession(undefined);
    localStorage.removeItem(TrainingKeys.sessionKey);
  };
  useEffect(() => {
    dispatch(getTrainingPlans(userId)).then(result => {
      SetTrainingPlan(result);
    });
    const lastSession = retrieveLastSession();
    if (lastSession != null) {
      setUnfinishedLastSession(lastSession);
    }
  }, []);

  const {t} = useTranslation();
  if (unfinishedLastSession != null && !isContinue) {
    return (
      <div className="flex justify-content-center flex-column text-center">
        <div>{t('YouHaveUnfinishedTraining')}</div>
        <div>
          <div
            onClick={() => {
              clearLastSession();
            }}>
            {t('StartNew')}
          </div>
          <div onClick={() => setIsContinue(true)}>{t('Continue')}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="row col-12"
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}>
        {!isManualy && unfinishedLastSession == null && selectedTrainingPlan == null && (
          <div
            className="row    col-12"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '350px',
            }}>
            <div
              className=" blockBackgroundWrapperWrapper col-xs-12 col-lg-5 "
              style={{height: '100%'}}>
              <h1 style={{textAlign: 'center'}} className={sharedColor.sidebarColor}>
                {t('LetsChooseAPlan')}
              </h1>

              <TrainingPlanList
                itemsPerSlide={3}
                list={trainingPlans}
                onSelectHandler={selected => {
                  if (selected?.id != null) {
                    dispatch(getTrainingPlan(selected.id)).then(result => {
                      SetSelectedTrainingPlan(result);
                    });
                  }
                }}
              />
            </div>

            <div
              className=" blockBackgroundWrapperWrapper col-xs-12 col-lg-5 flex  align-items-center justify-content-center"
              style={{height: '100%'}}
              onClick={() => {
                SetIsManualy(true);
              }}>
              <h1 className={classes.btn} style={{textAlign: 'center'}}>
                {t('DoItInProcess')}
              </h1>
            </div>
          </div>
        )}

        {(isManualy || selectedTrainingPlan != null || unfinishedLastSession != null) && (
          <ManuallyTrainingComponent
            trainingPlan={selectedTrainingPlan}
            sessionLast={unfinishedLastSession}
          />
        )}
        {/* {selectedTrainingPlan && (
        <TrainingComponent
          trainingPlan={selectedTrainingPlan}
          finishingTrainingHandler={session => {
            session.userId = userId
            dispatch(addSessions(session))
          }}
        />
      )} */}
      </div>
    );
  }
}

export default RealTimeTrainingPage;
