import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  Food,
  FoodDTO,
  FoodMealDTO,
  Macronutrients,
  MealDTO,
  RestaurantDTO,
} from '../../../../models/mealModel';
import FoodInput from '../../foods/foodInput/foodInput';
import {FoodComponent} from '../../foods/foodComponent/foodComponent';
import RestaurantMealWrapper from '../../restaurantMeals/restaurantMealsWrapper.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding, faL, faSave, faScaleUnbalanced} from '@fortawesome/free-solid-svg-icons';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import {useTranslation} from 'react-i18next';
import Tabs from '../../../common/tabs/tabs.component';
import {useSelector} from 'react-redux';
import {getRecentMeals} from '../../../../actions/meals/meal.action';
import {selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import RecentFoodComponent from '../../recentFood/recentFood.component';
import {AddEditFoodComponent} from '../../foods/foodAddUpdateComponent/AddEditFoodComponent';
import {
  FoodDTOObject,
  MacronutrientsObject,
  WeightModifierDto,
} from '../../../../models/strict/mealStrictModel';
import {
  addFood,
  getFoodByCode,
  getFoodByCodeExternalService,
} from '../../../../actions/meals/food.actions';
import {useAppDispatch} from '../../../../hooks/redux';
import RestaurantWrapper from '../../restaurants/restaurantWrapper.component';
import RestaurantInput from '../../restaurants/RestaurantInput/restaurantInput.component';
import classes from './addEditFoodMeal.module.scss';
import VerticalSlider from '../../../common/verticalSlider/verticalSlider.component';
import {SetWeightOfDay} from '../../../../actions/account/account.actions';
import ModalWrapper from '../../../common/modal/modalWrapper/modalWrapper.component';
import BarCodeReader from '../../../common/barCodeReader/barCodeReader.component';
import ConfirmModal from '../../../common/modal/confirmAction.component';
import {TranslationDto} from '../../../../models/strict/translationStrictModel';
import {addTranslationToFood} from '../../../../actions/common/translation.action';

import WeightModifiersWrapper from '../../weightModifiers/weightModifiersWrapper.component';
import AddBtn from '../../../common/buttons/addBtn.component';

export interface AddEditFoodMealComponentProperties {
  food: FoodMealDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addMealHandler: (params: MealDTO) => any;
}

export function AddEditFoodMealComponent({
  food,
  saveHandler,
  addMealHandler,
}: AddEditFoodMealComponentProperties) {
  const [currentFood, setCurrentFood] = useState<FoodMealDTO>();
  const [isThirdPartyFood, setIsThirdPartyFood] = useState<boolean>();
  const [isSelectRestaurant, setIsSelectRestaurant] = useState<boolean>();
  const [isWeightModifier, setIsWeightModifier] = useState<boolean>(false);
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>('current');
  const dispatch = useAppDispatch();
  const currentUserId = useSelector(selectCurrentUserId);
  const [recentFood, setRecentFood] = useState<FoodDTO[]>();
  const [restaurant, SetRestaurant] = useState<RestaurantDTO>();
  const [isAddOwn, SetIsAddOwn] = useState<boolean>();
  const [isBarcode, setIsBarCode] = useState<boolean>(false);
  const [barCode, setBarCode] = useState<string>();

  const [macronutients, setMacronutriens] = useState<Macronutrients>();
  const [weightModifier, setWeightModifier] = useState<WeightModifierDto>();
  useEffect(() => {
    setCurrentFood(food);
  }, [food]);

  function changeFoodWeight(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value) {
      setCurrentFood(prevState => ({
        ...prevState,
        weight: parseInt(event.target.value),
      }));
    }
  }

  function setFoodValue(newCurrentFood: FoodDTO | undefined) {
    if (newCurrentFood?.portions != null && newCurrentFood?.portions.length > 0) {
      setCurrentFood(prevState => ({
        ...prevState,
        weight: newCurrentFood.portions?.[0],
      }));
    }
    setCurrentFood(prevState => ({
      ...prevState,
      food: newCurrentFood,
    }));
  }

  function save() {
    const savedFood = currentFood;
    if (savedFood != null && weightModifier != null && weightModifier?.weight > 0) {
      savedFood.weight! -= weightModifier?.weight;
    }
    setWeightModifier(undefined);
    saveHandler(savedFood);
  }

  const addNewFood = (foodDto: FoodDTO, translation: TranslationDto | undefined) => {
    dispatch(addFood(foodDto))
      .then(f => {
        if (f) {
          setFoodValue(f);
          if (translation != null && translation.translationDatas.length > 0) {
            dispatch(addTranslationToFood(f.id, translation));
          }
        }
      })
      .catch(e => {
        console.log('Failed to save food:', e);
      });
  };
  useEffect(() => {
    getRecentMeals({userId: currentUserId}).then(f => setRecentFood(f));
  }, []);

  return (
    <div>
      {isAddOwn && !currentFood?.food && (
        <ModalWrapper
          handleClose={() => {
            SetIsAddOwn(false);
          }}
          isOpen={isAddOwn}>
          <AddEditFoodComponent
            barCode={barCode}
            macronutrients={macronutients}
            food={new FoodDTOObject(undefined, undefined, new MacronutrientsObject())}
            saveHandler={(params: FoodDTO, translation: TranslationDto | undefined) => {
              setMacronutriens(undefined);
              setBarCode(undefined);
              addNewFood(params, translation);
              SetIsAddOwn(false);
            }}></AddEditFoodComponent>
        </ModalWrapper>
      )}
      {isSelectRestaurant && !currentFood?.food && (
        <ModalWrapper
          handleClose={() => {
            setIsSelectRestaurant(false);
          }}
          isOpen={isSelectRestaurant}>
          <RestaurantInput
            selectRestaurant={function (params: RestaurantDTO): void {
              SetRestaurant(params);
              setIsSelectRestaurant(false);
            }}></RestaurantInput>
        </ModalWrapper>
      )}
      {isThirdPartyFood && (
        <ConfirmModal
          isOpen={isThirdPartyFood}
          handleAgree={() => {
            SetIsAddOwn(true);
            setIsThirdPartyFood(false);
          }}
          textConfirmation="ConfirmationAddThirdPartyFood"
          handleClose={() => {
            setIsThirdPartyFood(false);
            setMacronutriens(undefined);
            setIsBarCode(false);
          }}></ConfirmModal>
      )}
      {isBarcode && (
        <ModalWrapper
          handleClose={() => {
            setIsBarCode(false);
          }}
          isOpen={isBarcode}>
          <BarCodeReader
            onScan={(code: string) => {
              setIsBarCode(false);
              setBarCode(code);
              dispatch(getFoodByCode(code)).then(result => {
                if (result != null && typeof result === 'object') {
                  setFoodValue(result);
                } else {
                  dispatch(getFoodByCodeExternalService(code)).then(parsedNewProduct => {
                    setIsBarCode(false);
                    setMacronutriens(
                      new MacronutrientsObject(
                        parsedNewProduct?.data.product.nutriments['proteins_100g'],
                        parsedNewProduct?.data.product.nutriments['fat_100g'],
                        parsedNewProduct?.data.product.nutriments['carbohydrates_100g'],
                        parsedNewProduct?.data.product.nutriments['energy-kcal_100g'],
                      ),
                    );
                    setIsThirdPartyFood(true);
                  });
                }
              });
            }}
          />
        </ModalWrapper>
      )}

      {/* <Tabs
        values={['current', 'recent', 'restaurant', 'addOwn']}
        selectedTab={selectedTab}
        OnClickHandler={f => {
          SetRestaurant(undefined);
          setCurrentFood(undefined);
          setSelectedTab(f);
        }}
      /> */}
      {!currentFood?.food && !restaurant && (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <button
            className={classes.buttonFood + ' mr-1'}
            onClick={() => {
              SetIsAddOwn(true);
            }}>
            {t('AddOwn')}
          </button>
          <button
            className={classes.buttonFood + ' mx-1'}
            onClick={() => {
              setIsSelectRestaurant(true);
            }}>
            {t('SelectRestaurant')}
          </button>
        </div>
      )}
      <div className="tab-content">
        <div>
          <div className="row" style={{margin: 0, padding: 0}}>
            <div className="col-12" style={{textAlign: 'left'}}>
              {restaurant && (
                <button
                  style={{
                    padding: '10px 20px',
                    border: '2px var(--secondaryColor) solid',
                    color: 'white',
                    borderRadius: '5px',
                  }}
                  onClick={() => {
                    SetRestaurant(undefined);
                  }}
                  className={' mt-4 '}>
                  {restaurant.name}
                </button>
              )}
              {!currentFood?.food && (
                <FoodInput
                  onClickBarScannerHandler={() => {
                    setIsBarCode(true);
                  }}
                  maxWidth={'auto'}
                  restaurantId={restaurant ? restaurant.id : undefined}
                  getNewFood={setFoodValue}
                />
              )}
              {currentFood?.food && (
                <FoodComponent
                  onClickHandler={() => {
                    setFoodValue(undefined);
                  }}
                  food={currentFood.food}
                />
              )}
            </div>
          </div>
        </div>

        {/* {selectedTab === 'restaurant' && (
          <div>
            {!restaurant && (
              <RestaurantInput
                selectRestaurant={function (params: RestaurantDTO): void {
                  SetRestaurant(params);
                }}></RestaurantInput>
            )}
            {restaurant && (
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <h5
                  className={classes.restaurantTitle}
                  onClick={() => {
                    SetRestaurant(undefined);
                  }}>
                  {restaurant.name}
                </h5>
              </div>
            )}
            {!currentFood?.food && restaurant && (
              <>
                <FoodInput
                  onClickBarScannerHandler={() => {
                    setIsBarCode(true);
                  }}
                  restaurantId={restaurant?.id}
                  getNewFood={setFoodValue}
                />
              </>
            )}
            {currentFood?.food && (
              <FoodComponent
                onClickHandler={() => {
                  setFoodValue(undefined);
                }}
                food={currentFood.food}
              />
            )}
          </div>
        )} */}

        {!currentFood?.food && (
          <>
            <h5
              style={{
                borderBottom: '3px var(--primaryColor) solid',
                paddingBottom: '10px',
                marginBottom: '20px',
              }}>
              {t('History')}
            </h5>
            <div>
              <RecentFoodComponent
                OnClickHandler={(recent: FoodDTO) => {
                  setSelectedTab('current');
                  setFoodValue(recent);
                }}
                list={recentFood}
              />
            </div>
          </>
        )}
        {currentFood?.food && (
          <div className="row my-4">
            <div className="col-12 row">
              {currentFood.food.portions != null && currentFood?.food?.portions?.length > 1 && (
                <div className="col-3">
                  <VerticalSlider
                    onUpdate={value => {
                      setCurrentFood(prevState => ({
                        ...prevState,
                        weight: value,
                      }));
                    }}
                    numbers={currentFood.food.portions}
                  />
                </div>
              )}
              <div
                style={{display: 'flex', alignItems: 'center'}}
                className={
                  currentFood.food.portions != null && currentFood?.food?.portions?.length > 1
                    ? 'col-7 my-4'
                    : 'col-10 my-4'
                }>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}>
                  <div className="my-2">
                    <FontAwesomeIcon icon={faScaleUnbalanced}></FontAwesomeIcon>
                  </div>
                  <label className="text-white mx-4 " htmlFor="weight">
                    {t('Weight')}
                  </label>
                </div>
                <input
                  type="number"
                  id="weight"
                  className={`${classesModal.inputModal} w-full outline-0 h-[42px]`}
                  name="weight"
                  value={currentFood.weight}
                  onChange={changeFoodWeight}
                />
              </div>
              <div
                className="col-2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}>
                {weightModifier == undefined && (
                  <WeightModifiersWrapper
                    selectWeightModifier={(selectedWeightModifier: WeightModifierDto) => {
                      setWeightModifier(selectedWeightModifier);
                    }}
                    userId={currentUserId}
                  />
                )}
                {weightModifier && (
                  <div
                    onClick={() => {
                      setWeightModifier(undefined);
                    }}>
                    {weightModifier.name} {weightModifier.weight}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          className="col-12"
          style={{flexDirection: 'row-reverse', display: 'flex', marginBottom: '20px'}}>
          {currentFood?.food && currentFood?.weight && <AddBtn onClickHandler={save} />}
        </div>
      </div>
    </div>
  );
}
