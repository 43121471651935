import {FoodDTO} from '../../../models/mealModel';
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../..';
interface foodState {
  foods: FoodDTO[];
  foodsContainer: FoodDTO[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: foodState = {
  foods: [],
  foodsContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const foodSlice = createSlice({
  name: 'food',
  initialState,
  reducers: {
    foodFetching(state) {
      state.loading = true;
    },
    foodFetchingSuccess(state, action: PayloadAction<FoodDTO[]>) {
      state.error = '';
      state.foods = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    foodFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});
export default foodSlice.reducer;
