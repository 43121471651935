import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {faPlay} from '@fortawesome/free-solid-svg-icons';
import {sharedIconProperties} from './sharedProperties.component';

export default function PlayCustomIcon({onClickHandler, color}: sharedIconProperties) {
  return (
    <span
      style={{position: 'relative', display: 'flex', cursor: 'pointer', color: color ?? 'black'}}
      onClick={onClickHandler}>
      <FontAwesomeIcon icon={faPlay} />
    </span>
  );
}
