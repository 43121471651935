import BaseLogo from '../baseLogo';
import classes from './../logos.module.css';
import React from 'react';
export interface GoogleProperties {
  onClickHandler?: () => void;
  height?: number;
  width?: number;
  margin?: string;
}
export default function GoogleLogo({
  height = 60,
  width = 160,
  onClickHandler,
  margin,
}: GoogleProperties) {
  return (
    <BaseLogo
      height={height}
      width={width}
      margin={margin}
      className={classes.googleLogo}
      onClickHandler={onClickHandler}
    />
  );
}
