import React, {ChangeEvent, useEffect, useState} from 'react';
import {FoodDTO} from '../../../../models/mealModel';
import classes from './foodComponent.module.scss';
import {MacronutrientsComponent} from '../../common/macronutrientsComponent/macronutrients.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWeight} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
export interface FoodComponentProperties {
  food: FoodDTO;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickHandler?: (params: any) => any;
  weight?: number;
}
export function FoodComponent({food, onClickHandler, weight}: FoodComponentProperties) {
  const {t} = useTranslation();
  if (food != null) {
    return (
      <p
        style={{margin: 0}}
        key={food.id}
        onClick={() => {
          if (onClickHandler != null) {
            onClickHandler(food);
          }
        }}
        className={classes.foodWrapper}>
        <h4
          style={{textAlign: 'center'}}
          className={classes.foodName + ' my-2 ' + classesColor.sidebarColor}>
          {food.name}
          <p
            style={{margin: 0, zIndex: 2}}
            className={
              classes.foodDescription + ' border-custom border-primary background-primary  '
            }>
            {food.description}
          </p>
        </h4>
        <div className={classes.foodMacronutrientsWrapper}>
          <MacronutrientsComponent macronutrients={food.macronutrients} weight={weight} />

          {weight && (
            <div style={{display: 'flex'}}>
              <FontAwesomeIcon className="mx-2" icon={faWeight}></FontAwesomeIcon>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                {weight}
                {t('Gram')}
              </div>
            </div>
          )}
        </div>
      </p>
    );
  } else {
    return <span> </span>;
  }
}
