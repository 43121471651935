import React, {ChangeEvent, useEffect, useState} from 'react';
import classes from './addUpdatePortions.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWeight} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
export interface AddUpdatePortionsComponentProperties {
  portions: number[];
  addHandler: (value: number) => void;
  removeHandler: (value: number) => void;
}
export function AddUpdatePortionsComponent({
  portions,
  addHandler,
  removeHandler,
}: AddUpdatePortionsComponentProperties) {
  const {t} = useTranslation();
  const [weight, setWeight] = useState<number>(0);
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setWeight(Number.parseInt(event.target.value));
  };
  function addPortions() {
    if (weight != null) {
      addHandler(weight);
      setWeight(0);
    }
  }

  function renderPortionList() {
    return (
      <div className={classes.portionsWrapper + ' col-8'}>
        {portions.map((portion, index) => (
          <div
            className={classes.portion + ' mx-2'}
            key={index}
            onClick={() => {
              removeHandler(portion);
            }}>
            {portion}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <h4 style={{textAlign: 'left'}} className={classesColor.sidebarColor + ' mx-2 my-2'}>
        {t('Portions')}
      </h4>
      <div className="row my-2">
        <div
          className="col-4"
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <input
            type="number"
            id="portion"
            className={classesModal.inputModal + ' ml-4  w-full   outline-0 h-[42px]'}
            name="portion"
            value={weight}
            onChange={changeHandler}
          />
          {weight != null && weight > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '10px',
                height: '30px',
              }}
              className={classesModal.addBtn}
              onClick={addPortions}>
              +
            </div>
          )}
        </div>
        {renderPortionList()}
      </div>
    </>
  );
}
