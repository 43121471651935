import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TranslationDataDto, TranslationDto} from '../../../models/strict/translationStrictModel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faRemove, faSave} from '@fortawesome/free-solid-svg-icons';
import TranslationAddEditComponent from '../translationAdd/translationAddEdit.component';
import classes from './translationItem.module.scss';
export interface TranslationItemComponentProperties {
  translation: TranslationDataDto;
  onEditTranslationHandler: (translation: TranslationDataDto) => void;
  onRemoveTranslationHandler: (translation: TranslationDataDto) => void;
}

export default function TranslationItemComponent({
  translation,
  onEditTranslationHandler,
  onRemoveTranslationHandler,
}: TranslationItemComponentProperties) {
  const {t} = useTranslation();

  const [editTranslationItem, setEditTranslationItem] = useState<boolean>(false);
  return (
    <div
      className="mb-4 relative"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
      }}>
      {!editTranslationItem && (
        <>
          <div className={classes.content}>
            <div>{translation.locale}</div>
            <div>{translation.value}</div>
          </div>
          <div className={classes.actions}>
            <div
              onClick={() => {
                onRemoveTranslationHandler(translation);
              }}>
              <FontAwesomeIcon icon={faRemove}></FontAwesomeIcon>
            </div>
          </div>
          {/* <div
            onClick={() => {
              setEditTranslationItem(true);
            }}>
            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          </div> */}
        </>
      )}
      {editTranslationItem && (
        <TranslationAddEditComponent
          translation={translation}
          onAddEditTranslationHandler={function (editedTranslation: TranslationDataDto): void {
            onEditTranslationHandler(editedTranslation);
          }}
        />
      )}
    </div>
  );
}
