import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import classes from './foodConstructor.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWeight} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import classesModal from '../../common/modal/styles/modalShared.module.scss';
import classesColor from '../../shared/sharedColorConfigurations.module.scss';
import {FoodDTO, FoodMealDTO, Macronutrients, MealDTO} from '../../../models/mealModel';
import FoodInput from '../foods/foodInput/foodInput';
import AddBtn from '../../common/buttons/addBtn.component';
import {FoodComponent} from '../foods/foodComponent/foodComponent';
import {
  FoodDTOObject,
  FoodMealObject,
  MacronutrientsObject,
} from '../../../models/strict/mealStrictModel';
import SaveBtn from '../../common/buttons/saveBtn.component';
import MacronutrientsViewComponent from '../foods/macronutrientsView/macronutrientsView.component';
import ModalWrapper from '../../common/modal/modalWrapper/modalWrapper.component';
import BarCodeReader from '../../common/barCodeReader/barCodeReader.component';
import {
  addFood,
  getFoodByCode,
  getFoodByCodeExternalService,
} from '../../../actions/meals/food.actions';
import {useAppDispatch} from '../../../hooks/redux';
import {AddEditFoodComponent} from '../foods/foodAddUpdateComponent/AddEditFoodComponent';
import {TranslationDto} from '../../../models/strict/translationStrictModel';
import ConfirmModal from '../../common/modal/confirmAction.component';
import {addTranslationToFood} from '../../../actions/common/translation.action';
export interface FoodConstructorComponentProperties {
  calculateHandler: (macr: Macronutrients) => void;
}
export function FoodConstructorComponent({calculateHandler}: FoodConstructorComponentProperties) {
  const {t} = useTranslation();
  const [isBarcode, setIsBarcode] = useState<boolean>(false);
  const [weight, setWeight] = useState<number>(0);
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setWeight(Number.parseInt(event.target.value));
  };
  const totalWeight = useRef(0);
  const dispatch = useAppDispatch();
  const [food, setFood] = useState<FoodDTO>();
  const [meals, setMeals] = useState<FoodMealDTO[]>([]);
  const [isAddOwn, setIsAddOwn] = useState<boolean>(false);
  const [macronutients, setMacronutriens] = useState<Macronutrients>();
  const [barCode, setBarCode] = useState<string>();
  const [isThirdPartyFood, setIsThirdPartyFood] = useState<boolean>(false);
  const calculateTotals = () => {
    let localTotalWeight = 0;
    const totalMacros = {proteins: 0, fats: 0, carbohydrates: 0, calories: 0};

    if (meals != undefined) {
      meals.forEach(meal => {
        const weightRatio = meal.weight! / 100;
        localTotalWeight += meal.weight!;
        totalMacros.proteins! += (meal.food!.macronutrients?.proteins || 0) * weightRatio;
        totalMacros.fats! += (meal.food!.macronutrients?.fats || 0) * weightRatio;
        totalMacros.carbohydrates! += (meal.food!.macronutrients?.carbohydrates || 0) * weightRatio;
        totalMacros.calories! += (meal.food!.macronutrients?.calories || 0) * weightRatio;
      });
    }

    if (localTotalWeight === 0) return {proteins: 0, fats: 0, carbohydrates: 0, calories: 0};

    const per100gMacros = {
      proteins: (totalMacros.proteins / localTotalWeight) * 100,
      fats: (totalMacros.fats / localTotalWeight) * 100,
      carbohydrates: (totalMacros.carbohydrates / localTotalWeight) * 100,
      calories: (totalMacros.calories / localTotalWeight) * 100,
    };
    totalWeight.current = localTotalWeight;
    return per100gMacros;
  };

  const per100g = calculateTotals();
  const addNewFood = (foodDto: FoodDTO, translation: TranslationDto | undefined) => {
    dispatch(addFood(foodDto))
      .then(f => {
        if (f) {
          setFood(f);
          if (translation != null && translation.translationDatas.length > 0) {
            dispatch(addTranslationToFood(f.id, translation));
          }
        }
      })
      .catch(e => {
        console.log('Failed to save food:', e);
      });
  };
  return (
    <div className="relative col-12 border-primary-custom pt-4 mb-0 darkModalBackground color-white custom-border   px-4">
      {isAddOwn && (
        <ModalWrapper
          handleClose={() => {
            setIsAddOwn(false);
          }}
          isOpen={isAddOwn}>
          <AddEditFoodComponent
            barCode={barCode}
            macronutrients={macronutients}
            food={new FoodDTOObject(undefined, undefined, new MacronutrientsObject())}
            saveHandler={(params: FoodDTO, translation: TranslationDto | undefined) => {
              setMacronutriens(undefined);
              setBarCode(undefined);
              addNewFood(params, translation);
              setIsAddOwn(false);
            }}></AddEditFoodComponent>
        </ModalWrapper>
      )}
      {isThirdPartyFood && (
        <ConfirmModal
          isOpen={isThirdPartyFood}
          handleAgree={() => {
            setIsAddOwn(true);
            setIsThirdPartyFood(false);
          }}
          textConfirmation="ConfirmationAddThirdPartyFood"
          handleClose={() => {
            setIsThirdPartyFood(false);
            setMacronutriens(undefined);
            setIsBarcode(false);
          }}></ConfirmModal>
      )}
      {isBarcode && (
        <ModalWrapper
          handleClose={() => {
            setIsBarcode(false);
          }}
          isOpen={isBarcode}>
          <BarCodeReader
            onScan={(code: string) => {
              setIsBarcode(false);
              setBarCode(code);
              dispatch(getFoodByCode(code)).then(result => {
                if (result != null && typeof result === 'object') {
                  setFood(result);
                } else {
                  dispatch(getFoodByCodeExternalService(code)).then(parsedNewProduct => {
                    setIsBarcode(false);
                    setMacronutriens(
                      new MacronutrientsObject(
                        parsedNewProduct?.data.product.nutriments['proteins_100g'],
                        parsedNewProduct?.data.product.nutriments['fat_100g'],
                        parsedNewProduct?.data.product.nutriments['carbohydrates_100g'],
                        parsedNewProduct?.data.product.nutriments['energy-kcal_100g'],
                      ),
                    );

                    setIsThirdPartyFood(true);
                  });
                }
              });
            }}
          />
        </ModalWrapper>
      )}
      <div className="row col-12">
        <div className="col-12">
          <div className="summary">
            <button
              className={classes.buttonFood + ' mb-4 mr-1'}
              onClick={() => {
                setIsAddOwn(true);
              }}>
              {t('AddOwn')}
            </button>
            <h4>{t('Per100gTotals')}</h4>
            <MacronutrientsViewComponent macronutrients={per100g}></MacronutrientsViewComponent>
            <div style={{paddingLeft: '15px'}} className={' col-12 ' + classes.mealsWrapper}>
              {meals.map((f, index) => {
                const mealPercentage = ((f.weight! / totalWeight.current || 1) * 100).toFixed(2);
                return (
                  <div
                    className={classes.foodItem + ' my-2 mx-2'}
                    key={index}
                    onClick={() => {
                      setMeals(prev => prev.filter((_, i) => i !== index));
                    }}>
                    {f.food?.name} - {f.weight}
                    {t('gramm')} - {mealPercentage}%
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12 " style={{paddingLeft: '15px'}}>
          {food == undefined && (
            <FoodInput
              minWidth={'auto'}
              maxWidth={'auto'}
              onClickBarScannerHandler={() => {
                setIsBarcode(true);
              }}
              getNewFood={function (params: FoodDTO): void {
                setFood(params);
              }}
            />
          )}
          {food && (
            <FoodComponent
              food={food}
              onClickHandler={() => {
                setFood(undefined);
              }}></FoodComponent>
          )}
          {food && (
            <>
              <label className="col-12 my-4">{t('Weight')}</label>
              <div className="mb-4" style={{display: 'flex', alignItems: 'center'}}>
                <input
                  className={classesModal.inputModal + ' col-10  mr-3 w-full outline-0 h-[42px]'}
                  type="text"
                  onChange={changeHandler}
                  value={weight}
                  placeholder="Weight"
                />
                {weight > 0 && (
                  <AddBtn
                    onClickHandler={() => {
                      const mealFood = new FoodMealObject(
                        undefined,
                        undefined,
                        undefined,
                        weight,
                        food,
                      );
                      setMeals(prevMeals => [...prevMeals, mealFood]);
                      setWeight(0);
                      setFood(undefined);
                    }}></AddBtn>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {meals?.length > 0 && (
        <SaveBtn
          onClickHandler={() => {
            calculateHandler(calculateTotals());
          }}
        />
      )}
    </div>
  );
}
