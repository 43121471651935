import React, {useState} from 'react';
import {useAppDispatch} from '../../../hooks/redux';
import {RestaurantDTO} from '../../../models/mealModel';
import {AddEditFoodComponent} from '../foods/foodAddUpdateComponent/AddEditFoodComponent';
import {FoodDTOObject, MacronutrientsObject} from '../../../models/strict/mealStrictModel';
import {RestaurantComponent} from './restaurant.component';
import {addFood} from '../../../actions/meals/food.actions';

export interface RestaurantListProperties {
  list?: RestaurantDTO[];
}
export default function RestaurantList({list}: RestaurantListProperties) {
  const [isAddFood, setAddFood] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  function renderrestaurantList() {
    if (list?.length === 0) {
      return <p className="text-center">No results!</p>;
    }

    return list?.map(restaurant => (
      <RestaurantComponent key={restaurant.id} restaurant={restaurant} />
    ));
  }

  return (
    <div className="mb-4 relative" style={{border: '2px black solid'}}>
      {list && list.length > 0 && <div>{renderrestaurantList()}</div>}
    </div>
  );
}
