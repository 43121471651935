import {Control} from 'react-hook-form'; // Import Control type from react-hook-form

export interface ValidationConfig {
  name: string;
  control: Control; // Use Control type for the control property
  defaultValue: string;
  rules?: {
    required?: {value: boolean; message: string};
    minLength?: {value: number; message: string};
    maxLength?: {value: number; message: string};
    min?: {value: number; message: string};
    max?: {value: number; message: string};
    pattern?: {value: RegExp; message: string};
  };
}

export const emailValidationConfig = (control: Control): ValidationConfig => ({
  name: 'Email',
  control: control,
  defaultValue: '',
  rules: {
    required: {value: true, message: 'required'},
    minLength: {value: 10, message: 'minLength error'},
    pattern: {value: /^\S+@\S+$/i, message: 'Invalid email'},
  },
});
export const passwordValidationConfig = (control: Control): ValidationConfig => ({
  name: 'Password',
  control: control,
  defaultValue: '',
  rules: {
    required: {value: true, message: 'required'},
    minLength: {value: 6, message: 'minLength error'},
  },
});
export const heightValidationConfig = (control: Control): ValidationConfig => ({
  name: 'height',
  control: control,
  defaultValue: '',
  rules: {
    required: {value: true, message: 'required'},
    min: {value: 50, message: 'minLength error'},
    max: {value: 280, message: 'maxLength error'},
  },
});
export const simpleTextValidationConfig = (control: Control, name: string): ValidationConfig => ({
  name: name,
  control: control,
  defaultValue: '',
  rules: {
    required: {value: true, message: 'required'},
    minLength: {value: 2, message: 'minLength error'},
    maxLength: {value: 280, message: 'maxLength error'},
  },
});
export const emptyControlTextValidationConfig = (
  control: Control,
  name: string,
): ValidationConfig => ({
  name: name,
  control: control,
  defaultValue: '',
});
export const dateOfBirthValidationConfig = (control: Control): ValidationConfig => ({
  name: 'dateOfBirth',
  control: control,
  defaultValue: '',
  rules: {
    required: {value: true, message: 'required'},
  },
});
export const weightValidationConfig = (control: Control): ValidationConfig => ({
  name: 'weight',
  control: control,
  defaultValue: '',
  rules: {
    required: {value: true, message: 'required'},
    min: {value: 20, message: 'minLength error'},
    max: {value: 280, message: 'maxLength error'},
  },
});
