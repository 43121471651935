import {DateTime} from 'luxon';
import React, {ChangeEvent, useEffect, useState} from 'react';

import AddEditSessionComponent from '../../sessions/createSession/addEditSession.component';
import {SessionDto, TrainingPlanDto} from '../../../../models/trainingModel';
import {
  addTrainingPlans,
  getTrainingPlan,
  getTrainingPlans,
} from '../../../../actions/trainings/trainingPlan.action';
import {useAppDispatch} from '../../../../hooks/redux';
import {SessionList} from '../../sessions/sessions/sessionsList.component';
import {useTranslation} from 'react-i18next';
import {
  CreateSessionObject,
  SessionObject,
  TrainingPlanObject,
} from '../../../../models/strict/sessionStrictModel';
import {TrainingPlanList} from '../trainingPlanList/trainingPlanList.component';
import AddEditTrainingPlanComponent from '../addEditTrainingPlan.component';
import {Modal} from 'bootstrap';
import ModalWrapper from '../../../common/modal/modalWrapper/modalWrapper.component';
import {getCurrentUserInfo} from '../../../../actions/account/account.actions';
import {useSelector} from 'react-redux';
import {selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import AddHistoryIcon from '../../../common/icon/addHistoryIcon/addHistoryIcon.component';
import classes from './trainingPlanWrapper.module.scss';
export interface TrainingPlanWrapperProperties {
  list?: TrainingPlanDto[];
  onSelectTrainingPlanHandler: (trainingPlan: TrainingPlanDto) => void;
  onDeleteTrainingPlanHandler?: (trainingPlanId: string) => void;
  IsShowAddIcon: boolean;
}
export default function TrainingPlanWrapper({
  list,
  IsShowAddIcon,
  onSelectTrainingPlanHandler,
  onDeleteTrainingPlanHandler,
}: TrainingPlanWrapperProperties) {
  const [TrainingPlan, setTrainingPlan] = useState<TrainingPlanObject | null>();

  const {t} = useTranslation();
  return (
    <div className={classes.wrapper + ' row'}>
      {IsShowAddIcon && (
        <span
          style={{display: 'flex', justifyContent: 'flex-end'}}
          onClick={() => {
            const newTrainingPlan = new TrainingPlanObject();
            newTrainingPlan.session = new CreateSessionObject();
            newTrainingPlan.session.sets = [];
            setTrainingPlan(newTrainingPlan);
          }}>
          <AddHistoryIcon />
        </span>
      )}
      {TrainingPlan != null && (
        <ModalWrapper
          isOpen={TrainingPlan != null}
          handleClose={function () {
            setTrainingPlan(null);
          }}>
          <AddEditTrainingPlanComponent
            closeHandler={() => {
              setTrainingPlan(null);
            }}
            modificatedTrainingPlan={TrainingPlan}
          />
        </ModalWrapper>
      )}

      <TrainingPlanList
        itemsPerSlide={3}
        list={list}
        onEditHandler={(trainingPlan: TrainingPlanDto) => {
          setTrainingPlan(trainingPlan);
        }}
        onDeleteHandler={onDeleteTrainingPlanHandler}
        onSelectHandler={onSelectTrainingPlanHandler}
      />
    </div>
  );
}
