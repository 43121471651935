import React, {useEffect, useState} from 'react';
import {TrainingPlanDto} from '../../../../models/trainingModel'; // Ensure the path is correct based on your project structure
import Carousel from '../../../common/carousel/carousel.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faDeleteLeft,
  faPen,
  faPenAlt,
  faTrash,
  faTrashAlt,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import {useDispatch} from 'react-redux';
import {getTrainingPlan} from '../../../../actions/trainings/trainingPlan.action';
import {useAppDispatch} from '../../../../hooks/redux';

import './trainingPlanList.component.scss';
import TrainingPlanComponent from '../trainingPlanComponent/trainingPlan.component';
export interface TrainingPlanListProperties {
  list?: TrainingPlanDto[];
  onSelectHandler: (trainingplan: TrainingPlanDto) => void;
  onEditHandler?: (trainingplan: TrainingPlanDto) => void;
  onDeleteHandler?: (trainingPlanId: string) => void;
  itemsPerSlide: number;
}

export function TrainingPlanList({
  list,
  onSelectHandler,
  onEditHandler,
  onDeleteHandler,
  itemsPerSlide,
}: TrainingPlanListProperties) {
  useEffect(() => {
    console.log(list);
  }, [list]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const dispatch = useAppDispatch();
  function chunkArray(array: TrainingPlanDto[], size: number): TrainingPlanDto[][] {
    const chunkedArr: TrainingPlanDto[][] = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }

  function renderTrainingPlanList(
    trainingPlanList: TrainingPlanDto[] | undefined | null,
  ): JSX.Element {
    if (!trainingPlanList || trainingPlanList.length === 0) {
      return <p className="text-center">No results!</p>;
    }
    const selectTraining = (trainingPlan: TrainingPlanDto) => {
      if (onSelectHandler != null) {
        onSelectHandler(trainingPlan);
      } else {
        console.log('select handler is null');
      }
    };
    return (
      <Carousel itemsPerSlide={itemsPerSlide} autoPlay={false} interval={5000}>
        {trainingPlanList.map((trainingPlan, index) => (
          <TrainingPlanComponent
            key={index}
            isSelected={index == selectedIndex}
            trainingPlan={trainingPlan}
            onSelectHandler={(item: TrainingPlanDto) => {
              setSelectedIndex(index);
              onSelectHandler(item);
            }}
            onEditHandler={onEditHandler}
            onDeleteHandler={onDeleteHandler}></TrainingPlanComponent>
        ))}
      </Carousel>
    );
  }

  return list ? renderTrainingPlanList(list) : <div>No training plans available.</div>;
}
