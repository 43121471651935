import BaseLogo from '../baseLogo';
import classes from './../logos.module.css';
import React from 'react';
export interface LinkedinProperties {
  onClickHandler?: () => void;
  height?: number;
  width?: number;
  margin?: string;
}
export default function LinkedinLogo({
  height = 60,
  width = 160,
  margin,
  onClickHandler,
}: LinkedinProperties) {
  return (
    <BaseLogo
      height={height}
      width={width}
      margin={margin}
      className={classes.linkedinLogo}
      onClickHandler={onClickHandler}
    />
  );
}
