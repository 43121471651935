import {AppDispatch} from '../../store';
import axios from '../../axios';
import {scheduledTrainingPlanSlice} from '../../store/trainings/slices/scheduledTrainingPlan.slice';
import {queries} from '@testing-library/react';
import {DateTime} from 'luxon';
import {CreateScheduledTrainingPlanDto, ScheduledTrainingPlanDto} from '../../models/trainingModel';
import {TrainingApi} from './constant';
export const ScheduledTrainingPlan_API_URL =
  TrainingApi.TrainingApiPrefix + '/scheduledTrainingPlan';
export const getScheduledTrainingPlans = (userId?: string, from?: string, to?: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<ScheduledTrainingPlanDto[]>(
        ScheduledTrainingPlan_API_URL + '/filter',
        {
          // paginationRequest: {
          //   currentPage: 0,
          //   pageSize: 50,
          // },
          StartDate: from,
          EndDate: to,
          userId: userId,
        },
      );
      if (response.status >= 300) {
        dispatch(scheduledTrainingPlanSlice.actions.ScheduledTrainingPlanFetchingError);
      } else {
        console.log(response.data);
        dispatch(
          scheduledTrainingPlanSlice.actions.ScheduledTrainingPlansFetchingSuccess(response.data),
        );
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const deleteScheduledTrainingPlan = (ScheduledTrainingPlanId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete(
        ScheduledTrainingPlan_API_URL + '/' + ScheduledTrainingPlanId,
      );
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addScheduledTrainingPlans = (
  ScheduledTrainingPlan: CreateScheduledTrainingPlanDto,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<ScheduledTrainingPlanDto[]>(
        ScheduledTrainingPlan_API_URL,
        ScheduledTrainingPlan,
      );
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
