import React, {ChangeEvent, useEffect, useState} from 'react';

import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {TimePicker} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';
import {largeCommonDateFormat, longCommonTimeFormat} from '../../../../constants/common/dateFormat';
import {useAppDispatch} from '../../../../hooks/redux';
import {TrainingDto, TrainingDescriptionDto} from '../../../../models/trainingModel';
import TrainingDescriptionInput from '../../trainingDescription/trainingDescriptionInput/trainingDescriptionInput.component';
import {duration} from '@mui/material';
import {TimeSpanObject, TrainingObject} from '../../../../models/strict/sessionStrictModel';
import {
  ValidationConfig,
  passwordValidationConfig,
  simpleTextValidationConfig,
} from '../../../common/configurations/validationConfigurations';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import TrainingDescriptionChoosed from '../../trainingDescription/trainingDescriptionChoosed.component';
import {DateInputProps} from '../../../../constants/common/dateInputProps';
import classes from './addEditTraining.module.scss';
import classesComponent from '../../../shared/sharedComponent.module.scss';
import classesModal from '../../../../components/common/modal/styles/modalShared.module.scss';
export interface AddEditTrainingComponentProperties {
  trainingInput: TrainingDto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
}
export default function AddEditTrainingComponent({
  trainingInput,
  saveHandler,
}: AddEditTrainingComponentProperties) {
  const {t} = useTranslation();
  const [training, setTraining] = useState<TrainingDto>(trainingInput);
  const [trainingDescription, setTrainingDescription] = useState<TrainingDescriptionDto | null>();
  return (
    <div className={'col-12 ' + classes.wrapper} key={training.id}>
      <p className="">{t('Training')}</p>
      <div className="row col-12 border-primary-custom py-4  " style={{marginLeft: 0}}>
        <div className="row col-12 " style={{marginLeft: 0}}>
          <div className="col-6">
            <TrainingDescriptionInput
              getNewtrainingDescription={newTrainingDescription => {
                training.trainingDescriptionId = newTrainingDescription.id;
                if (newTrainingDescription.name) {
                  training.customTrainingName = newTrainingDescription.name;
                }
                setTrainingDescription(newTrainingDescription);
              }}
            />
            {/* ) : (
              <TrainingDescriptionChoosed
                onClickHandler={() => {
                  setTrainingDescription(undefined);
                }}
                training={trainingDescription}></TrainingDescriptionChoosed>
            )} */}
          </div>
          {trainingDescription != null && trainingDescription.typeOfTrainingDescription == 0 && (
            <div className="col-6">
              <div className="mb-4 relative">
                <label htmlFor="Counts" className="my-4">
                  {t('Counts')}
                </label>
                <input
                  type="number"
                  id="weight"
                  className={
                    'border px-4 py-2 w-full  outline-0 h-[42px] ' +
                    classesComponent.transparentInputGrey
                  }
                  name="counts"
                  value={training.counts ?? 0}
                  onChange={event => {
                    setTraining({...training, counts: Number.parseInt(event.target.value!)});
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="row col-5 mx-2">
          <label htmlFor="duration" className="my-4">
            {t('Duration')}{' '}
          </label>

          <TimePicker
            label={t('Controlledpicker')}
            views={['minutes', 'seconds']}
            format="mm:ss"
            className="col-12"
            slotProps={DateInputProps.timeSlots}
            value={
              training.duration
                ? DateTime.fromFormat(training.duration, longCommonTimeFormat)
                : DateTime.fromFormat('00:00:00', longCommonTimeFormat)
            }
            onChange={newValue => {
              newValue
                ? setTraining(prevState => ({
                    ...prevState,
                    duration:
                      newValue.toFormat('HH') +
                      ':' +
                      newValue.toFormat('mm') +
                      ':' +
                      newValue.toFormat('ss'),
                  }))
                : '00:00:00';
            }}></TimePicker>
        </div>
        <div className="row col-5 mx-2">
          <label htmlFor="chillout" className="my-4">
            {t('Chillout')}{' '}
          </label>

          <TimePicker
            label={t('Controlledpicker')}
            views={['minutes', 'seconds']}
            format="mm:ss"
            className="col-12 color-white"
            slotProps={DateInputProps.timeSlots}
            value={
              training.chillTime
                ? DateTime.fromFormat(training.chillTime, longCommonTimeFormat)
                : DateTime.fromFormat('00:00:00', longCommonTimeFormat)
            }
            onChange={newValue => {
              newValue
                ? setTraining(prevState => ({
                    ...prevState,
                    chillTime:
                      newValue.toFormat('HH') +
                      ':' +
                      newValue.toFormat('mm') +
                      ':' +
                      newValue.toFormat('ss'),
                  }))
                : '00:00:00';
            }}></TimePicker>
        </div>
      </div>
      {trainingDescription != null &&
        training.duration &&
        (training.trainingDescription?.typeOfTrainingDescription != 0 ||
          (training?.counts != null && training.counts > 0)) && (
          <span
            className={'absolute top-1 right-1 ' + classesModal.addBtn}
            onClick={() => {
              saveHandler(training);
              setTrainingDescription(null);
              setTraining(new TrainingObject());
            }}>
            {' '}
            +
          </span>
        )}
    </div>
  );
}
