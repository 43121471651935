import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';
import classes from './foodListPresentation.module.scss';
import classesList from '../../../common/scss/list.module.scss';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBarcode, faInfoCircle, faTrademark} from '@fortawesome/free-solid-svg-icons';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import {FoodDTO} from '../../../../models/mealModel';
import MacronutrientsViewComponent from '../macronutrientsView/macronutrientsView.component';

export interface FoodListPresentationProperties {
  foods: FoodDTO[];
  onClickHandler: (clickedFood: FoodDTO) => void;
}

export default function FoodListPresentation({
  foods,
  onClickHandler,
}: FoodListPresentationProperties) {
  const {t} = useTranslation();
  return (
    <ScrollableBody height="200px">
      {foods?.map(food => (
        <li
          key={food.id}
          onClick={() => {
            onClickHandler(food);
          }}
          style={{borderRadius: '5px', textDecoration: 'none', listStyle: 'none'}}
          className={
            classesList.searchedLi + ' cursor-pointer background-primary text-white px-3 my-2 p-2'
          }>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div style={{display: 'flex'}}>
              {food.description && (
                <div title={food.description} className="mr-2 desktopOnly">
                  <FontAwesomeIcon icon={faInfoCircle} style={{color: 'var(--primaryColor)'}} />
                </div>
              )}
              <div title={food?.name || ''}>
                {food?.name && food?.name!.length > 25 && food.brandName
                  ? `${food?.name!.slice(0, 20)}...`
                  : food?.name}
              </div>
            </div>

            {food.brandName && (
              <span className={classes.brandName}>
                {food.brandName}
                <FontAwesomeIcon
                  style={{color: 'var( --textColor)', position: 'absolute'}}
                  icon={faTrademark}
                  className="mx-1"
                  size={'2xs'}
                />
              </span>
            )}
          </div>
          {food.description && (
            <div className="col-12 mr-2   mobileTabletOnly">
              <div className={classes.foodDescription}>
                {' '}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{color: 'var(--primaryColor)'}}
                  className="mr-2"
                />
                {food.description}
              </div>
            </div>
          )}
          {food.macronutrients && (
            <MacronutrientsViewComponent macronutrients={food.macronutrients} />
          )}
        </li>
      ))}{' '}
    </ScrollableBody>
  );
}
