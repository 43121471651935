import {AppDispatch} from '../../store';
import axios from '../../axios';
import {trainingPlanSlice} from '../../store/trainings/slices/trainingPlan.slice';
import {TrainingPlanDto} from '../../models/trainingModel';
import {TrainingApi} from './constant';
export const TRININGPLAN_API_URL = TrainingApi.TrainingApiPrefix + '/trainingPlan';
export const getTrainingPlans = (
  userId?: string,
  name?: string,
  description?: string,
  from?: string,
  to?: string,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<TrainingPlanDto[]>(TRININGPLAN_API_URL + '/filter', {
        // paginationRequest: {
        //   currentPage: 0,
        //   pageSize: 50,
        // },
        name: name,
        description: description,
        from: from,
        to: to,
        userId: userId,
      });
      if (response.status >= 300) {
        dispatch(trainingPlanSlice.actions.trainingPlanFetchingError);
      } else {
        console.log(response.data);
        dispatch(trainingPlanSlice.actions.trainingPlansFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getTrainingPlan = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<TrainingPlanDto>(TRININGPLAN_API_URL + '/' + id);
      if (response.status >= 300) {
        dispatch(trainingPlanSlice.actions.trainingPlanFetchingError);
      } else {
        console.log(response.data);
        dispatch(trainingPlanSlice.actions.trainingPlanFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const deleteTrainingPlan = (trainingPlanId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete(TRININGPLAN_API_URL + '/' + trainingPlanId);
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addTrainingPlans = (trainingPlan: TrainingPlanDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<TrainingPlanDto[]>(TRININGPLAN_API_URL, trainingPlan);
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const updateTrainingPlan = (trainingPlan: TrainingPlanDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put<TrainingPlanDto>(TRININGPLAN_API_URL, trainingPlan);
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
