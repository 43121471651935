import React, {ChangeEvent, useEffect, useState} from 'react';

import {DateTime} from 'luxon';
import {Box, Modal, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faPlay,
  faStar,
  faStopwatch20,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import classes from './startTraining.module.scss';
import {useTranslation} from 'react-i18next';
export interface StartTrainingComponentProperties {
  onClickHandler: () => void;
}

export function StartTrainingComponent({onClickHandler}: StartTrainingComponentProperties) {
  const {t} = useTranslation();

  return (
    <>
      <div className={classes.playButtonWrapper}>
        <a href="\realtimetrainings" style={{display: 'flex', flexDirection: 'column'}}>
          <h1 className={classes.textInfo}>{t('StartNewTraining')}</h1>
          <FontAwesomeIcon
            icon={faPlay}
            height={'100px'}
            className={classes.playButton}></FontAwesomeIcon>
        </a>
      </div>
    </>
  );
}
