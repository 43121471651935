import React from 'react';
import FacebookLogo from '../../../components/common/logos/socialLogo/facebookLogo.component';
import GoogleLogo from '../../../components/common/logos/socialLogo/googleLogo.component';
import InstagramLogo from '../../../components/common/logos/socialLogo/instagramLogo.component';
import LinkedinLogo from '../../../components/common/logos/socialLogo/linkedinLogo.component';

export interface AuthWrapperProperties {
  header: string;
}
export default function AuthWrapperLogo({header}: AuthWrapperProperties) {
  return (
    <div className="row" style={{display: 'none'}}>
      <div
        className="col-12 text-center"
        style={{
          borderTop: '1px rgba(163, 163, 163, 0.35) solid',
          paddingTop: 30,
          paddingBottom: 25,
          fontSize: 20,
          color: '#1C2520',
          fontWeight: 400,
          fontFamily: 'Outfit',
        }}>
        {header}
      </div>
      <div className="row col-12 justify-content-center">
        <FacebookLogo height={60} width={60} margin="0 36px" onClickHandler={() => {}} />
        <GoogleLogo height={60} width={60} margin="0 36px" onClickHandler={() => {}} />
        <InstagramLogo height={60} width={60} margin="0 36px" onClickHandler={() => {}} />
        <LinkedinLogo height={60} width={60} margin="0 36px" onClickHandler={() => {}} />
      </div>
    </div>
  );
}
