import {FoodDTO, MealDTO} from '../../../models/mealModel';
import {useAppDispatch} from '../../../hooks/redux';
import classes from './recentFood.module.scss';
import classesList from '../../common/scss/list.module.scss';
import React from 'react';
import ScrollableBody from '../../common/scrollbar/scrollbar.component';
import FoodListPresentation from '../foods/foodListPresentation/foodListPresentation.component';
export interface RecentFoodComponentProperties {
  list?: FoodDTO[] | undefined;
  OnClickHandler: (food: FoodDTO) => void;
}
export default function RecentFoodComponent({list, OnClickHandler}: RecentFoodComponentProperties) {
  const dispatch = useAppDispatch();
  function renderRecentFoodComponent() {
    if (list?.length === 0) {
      return <p className="text-center">No results!</p>;
    }

    if (list?.length != 0 && list != null) {
      return (
        <FoodListPresentation
          foods={list}
          onClickHandler={selectedFood => {
            OnClickHandler(selectedFood);
          }}
        />
      );
    }
  }

  return (
    <div className="mb-4 relative">
      {list && list.length > 0 && (
        <ul className={classesList.listWrapper}>{renderRecentFoodComponent()}</ul>
      )}
    </div>
  );
}
