import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import MealList from '../../components/meals/meals/mealList';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useSelector} from 'react-redux';

import {useTranslation} from 'react-i18next';
import {TrainingKeys} from '../../constants/training/trainingKeys';
import {SessionState} from 'http2';
import {StateOfTraining} from '../../models/common/stateOfTraining';
import classes from '../../components/shared/sharedButton.module.scss';
import sharedColor from '../../components/shared/sharedColorConfigurations.module.scss';
import {SessionDto} from '../../models/trainingModel';
import {getSessions} from '../../actions/trainings/session.action';
import {selectCurrentUserId} from '../../store/account/slices/login.slice';
function SessionsPage() {
  const [sessions, setSession] = useState<SessionDto[]>();
  const [pageNumber, SetPageNumber] = useState<number>(0);
  const pageCount = 10;
  const userId = useSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSessions(userId, undefined, undefined, undefined, pageCount, pageNumber)).then(
      result => {
        setSession(result);
      },
    );
  }, []);

  const {t} = useTranslation();
  return <div>{sessions?.map(f => f.dateTime)}</div>;
}

export default SessionsPage;
