import React from 'react';
import classesModule from '../../../../layouts/auth.module.scss';
import classes from './authButton.module.scss';
interface AuthButtonProperties {
  labelValue: string;
  onClickHandler?: () => void;
  isSubmit?: boolean;
}

export function AuthButton({onClickHandler, labelValue, isSubmit}: AuthButtonProperties) {
  if (isSubmit) {
    return (
      <div className={`${classesModule.auth} col-12`}>
        <input
          type="submit"
          className={`${classesModule.auth} ${classes.auth} ${classesModule.authSubmit} ${classesModule.buttonAuthFull}`}
          value={labelValue}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`${classesModule.auth} ${classesModule.authSubmit} ${classesModule.buttonAuthFull}`}>
        <span
          className={classesModule.authSubmit}
          onClick={() => {
            if (onClickHandler != null) {
              onClickHandler();
            }
          }}>
          {labelValue}
        </span>
      </div>
    );
  }
}
