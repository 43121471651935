import React, {ChangeEvent, useEffect, useState} from 'react';
import {Macronutrients} from '../../../../models/mealModel';
import classes from './macronutrientsComponent.module.css';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
import {useTranslation} from 'react-i18next';
import MacronutrientsViewComponent from '../../foods/macronutrientsView/macronutrientsView.component';
export interface MacronutrientsComponentProperties {
  macronutrients?: Macronutrients;
  weight?: number;
}
export function MacronutrientsComponent({
  macronutrients,
  weight,
}: MacronutrientsComponentProperties) {
  const {t} = useTranslation();
  if (macronutrients != null) {
    return (
      <>
        {!weight && <MacronutrientsViewComponent macronutrients={macronutrients} />}
        {weight && (
          <div>
            <p
              className={classes.MacronutrientsMacronutrientsValue}
              style={{display: 'inline-block', margin: 3}}>
              <div className={classesColor.sidebarColor}>{t('M_calories')}</div>
              {macronutrients?.calories && ((macronutrients?.calories * weight) / 100).toFixed(2)}
              <p className={classes.MacronutrientsUpperIndex}>
                {macronutrients?.calories?.toFixed(0)}
              </p>
            </p>
            <p
              className={classes.MacronutrientsMacronutrientsValue}
              style={{display: 'inline-block', margin: 3}}>
              <div className={classesColor.sidebarColor}>{t('M_carbohydrates')}</div>
              {macronutrients?.carbohydrates &&
                ((macronutrients?.carbohydrates * weight) / 100).toFixed(2)}
              <p className={classes.MacronutrientsUpperIndex}>
                {macronutrients?.carbohydrates?.toFixed(0)}
              </p>
            </p>
            <p
              className={classes.MacronutrientsMacronutrientsValue}
              style={{display: 'inline-block', margin: 3}}>
              <div className={classesColor.sidebarColor}>{t('M_fats')}</div>
              {macronutrients?.fats && ((macronutrients?.fats * weight) / 100).toFixed(2)}
              <p className={classes.MacronutrientsUpperIndex}>{macronutrients?.fats?.toFixed(0)}</p>
            </p>
            <p
              className={classes.MacronutrientsMacronutrientsValue}
              style={{display: 'inline-block', margin: 3}}>
              <div className={classesColor.sidebarColor}>{t('M_proteins')}</div>
              {macronutrients?.proteins && ((macronutrients?.proteins * weight) / 100).toFixed(2)}
              <p className={classes.MacronutrientsUpperIndex}>
                {macronutrients?.proteins?.toFixed(0)}
              </p>
            </p>
          </div>
        )}
      </>
    );
  } else {
    return <span> </span>;
  }
}
