import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {faBarcode, faVial} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalWrapper from '../common/modal/modalWrapper/modalWrapper.component';
import ThemeList from './themeList/themesList.component';
import ThemeChangerBtn from './themeChangerBtn/themeChangerBtn.component';

export interface ThemeChangerProperties {}

export default function ThemeChanger() {
  const [isSelectThemeActivated, SetIsSelectThemeActivated] = useState<boolean>(false);
  return (
    <>
      <ModalWrapper
        handleClose={() => {
          SetIsSelectThemeActivated(false);
        }}
        isOpen={isSelectThemeActivated}>
        <ThemeList></ThemeList>
      </ModalWrapper>
      <ThemeChangerBtn
        onClickHandler={function (): void {
          SetIsSelectThemeActivated(true);
        }}></ThemeChangerBtn>
    </>
  );
}
