import React, {useState} from 'react';
import './dayChoosing.component.scss';
export interface DayChoosingComponentProperties {
  day: string;
  numberOfDay: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickHandler: (numberOfDay: number, state: boolean) => any;
}
export default function DayChoosingComponent({
  day,
  numberOfDay,
  onClickHandler,
}: DayChoosingComponentProperties) {
  const [IsSelected, SetIsSelected] = useState(false);
  return (
    <div
      onClick={() => {
        onClickHandler(numberOfDay, !IsSelected);
        SetIsSelected(!IsSelected);
      }}
      className={`mx-2 cursor-pointer ${IsSelected ? 'selectedDay' : ''}`}
      key={day}>
      {day}
    </div>
  );
}
