/* eslint-disable @typescript-eslint/no-explicit-any */
import {DateTime} from 'luxon';
import {SessionDto, SetDto, TrainingDto} from '../../../../models/trainingModel';
import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../../hooks/redux';
import {largeCommonDateFormat} from '../../../../constants/common/dateFormat';
import {DateTimePicker} from '@mui/x-date-pickers';

import {SetsWrapper} from '../../sets/setsWrapper.component';
export interface AddEditSessionComponentProperties {
  modificatedSession: SessionDto;
  onSetChangeHandler: (params: SetDto) => any;
  saveHandler: (params: any) => any;
}
export default function AddEditSessionComponent({
  modificatedSession,
  saveHandler,
  onSetChangeHandler,
}: AddEditSessionComponentProperties) {
  const [session, setSession] = useState<SessionDto>(modificatedSession);
  // const [sets, setSets] = useState<SetDto[]>();
  // useEffect(() => {
  //   setSets(session.sets);
  // }, [session,modificatedSession])
  return (
    <div key={session.id}>
      {session.sets && (
        <SetsWrapper
          list={session.sets}
          onChangeSetHandler={set => {
            onSetChangeHandler(set);
          }}
          removeTrainingHandler={(setToRemoveFrom, index, setIndex) => {
            const updatedSet = {...setToRemoveFrom};
            updatedSet.trainings.splice(index, 1); // Remove training from set
            onSetChangeHandler(updatedSet); // Update the modified set in the parent component
          }}></SetsWrapper>
      )}
      {session.dateTime && session.dateTime != '0001-01-01T00:00:00' && (
        <div>
          <DateTimePicker
            label="Controlled picker"
            value={DateTime.fromFormat(session?.dateTime, largeCommonDateFormat)}
            onChange={newValue => {
              newValue
                ? setSession(prevState => ({
                    ...prevState,
                    eatTime: newValue.toFormat(largeCommonDateFormat),
                  }))
                : console.log(newValue);
            }}
          />
        </div>
      )}
      {/* {TrainingDescriptionsession?.TrainingDescriptions != null &&
          TrainingDescriptionsession?.TrainingDescriptions.length > 0 && ( */}

      {/* )} */}
    </div>
  );
}
