import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import classesModal from '../../common/modal/styles/modalShared.module.scss';
import {faAdd, faBarcode, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export interface AddBtnProperties {
  onClickHandler: () => void;
}

export default function AddBtn({onClickHandler}: AddBtnProperties) {
  return (
    <div className={`${classesModal.addBtn}`} onClick={onClickHandler}>
      <FontAwesomeIcon icon={faPlus} />
    </div>
  );
}
