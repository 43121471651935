import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {LoginUserCommand} from '../../models/accountModel';
import {login} from '../../actions/account/login.actions';
import {useTranslation} from 'react-i18next';
import MainLogo from '../../components/common/logos/mainLogo';
import {AuthTextInput} from './components/authInputs/authInputs.component';
import AuthWrapperLogo from './components/authSocialWrapper.component';
import {AuthButton} from './components/authButton/authButton.component';
import {backdropClasses} from '@mui/material';
import classes from '../../layouts/auth.module.scss';
import {useForm} from 'react-hook-form';
import {
  ValidationConfig,
  emailValidationConfig,
  passwordValidationConfig,
} from '../../components/common/configurations/validationConfigurations';
import AuthPageLayout from '../../layouts/auth.layout';
import {sendForgotPassword} from '../../actions/account/account.actions';
export function ForgotPasswordPage() {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
    setValue,
    getFieldState,
  } = useForm<LoginUserCommand>({
    mode: 'onBlur',
  });
  const [form, setForm] = useState<LoginUserCommand>({
    password: '',
    email: '',
  });
  const {t} = useTranslation();
  const emailConfig: ValidationConfig = emailValidationConfig(control);
  const passwordConfig: ValidationConfig = passwordValidationConfig(control);

  const changeHandler = (name: string, value: string | number) => {
    setForm(prev => ({...prev, [name]: value}));
  };

  const forgotPasswordHandler = async () => {
    if (form.email != null) {
      sendForgotPassword(form.email)
        .then(f => {
          window.location.href = '/resetPassword?email=' + form.email;
        })
        .catch(f => {
          console.log(f);
        });
    }
  };
  return (
    <AuthPageLayout>
      <div className="row col-12">
        <form onSubmit={handleSubmit(forgotPasswordHandler)}>
          <AuthTextInput
            props={emailConfig}
            labelValue="Email"
            placeholder={t('Email')}
            className="col-12"
            onUpdate={value => {
              changeHandler('email', value);
            }}></AuthTextInput>

          <AuthButton labelValue={t('Forgot..')} isSubmit={true} />
        </form>
      </div>
    </AuthPageLayout>
  );
}
