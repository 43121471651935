import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';
import classes from './macronutrientsView.module.scss';
import classesList from '../../../common/scss/list.module.scss';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBarcode, faTrademark} from '@fortawesome/free-solid-svg-icons';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import {FoodDTO, Macronutrients} from '../../../../models/mealModel';

export interface MacronutrientsViewProperties {
  macronutrients: Macronutrients;
}

export default function MacronutrientsViewComponent({
  macronutrients,
}: MacronutrientsViewProperties) {
  const {t} = useTranslation();
  return (
    <div className={classes.wrapper}>
      <p style={{display: 'inline-block', margin: 3}}>
        <div className={classes.titleOfBlock}>{t('M_calories')}</div>
        {macronutrients?.calories?.toFixed(2)}
      </p>
      <p style={{display: 'inline-block', margin: 3}}>
        <div className={classes.titleOfBlock}>{t('M_carbohydrates')}</div>
        {macronutrients?.carbohydrates?.toFixed(2)}
      </p>
      <p style={{display: 'inline-block', margin: 3}}>
        <div className={classes.titleOfBlock}>{t('M_fats')}</div>
        {macronutrients?.fats?.toFixed(2)}
      </p>
      <p style={{display: 'inline-block', margin: 3}}>
        <div className={classes.titleOfBlock}>{t('M_proteins')}</div>
        {macronutrients?.proteins?.toFixed(2)}
      </p>
    </div>
  );
}
