import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState, useTransition} from 'react';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import MealList from '../../components/meals/meals/mealList';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getMeals} from '../../actions/meals/meal.action';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../store/account/slices/login.slice';
import {getCurrentUserInfo} from '../../actions/account/account.actions';
import {NavigationCalendar} from '../../components/navigation/navigationCalendar';
import {DateTime} from 'luxon';
import AddWeightUserComponent from '../../components/account/weight/addWeightUser.component';
import {shortCommonDateFormat} from '../../constants/common/dateFormat';
import {getLastDateForUser} from '../../utils/accountUtil';
import {MealDTO} from '../../models/mealModel';
import {groupBy} from '../../utils/collectionUtils';
import {MealDayPerPage} from '../../models/strict/mealStrictModel';
import {CalorieDeficitComponent} from '../../components/meals/common/calorieDeficitComponent/calorieDeficit.component';
import SessionWrapper from '../../components/trainings/sessions/sessions/sessionWrapper.component';
import {ScheduledTrainingPlanDto, SessionDto} from '../../models/trainingModel';
import {SessionDayPerPage} from '../../models/strict/sessionStrictModel';
import {deleteSession, getSessions} from '../../actions/trainings/session.action';
import CalendarItem from '../../components/calendar/calendarItem.component';
import {
  addScheduledTrainingPlans,
  deleteScheduledTrainingPlan,
  getScheduledTrainingPlans,
} from '../../actions/trainings/scheduledTrainingPlan.action';
import {useTranslation} from 'react-i18next';
import AddScheduleComponent from '../../components/trainings/schedule/addSchedule.component';
import ModalWrapper from '../../components/common/modal/modalWrapper/modalWrapper.component';
import {addSchedules} from '../../actions/trainings/schedule.action';
import AddScheduledTrainingPlanComponent from '../../components/trainings/scheduledTrainingPlan/addScheduledTrainingPlan.component';
import classes from './calendar.module.scss';
import classesColor from '../../components/shared/sharedColorConfigurations.module.scss';
import classesStructures from '../../components/shared/sharedComponent.module.scss';
function CalendarPage() {
  const dispatch = useAppDispatch();
  const [isAddSchedule, SetIsAddSchedule] = useState<boolean>(false);
  const [dateForNewScheduledPlanning, setDateForNewScheduledPlanning] = useState<DateTime>();
  const [sessions, setSessions] = useState<SessionDto[]>();
  const [meals, setMeals] = useState<MealDTO[]>();
  const today = DateTime.local();
  const [scheduledTrainingPlan, SetScheduledTrainingPlan] = useState<ScheduledTrainingPlanDto[]>();
  const startOfMonth = today.startOf('month');
  const endOfMonth = today.endOf('month');
  const startOfWeek = startOfMonth.minus({days: startOfMonth.weekday % 7});
  const endOfWeek = endOfMonth.plus({days: 6 - (endOfMonth.weekday % 7)});
  const {t} = useTranslation();
  const userId = useSelector(selectCurrentUserId);
  const days = [];
  const [highlightedDay, setHighlightedDay] = useState<DateTime | null>(null); // State to track highlighted day

  // Function to handle hover
  const handleHover = (day: DateTime) => {
    setHighlightedDay(day);
  };

  let currentDay = startOfWeek;
  const updateCalendar = () => {
    const now = DateTime.now();
    const lastDayOfPreviousMonth = now.minus({months: 1}).endOf('month').toISODate();
    const firstDayOfNextMonth = now.plus({months: 1}).startOf('month').toISODate();
    dispatch(getScheduledTrainingPlans(userId, lastDayOfPreviousMonth!, firstDayOfNextMonth!)).then(
      result => {
        SetScheduledTrainingPlan(result);
      },
    );
    dispatch(getSessions(userId, lastDayOfPreviousMonth!, firstDayOfNextMonth!)).then(
      oldSessions => {
        if (oldSessions != undefined) {
          setSessions(oldSessions);
        } else {
          setSessions([]);
        }
      },
    );
    dispatch(getMeals(lastDayOfPreviousMonth!, userId, firstDayOfNextMonth!)).then(f => {
      setMeals(f);
    });
  };
  useEffect(() => {
    updateCalendar();
  }, []);

  while (currentDay <= endOfWeek) {
    days.push(currentDay);
    currentDay = currentDay.plus({days: 1});
  }
  const getSessionsForDay = (day: DateTime) => {
    if (day != null) {
      const dayString = day!.toISO()!.slice(0, 10);

      return sessions?.filter(f => f.dateTime != null && f.dateTime.slice(0, 10) === dayString);
    }
    return [];
  };
  const getMealsForDay = (day: DateTime) => {
    if (day != null) {
      const dayString = day!.toISO()!.slice(0, 10);

      return meals?.filter(f => f.eatTime != null && f.eatTime.slice(0, 10) === dayString);
    }
    return [];
  };
  const getTrainingPlanForDay = (day: DateTime): ScheduledTrainingPlanDto[] => {
    if (day != null) {
      const dayString = day!.toISO()!.slice(0, 10);

      const result = scheduledTrainingPlan?.filter(
        f => f.scheduledDate != null && f.scheduledDate.slice(0, 10) === dayString,
      );
      if (result != undefined) {
        return result;
      }
    }
    return [];
  };
  return (
    <div>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <div
          style={{maxWidth: '200px'}}
          className={classesStructures.buttonPrimary + ' my-3 mx-3'}
          onClick={() => {
            SetIsAddSchedule(true);
          }}>
          {t('AddSchedule')}
        </div>
      </div>

      <ModalWrapper
        handleClose={function () {
          SetIsAddSchedule(false);
        }}
        isOpen={isAddSchedule}>
        <AddScheduleComponent
          saveHandler={schedule => {
            dispatch(addSchedules(schedule));
            SetIsAddSchedule(false);
            updateCalendar();
          }}
        />
      </ModalWrapper>
      <ModalWrapper
        handleClose={function () {
          SetIsAddSchedule(false);
        }}
        isOpen={dateForNewScheduledPlanning != null}>
        <AddScheduledTrainingPlanComponent
          saveHandler={schedule => {
            dispatch(addScheduledTrainingPlans(schedule));
            setDateForNewScheduledPlanning(undefined);
            updateCalendar();
          }}
          scheduledDate={dateForNewScheduledPlanning}
        />
      </ModalWrapper>
      <div className={classes.calendarWrapper}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div
            key={day}
            className={classesColor.sidebarColor}
            style={{padding: '10px', fontWeight: 'bold', textAlign: 'center'}}>
            {day}
          </div>
        ))}
        {days.map(day => (
          <CalendarItem
            openAddScheduledTrainingPlanHandler={dateOfScheduled => {
              setDateForNewScheduledPlanning(dateOfScheduled);
            }}
            sessions={getSessionsForDay(day)}
            meals={getMealsForDay(day)}
            key={day.day + ' ' + day.month}
            trainings={getTrainingPlanForDay(day)}
            isSelected={highlightedDay?.equals(day) ?? false}
            onHover={handleHover}
            clickDeleteHandler={(id: string) => {
              dispatch(deleteScheduledTrainingPlan(id)).then(() => {
                updateCalendar();
              });
            }}
            day={day}
            today={today}></CalendarItem>
        ))}
      </div>
    </div>
  );
  return <div className="content flex-row w-full items-center space-x-4"></div>;
}

export default CalendarPage;
