import React, {ChangeEvent, useEffect, useState} from 'react';

import {SessionDto} from '../../../../models/trainingModel';
import {SetDayComponent} from '../../sets/setsDay.component';
import {DateTime} from 'luxon';
import {Box, Modal, Typography} from '@mui/material';
import SummaryComponent from '../../realTimeTraining/manuallyTraining/summary/summary.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';

export interface SessionDayComponentProperties {
  session?: SessionDto;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteHandler?: (sessionId: string) => any;
}
export function SessionDayComponent({session, onDeleteHandler}: SessionDayComponentProperties) {
  const [IsCollapsed, SetIsCollapsed] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  if (session != null) {
    return (
      <div>
        <div
          key={session.id}
          onClick={handleOpen}
          style={{borderBottom: '1px white solid'}}
          className="px-2 py-1 mx-3">
          <span style={{margin: 10}}>
            <span style={{margin: 10, color: 'white'}}>
              {session.dateTime != null &&
                DateTime.fromISO(session.dateTime).toFormat('dd.MM hh:mm')}
            </span>
          </span>
          <span style={{color: 'white'}}> Sets number:{session.sets?.length}</span>
          {onDeleteHandler && (
            <span
              className="ml-2"
              onClick={event => {
                event.stopPropagation();
                if (session.id != null && session.id != undefined) {
                  onDeleteHandler(session.id);
                }
              }}>
              <FontAwesomeIcon className="color-white" icon={faXmark} />
            </span>
          )}
          {IsCollapsed &&
            session?.sets?.map(set => <SetDayComponent key={set.id} set={set}></SetDayComponent>)}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <SummaryComponent session={session}></SummaryComponent>
          </Box>
        </Modal>
      </div>
    );
  } else {
    return <span> </span>;
  }
}
