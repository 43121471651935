import {Route} from 'react-router-dom';
import AuthGuard from '../guards/auth.guard';
import ProfilePage from '../pages/profile/profile.page';
import React from 'react';
import DayMgmtPage from '../pages/dayMgmt/daymgmt.page';
import RestaurantMgmtPage from '../pages/resturantMgmt/restaurantmgmg.page';
import RealTimeTrainingPage from '../pages/realtimeTraining/realtimetraining.page';
import TrainingPlanPage from '../pages/trainingPlan/trainingPlan.page';
import MainPage from '../pages/main/main.page';
import Calendar from 'react-calendar';
import CalendarPage from '../pages/calendar/calendar.page';
import GroupMgmtPage from '../pages/groups/group.page';
import UserProfilePage from '../pages/profile/userProfile.page';
import SessionsPage from '../pages/session/sessions.page';
import HistoryPage from '../pages/history/history.page';
const AuthRoutes = [
  <Route key="MainPage" path="/" element={<AuthGuard component={MainPage} />} />,
  <Route key="Profile" path="/profile" element={<AuthGuard component={ProfilePage} />} />,
  <Route key="Profile" path="/profile/:id" element={<AuthGuard component={UserProfilePage} />} />,
  <Route key="History" path="/history/:id" element={<AuthGuard component={HistoryPage} />} />,
  <Route key="Sessions" path="/sessions/:id" element={<AuthGuard component={SessionsPage} />} />,
  <Route
    key="TrainingPlan"
    path="/trainingplan"
    element={<AuthGuard component={TrainingPlanPage} />}
  />,
  <Route
    key="RestaurantsPage"
    path="/restaurants"
    element={<AuthGuard component={RestaurantMgmtPage} />}
  />,
  <Route
    key="RealTimeTrainingPage"
    path="/realtimetrainings"
    element={<AuthGuard component={RealTimeTrainingPage} />}
  />,
  <Route key="Groups" path="/Groups" element={<AuthGuard component={GroupMgmtPage} />} />,
  <Route key="CalendarPage" path="/calendar" element={<AuthGuard component={CalendarPage} />} />,
];

export default AuthRoutes;
