import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../..';
import {GroupDto} from '../../../models/strict/accountStrictModel';
interface groupState {
  groups: GroupDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: groupState = {
  groups: [],
  count: 0,
  loading: false,
  error: '',
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    groupsFetching(state) {
      state.loading = true;
    },
    usesrFetchingSuccess(state, action: PayloadAction<GroupDto[]>) {
      state.error = '';
      state.groups = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    groupsFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    groupFetching(state) {
      state.loading = true;
    },
    groupFetchingSuccess(state, action: PayloadAction<GroupDto>) {
      state.error = '';
      state.loading = false;
    },
    groupsFetchingSuccess(state, action: PayloadAction<GroupDto[]>) {
      state.error = '';
      state.loading = false;
    },
    groupFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default groupSlice.reducer;
