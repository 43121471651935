import React, {ChangeEvent, useEffect, useState} from 'react';

import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker, DateTimePicker, TimePicker} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';
import {largeCommonDateFormat, longCommonTimeFormat} from '../../../constants/common/dateFormat';
import {useAppDispatch} from '../../../hooks/redux';
import {
  TrainingDto,
  TrainingDescriptionDto,
  ScheduledTrainingPlanDto,
  TrainingPlanDto,
  CreateScheduledTrainingPlanDto,
  ScheduleDto,
} from '../../../models/trainingModel';
import TrainingDescriptionInput from '../trainingDescription/trainingDescriptionInput/trainingDescriptionInput.component';
import {duration} from '@mui/material';
import {
  CreateScheduledTrainingPlanObject,
  TimeSpanObject,
  TrainingObject,
} from '../../../models/strict/sessionStrictModel';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {DateInputProps} from '../../../constants/common/dateInputProps';
import {getTrainingPlans} from '../../../actions/trainings/trainingPlan.action';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../../store/account/slices/login.slice';
import TrainingPlanWrapper from '../trainingPlan/trainingPlanWrapper/trainingPlanWrapper.component';
import {addScheduledTrainingPlans} from '../../../actions/trainings/scheduledTrainingPlan.action';

export interface AddScheduledTrainingPlanComponentProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
  scheduledDate?: DateTime;
}
export default function AddScheduledTrainingPlanComponent({
  saveHandler,
  scheduledDate,
}: AddScheduledTrainingPlanComponentProperties) {
  const {t} = useTranslation();
  const [newScheduledTrainingPlan, setNewScheduledTrainingPlan] =
    useState<CreateScheduledTrainingPlanDto>(new CreateScheduledTrainingPlanObject());
  const [trainingPlans, setTrainingPlans] = useState<TrainingPlanDto[]>();
  const [schedule, setSchedules] = useState<ScheduleDto>();
  const user = useSelector(selectCurrentUser);
  const userId = useSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTrainingPlans(userId)).then(f => setTrainingPlans(f));
  }, []);
  useEffect(() => {
    setNewScheduledTrainingPlan({
      ...newScheduledTrainingPlan,
      description: '',
      scheduledDate: scheduledDate?.toUTC().toISO(),
    });
  }, [scheduledDate]);
  if (scheduledDate == null) {
    return <div></div>;
  }
  return (
    <div className="row col-12 relative" style={{marginLeft: 0}}>
      <p className="">{t('Training')}</p>
      <div
        className="row col-12 border-primary-custom py-4 primaryBackground custom-border"
        style={{marginLeft: 0}}>
        <div className="row col-12 " style={{marginLeft: 0}}>
          <TrainingPlanWrapper
            IsShowAddIcon={false}
            onSelectTrainingPlanHandler={trainingPlan => {
              setNewScheduledTrainingPlan({...newScheduledTrainingPlan, planId: trainingPlan.id});
            }}
            list={trainingPlans}
          />
        </div>

        <div style={{display: 'flex'}} className="py-4 justify-content-center"></div>
        <div className="col-6">
          <DateTimePicker
            label="Controlled picker"
            value={
              newScheduledTrainingPlan?.scheduledDate
                ? DateTime.fromISO(newScheduledTrainingPlan?.scheduledDate)
                : DateTime.now()
            }
            slotProps={DateInputProps.dateTimeSlots}
            onChange={newValue => {
              newValue
                ? setNewScheduledTrainingPlan(prevState => ({
                    ...prevState,
                    ScheduledTrainingPlanDateStart: newValue.toUTC().toISO(),
                  }))
                : console.log(newValue);
            }}
          />
        </div>
      </div>{' '}
      <span
        className="mt-3 top-0 right-0 absolute"
        onClick={() => {
          newScheduledTrainingPlan.userId = userId;
          saveHandler(newScheduledTrainingPlan);
        }}>
        <FontAwesomeIcon icon={faSave} size="lg" />
      </span>
    </div>
  );
}
