import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {LoginUserCommand} from '../../models/accountModel';
import {login} from '../../actions/account/login.actions';
import {useTranslation} from 'react-i18next';
import MainLogo from '../../components/common/logos/mainLogo';
import {AuthTextInput} from './components/authInputs/authInputs.component';
import AuthWrapperLogo from './components/authSocialWrapper.component';
import {AuthButton} from './components/authButton/authButton.component';
import {backdropClasses} from '@mui/material';
import classes from '../../layouts/auth.layout';
import {useForm} from 'react-hook-form';
import {
  ValidationConfig,
  emailValidationConfig,
  passwordValidationConfig,
  simpleTextValidationConfig,
} from '../../components/common/configurations/validationConfigurations';
import AuthPageLayout from '../../layouts/auth.layout';
import {resetPassword, sendForgotPassword} from '../../actions/account/account.actions';
import {ResetPasswordCommand} from '../../models/strict/accountStrictModel';
export function ResetPasswordPage() {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
    setValue,
    getFieldState,
  } = useForm<LoginUserCommand>({
    mode: 'onBlur',
  });
  const [form, setForm] = useState<ResetPasswordCommand>({
    newPassword: '',
    email: '',
    code: '',
  });
  const {t} = useTranslation();
  const [emailConfig, setEmailConfig] = useState<ValidationConfig>();
  const passwordConfig: ValidationConfig = passwordValidationConfig(control);
  const [codeConfig, setCodeConfig] = useState<ValidationConfig>();

  function getEmailInitiate() {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    if (emailParam != null) {
      setForm(prev => ({...prev, email: emailParam}));
      return emailParam;
    }
    return '';
  }
  function getCodeInitiate() {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');
    if (codeParam != null) {
      setForm(prev => ({...prev, code: codeParam}));
      return codeParam;
    }
    return '';
  }
  const changeHandler = (name: string, value: string | number) => {
    setForm(prev => ({...prev, [name]: value}));
  };
  useEffect(() => {
    const newEmailConfig = emailValidationConfig(control);
    const emailInitiate = getEmailInitiate();
    if (emailInitiate != '') {
      newEmailConfig.defaultValue = emailInitiate;
    }
    setEmailConfig(emailConfig);
    const newCodeConfig = simpleTextValidationConfig(control, 'code');
    const codeInitiate = getCodeInitiate();
    if (codeInitiate != '') {
      newCodeConfig.defaultValue = codeInitiate;
    }
    setCodeConfig(newCodeConfig);
    return () => {
      [];
    };
  }, []);

  const resetPasswordHandler = async () => {
    if (form.email != null) {
      await dispatch(resetPassword(form));
    }
  };
  return (
    <AuthPageLayout>
      <div className="row col-12">
        <form onSubmit={handleSubmit(resetPasswordHandler)}>
          {emailConfig != null && (
            <AuthTextInput
              props={emailConfig}
              labelValue="Email"
              placeholder={t('Email')}
              className="col-12"
              onUpdate={value => {
                changeHandler('email', value);
              }}></AuthTextInput>
          )}
          {codeConfig != null && (
            <AuthTextInput
              props={codeConfig}
              labelValue="Code"
              placeholder={t('Code')}
              className="col-12"
              onUpdate={value => {
                changeHandler('code', value);
              }}></AuthTextInput>
          )}
          <AuthTextInput
            type="password"
            props={passwordConfig}
            labelValue="newPassword"
            placeholder={t('newPassword')}
            className="col-12"
            onUpdate={value => {
              changeHandler('newPassword', value);
            }}></AuthTextInput>

          <AuthButton labelValue={t('Reset Password')} isSubmit={true} />
        </form>
      </div>
    </AuthPageLayout>
  );
}
