import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RestaurantDTO} from '../../../models/mealModel';
import {RestaurantObject} from '../../../models/strict/mealStrictModel';

interface restaurantState {
  restaurant: RestaurantDTO;
  restaurants: RestaurantDTO[];
  restaurantsContainer: RestaurantDTO[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: restaurantState = {
  restaurant: new RestaurantObject(),
  restaurants: [],
  restaurantsContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    restaurantFetching(state) {
      state.loading = true;
    },
    restaurantFetchingSuccess(state, action: PayloadAction<RestaurantDTO>) {
      state.error = '';
      state.restaurant = action.payload;
      state.loading = false;
    },
    restaurantFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    restaurantsFetchingSuccess(state, action: PayloadAction<RestaurantDTO[]>) {
      state.error = '';
      state.restaurants = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    restaurantsFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default restaurantSlice.reducer;
