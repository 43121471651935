import {keyValuePair} from '../../../models/common/keyValuePair';

export const locales = [
  new keyValuePair('en', 'English'),
  new keyValuePair('uk', 'Ukrainian'),
  new keyValuePair('es', 'Spanish'),
  new keyValuePair('fr', 'French'),
  new keyValuePair('de', 'German'),
  new keyValuePair('it', 'Italian'),
  new keyValuePair('pt', 'Portuguese'),
  new keyValuePair('ru', 'Russian'),
  new keyValuePair('zh', 'Chinese'),
  new keyValuePair('ja', 'Japanese'),
  new keyValuePair('ko', 'Korean'),
  new keyValuePair('ar', 'Arabic'),
  new keyValuePair('hi', 'Hindi'),
  new keyValuePair('pl', 'Polish'),
  new keyValuePair('nl', 'Dutch'),
  new keyValuePair('sv', 'Swedish'),
  new keyValuePair('tr', 'Turkish'),
  new keyValuePair('no', 'Norwegian'),
  new keyValuePair('fi', 'Finnish'),
  new keyValuePair('cs', 'Czech'),
  new keyValuePair('ro', 'Romanian'),
  new keyValuePair('hu', 'Hungarian'),
  new keyValuePair('bg', 'Bulgarian'),
  new keyValuePair('el', 'Greek'),
  new keyValuePair('he', 'Hebrew'),
  new keyValuePair('th', 'Thai'),
  new keyValuePair('vi', 'Vietnamese'),
];
