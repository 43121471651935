import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {LoginUserCommand} from '../../models/accountModel';
import {login} from '../../actions/account/login.actions';
import {useTranslation} from 'react-i18next';
import MainLogo from '../../components/common/logos/mainLogo';
import {AuthTextInput} from './components/authInputs/authInputs.component';
import AuthWrapperLogo from './components/authSocialWrapper.component';
import {AuthButton} from './components/authButton/authButton.component';
import {backdropClasses} from '@mui/material';
import classes from '../../layouts/auth.layout';
import {useForm} from 'react-hook-form';
import {
  ValidationConfig,
  emailValidationConfig,
  passwordValidationConfig,
} from '../../components/common/configurations/validationConfigurations';
import {useLocation} from 'react-router-dom';
import {confirmEmail} from '../../actions/account/account.actions';
import AuthPageLayout from '../../layouts/auth.layout';
import Loader from '../../components/common/loader/globalLoader/globalLoader.component';
export function EmailConfirmPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [IsLoading, SetIsLoading] = useState<boolean>(true);
  const [IsSuccess, SetIsSuccess] = useState<boolean>(false);
  const {t} = useTranslation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    const codeParam = queryParams.get('code');
    if (emailParam != null && codeParam != null) {
      confirmEmail(emailParam, codeParam).then(f => {
        SetIsLoading(false);
        console.log(f);
        SetIsSuccess(f as boolean);
      });
    }
  }, [location, dispatch]);

  return (
    <AuthPageLayout>
      <div className="row col-12">
        {IsLoading && (
          <div className="row col-12" style={{marginTop: 30, marginBottom: 80}}>
            <Loader />
          </div>
        )}
        {!IsLoading && !IsSuccess && <div>{t('Verification is failed')}</div>}
        {!IsLoading && IsSuccess && <div>{t('Verification is success')}</div>}
        <div className="row col-12" style={{marginTop: 30, marginBottom: 80}}>
          <div className="col-6 text-end">
            <span>
              {t('EmailIsConfirmed')} <a href="signin"> {t('Go to the signin page')} </a>
            </span>
          </div>
        </div>
      </div>
    </AuthPageLayout>
  );
}
