import React, {ChangeEvent, useEffect, useState} from 'react';

import {faVial} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classes from './themeChangerBtn.module.scss';
export interface ThemeChangerBtnProperties {
  onClickHandler: () => void;
}

export default function ThemeChangerBtn({onClickHandler}: ThemeChangerBtnProperties) {
  return (
    <button
      onClick={() => {
        onClickHandler();
      }}>
      <FontAwesomeIcon className={classes.themeChangeBtn} icon={faVial} />
    </button>
  );
}
