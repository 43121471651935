import {DateTime} from 'luxon';
import {
  CreateSessionDto,
  CreateSetDto,
  SessionDto,
  SetDto,
  TagTrainingDescriptionDto,
  TimeSpan,
  TrainingDto,
  TrainingDescriptionDto,
  TrainingPlanDto,
  ScheduledTrainingPlanDto,
  ScheduleDto,
  CreateScheduledTrainingPlanDto,
  CreateScheduleDto,
} from '../trainingModel';
import {largeCommonDateFormat} from '../../constants/common/dateFormat';
export class SessionObject implements SessionDto {
  constructor(
    public sets: SetDto[] = [],
    public dateTime?: string,
    public isAddedManually?: boolean,
    public duration?: string,
    public id?: string,
    public userId?: string | null,
  ) {}
}
export class CreateScheduleDtoObject implements CreateScheduleDto {
  constructor(
    public planId?: string | null,
    public scheduleDateStart?: string | null,
    public scheduleDateEnd?: string | null,
    public everyCount?: number,
    public weekdays?: number[] | null,
    public userId?: string | null,
    public groupId?: string | null,
  ) {
    if (weekdays == null) {
      scheduleDateStart = DateTime.now().toUTC().toISO();
      scheduleDateEnd = DateTime.now().toUTC().toISO();
      weekdays = [];
    }
  }
}
export class CreateSessionObject implements CreateSessionDto {
  constructor(
    public sets: CreateSetDto[] = [],
    public dateTime?: string,
    public userId?: string | null,
    public isAddedManually?: boolean,
    public duration?: string,
  ) {
    if (sets == null) {
      sets = [];
    }
  }
}
export class TrainingPlanObject implements TrainingPlanDto {
  constructor(
    public id?: string,
    public session?: SessionDto,
    public userId?: string,
    public description?: string | null,
    public name?: string | null,
  ) {}
}
export class CreateSetObject implements CreateSetDto {
  constructor(
    public trainings: TrainingDto[],
    public order?: number,
    public duration?: string | null,
  ) {}
}
export class ScheduleObject implements ScheduleDto {
  constructor(
    public id?: string | null,
    public scheduleDateStart?: string | null,
    public scheduleDateEnd?: string | null,
    public everyCount?: number,
    public weekdays?: number[] | null,
    public scheduledTrainingPlans?: ScheduledTrainingPlanDto[] | null,

    public userId?: string | null,
    public groupId?: string | null,
  ) {}
}
export class CreateScheduleObject implements ScheduleDto {
  constructor(
    public planId?: string,
    public scheduleDateStart?: string | null,
    public scheduleDateEnd?: string | null,
    public everyCount?: number,
    public weekdays?: number[] | null,
    public userId?: string | null,
    public groupId?: string | null,
  ) {}
}
export class ScheduledTrainingPlanObject implements ScheduledTrainingPlanDto {
  /** @format uuid */
  constructor(
    public id?: string | null,
    public planId?: string | null,
    public plan?: TrainingPlanDto | null,
    public userId?: string | null,
    public groupId?: string | null,
    public description?: string | null,
    /** @format date-time */
    public scheduledDate?: string | null,
    public schedule?: ScheduleDto,
    /** @format uuid */
    public scheduleId?: string | null,
  ) {}
}
export class CreateScheduledTrainingPlanObject implements CreateScheduledTrainingPlanDto {
  /** @format uuid */
  constructor(
    public id?: string | null,
    public planId?: string | null,
    public description?: string | null,
    public userId?: string | null,
    public groupId?: string | null,
    public scheduledDate?: string | null,
    public scheduleId?: string | null,
  ) {}
}
export class TagTrainingDescriptionObject implements TagTrainingDescriptionDto {
  constructor(
    public id?: string,
    public name?: string | null,
  ) {}
}
export class SessionDayPerPage {
  constructor(
    public date: DateTime,
    public isRequested: boolean,
    public sessions?: SessionDto[],
    public isAddedManually?: boolean,
    public duration?: string,
  ) {}
}
export class TrainingObject implements TrainingDto {
  constructor(
    public id?: string,
    public trainingDescriptionId?: string | null,
    public customTrainingName?: string | null,
    public counts?: number | null,
    public duration?: string | null,
    public chillTime?: string | null,
  ) {}
}
export class SetObject implements SetDto {
  trainings: TrainingDto[] = [];
  id?: string;
  sessionId?: string;
  duration?: string | null;

  constructor(
    trainings: TrainingDto[] = [],
    id?: string,
    sessionId?: string,
    duration?: string | null,
  ) {
    this.id = id;
    this.sessionId = sessionId;
    this.trainings = trainings;
    if (this.trainings == null) {
      trainings = [];
    }
    this.duration = duration;
  }
}
export class TrainingDescriptionObject implements TrainingDescriptionDto {
  constructor(
    public id?: string | null,
    public isDeleted?: boolean,
    public deletedAt?: string | null,
    public name?: string | null,
    public typeOfTrainingDescription?: number,
    public description?: string | null,
    public category?: string | null,
    public tags?: TagTrainingDescriptionDto[] | null,
  ) {}
}
export class TimeSpanObject implements TimeSpan {
  constructor(
    public ticks?: number,
    public days?: number,
    public hours?: number,
    public milliseconds?: number,
    public microseconds?: number,
    public nanoseconds?: number,
    public minutes?: number,
    public seconds?: number,
    public totalDays?: number,
    public totalHours?: number,
    public totalMilliseconds?: number,
    public totalMicroseconds?: number,
    public totalNanoseconds?: number,
    public totalMinutes?: number,
    public totalSeconds?: number,
  ) {}
}
