import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../..';
import {UserGroupDto} from '../../../models/strict/accountStrictModel';
interface userGroupState {
  userGroups: UserGroupDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: userGroupState = {
  userGroups: [],
  count: 0,
  loading: false,
  error: '',
};

export const userGroupSlice = createSlice({
  name: 'userGroup',
  initialState,
  reducers: {
    userGroupsFetching(state) {
      state.loading = true;
    },
    usesrFetchingSuccess(state, action: PayloadAction<UserGroupDto[]>) {
      state.error = '';
      state.userGroups = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    userGroupsFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    userGroupFetching(state) {
      state.loading = true;
    },
    userGroupFetchingSuccess(state, action: PayloadAction<UserGroupDto>) {
      state.error = '';
      state.loading = false;
    },
    userGroupsFetchingSuccess(state, action: PayloadAction<UserGroupDto[]>) {
      state.error = '';
      state.loading = false;
    },
    userGroupFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default userGroupSlice.reducer;
