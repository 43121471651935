import classes from './logos.module.css';
import React from 'react';
export interface BaseLogoProperties {
  onClickHandler?: () => void;
  className: string;
  height?: number;
  width?: number;
  margin?: string;
  aditionalClass?: string;
}
export default function BaseLogo({
  height = 60,
  width = 160,
  margin,
  className,
  onClickHandler,
  aditionalClass = '',
}: BaseLogoProperties) {
  return (
    <div
      style={{
        height: height,
        width: width,
        margin: margin,
        cursor: onClickHandler != null ? 'pointer' : 'default',
      }}
      className={`${classes.logo} ${aditionalClass} ${className}`}></div>
  );
}
