import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TrainingDto} from '../../../../../models/trainingModel';

import classes from './trainingState.module.scss';
import {faArrowAltCircleRight, faArrowRightArrowLeft} from '@fortawesome/free-solid-svg-icons';

export interface TrainingStateComponentProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickHandler?: (params: TrainingDto) => void;
  currentTraining?: TrainingDto;
  prevTraining?: TrainingDto;
  nextTraining?: TrainingDto;
}
export default function TrainingStateComponent({
  onClickHandler,
  prevTraining,
  nextTraining,
  currentTraining,
}: TrainingStateComponentProperties) {
  return (
    <div className={classes.trainingStateWrapper}>
      {prevTraining && (
        <>
          <div className={classes.text + ' shadowText shadowTextBack'}>
            <div style={{textAlign: 'center'}}>{prevTraining.customTrainingName}</div>
            {prevTraining?.trainingDescription?.typeOfTrainingDescription == 0 && (
              <div style={{textAlign: 'center'}}>{prevTraining.counts}</div>
            )}
          </div>
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
        </>
      )}
      {currentTraining && (
        <div className={classes.text + ' originText'}>
          <div
            onClick={() => {
              if (onClickHandler != null) {
                onClickHandler(currentTraining);
              }
            }}
            style={{textAlign: 'center', cursor: onClickHandler != null ? 'pointer' : 'default'}}>
            {currentTraining.customTrainingName}
          </div>

          {currentTraining?.trainingDescription?.typeOfTrainingDescription == 0 && (
            <div style={{textAlign: 'center'}}>{currentTraining.counts}</div>
          )}
        </div>
      )}
      {nextTraining && (
        <>
          <FontAwesomeIcon icon={faArrowAltCircleRight}></FontAwesomeIcon>
          <div className={classes.text + ' shadowText shadowTextNext '}>
            <div style={{textAlign: 'center'}}>{nextTraining.customTrainingName}</div>

            {nextTraining?.trainingDescription?.typeOfTrainingDescription == 0 && (
              <div style={{textAlign: 'center'}}>{nextTraining.counts}</div>
            )}
          </div>
        </>
      )}
    </div>
  );

  return <div></div>;
}
