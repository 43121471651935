import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';

import {CreateGroupDto, UserGroupRoleEnum} from '../../../models/strict/accountStrictModel';
import modalClasses from '../..//common/modal/styles/modalShared.module.scss';
import classesStructure from '../../shared/sharedComponent.module.scss';
export interface CreateGroupComponentProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
}

export default function CreateGroupComponent({saveHandler}: CreateGroupComponentProperties) {
  const {t} = useTranslation();
  const [newGroup, setNewGroup] = useState<CreateGroupDto>(
    new CreateGroupDto('', UserGroupRoleEnum.User),
  );

  return (
    <div
      className="row relative col-12 border-primary-custom pt-4 darkModalBackground color-white custom-border   px-4"
      style={{marginLeft: 0}}>
      <div className="col-6">
        <label htmlFor="Name" className="my-4">
          {t('Name')}
        </label>
        <input
          id="name"
          className={
            '  px-4 py-2 w-full outline-0 h-[42px] ' + classesStructure.transparentInputGrey
          }
          name="name"
          required
          onChange={event => {
            setNewGroup({...newGroup, name: event.target.value!});
          }}
        />
      </div>
      <div className="col-6">
        <label htmlFor="Description" className="my-4">
          {t('Description')}
        </label>
        <input
          id="Description"
          className={
            '  px-4 py-2 w-full outline-0 h-[42px] ' + classesStructure.transparentInputGrey
          }
          name="Description"
          onChange={event => {
            setNewGroup({...newGroup, description: event.target.value!});
          }}
        />
      </div>
      <div className="col-12 mb-5">
        <label htmlFor="Role" className="my-4">
          {t('Role')}
        </label>
        <select
          id="Role"
          className={
            '  px-4 py-2 w-full outline-0 h-[42px] ' + classesStructure.transparentInputGrey
          }
          name="Role"
          onChange={event => {
            setNewGroup({...newGroup, myRole: Number.parseInt(event.target.value)});
          }}>
          <option value={UserGroupRoleEnum.User}>{t('User')}</option>
          <option value={UserGroupRoleEnum.Trainer}>{t('Trainer')}</option>
          <option value={UserGroupRoleEnum.Owner}>{t('Owner')}</option>
        </select>
      </div>
      {newGroup.name.length > 3 && (
        <p className={modalClasses.saveBtn} onClick={() => saveHandler(newGroup)}>
          +
        </p>
      )}
    </div>
  );
}
