import {MealDTO} from '../../../models/mealModel';
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
interface mealState {
  meals: MealDTO[];
  mealsContainer: MealDTO[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: mealState = {
  meals: [],
  mealsContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const mealSlice = createSlice({
  name: 'meal',
  initialState,
  reducers: {
    mealFetching(state) {
      state.loading = true;
    },
    mealFetchingSuccess(state, action: PayloadAction<MealDTO[]>) {
      state.error = '';
      state.meals = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    mealFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default mealSlice.reducer;
