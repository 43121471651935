import React, {ChangeEvent, useEffect, useState} from 'react';
import {CustomTextInput} from '../../../../components/common/input/customTextInput.component';

import defaultclassesModule from '../../../../components/common/input/commonInput.module.css';
import classesModule from '../../../../layouts/auth.module.scss';
import classes from './authInputs.module.scss';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import ExlamationMarkIcon from '../../../../components/common/icon/exlamationMark/exlamationMark.component';
import {UseControllerProps, useController} from 'react-hook-form';
interface AuthTextInputProps {
  inputValue?: string;
  className?: string;
  labelValue: string;
  wrapperClassName?: string;
  type?: string;
  onUpdate: (value: string | number) => void;
  placeholder?: string;
  props: UseControllerProps;
  autocomplete?: string;
  name?: string;
}

export function AuthTextInput({
  className,
  onUpdate,
  labelValue,
  inputValue,
  placeholder,
  wrapperClassName = '',
  props,
  autocomplete,
  name,
  type = 'text',
}: AuthTextInputProps) {
  const {field, fieldState} = useController(props);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: string | number = event.target.value;
    if (type == 'number') {
      value = Number.parseFloat(value);
    }
    field.onChange(value);

    onUpdate(value);
  };
  useEffect(() => {
    if (inputValue != null && field.value < 2) {
      if (type == 'number') {
        field.onChange(Number.parseFloat(inputValue));
      } else {
        field.onChange(inputValue);
      }
    }
  }, [inputValue]);
  const isError = (): boolean => fieldState.error?.message != null && fieldState.invalid;
  return (
    <div className={wrapperClassName + ' ' + classesModule.wrapperInput}>
      <div
        className={classesModule.auth + ' row ' + className}
        style={{position: 'relative', padding: '0px', margin: '0px', alignItems: 'center'}}>
        {labelValue != null && field?.value.length > 0 && (
          <label
            className={
              classesModule.auth + ` col-12 ${isError() ? defaultclassesModule.error : ''}`
            }>
            {labelValue}
          </label>
        )}
        <input
          type={type}
          {...field}
          placeholder={placeholder}
          onBlur={field.onBlur}
          onChange={handleInputChange}
          className={`
            ${classesModule.auth} ${classesModule.authWrapperInnerInput} 
            ${isError() ? defaultclassesModule.error : ''}
          `}
          name={name}
          autoComplete={autocomplete || 'off'}
        />

        {isError() && (
          <ExlamationMarkIcon
            wrapperStyle={{
              position: 'absolute',
              right: 0,
              marginRight: 0,
              width: 50,
              height: '100%',
            }}
          />
        )}
      </div>
      {isError() && (
        <p style={{marginBottom: 0}} className={classesModule.authErrorMessage}>
          {fieldState.error?.message}
        </p>
      )}
    </div>
  );
}
