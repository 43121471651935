import {TrainingPlanDto} from '../../../../models/trainingModel';
import {useAppDispatch} from '../../../../hooks/redux';
import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {getTrainingPlan} from '../../../../actions/trainings/trainingPlan.action';
import './trainingPlan.component.scss';
export interface AddEditTrainingPlanComponentProperties {
  trainingPlan: TrainingPlanDto;
  onSelectHandler: (trainingplan: TrainingPlanDto) => void;
  onEditHandler?: (trainingplan: TrainingPlanDto) => void;
  onDeleteHandler?: (trainingPlanId: string) => void;
  isSelected: boolean;
}
export default function TrainingPlanComponent({
  trainingPlan,
  onSelectHandler,
  onEditHandler,
  onDeleteHandler,
  isSelected,
}: AddEditTrainingPlanComponentProperties) {
  const dispatch = useAppDispatch();

  return (
    <div className="d-flex justify-content-around training-plan-wrapper">
      <div
        className={
          'text-center mx-2 relative justify-content-center flex align-items-center cursor-pointer' +
          (isSelected ? ' selected' : '')
        }
        onClick={() => {
          onSelectHandler(trainingPlan);
        }}>
        <div className="absolute">
          <h3>{trainingPlan.name}</h3>
          <p>{trainingPlan.description}</p>
        </div>
        <img
          className="d-block w-100"
          style={{width: '100%', height: '100%'}}
          src="https://via.placeholder.com/200x200?text=Training+Plan"
          alt={`${trainingPlan.name}`}
        />
        <div className="absolute right-0 top-0 flex  training-plan-item-actions">
          {onEditHandler != null && onSelectHandler == null && (
            <span
              onClick={event => {
                event.stopPropagation();
                if (trainingPlan?.id != null) {
                  dispatch(getTrainingPlan(trainingPlan.id)).then(f =>
                    f != null ? onEditHandler(f) : console.log('something wrong'),
                  );
                }
              }}>
              <FontAwesomeIcon icon={faPenAlt}></FontAwesomeIcon>
            </span>
          )}
          {onDeleteHandler != null && onSelectHandler == null && trainingPlan.id && (
            <span
              onClick={event => {
                event.stopPropagation();
                if (trainingPlan.id) {
                  onDeleteHandler(trainingPlan.id);
                }
              }}>
              <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
