import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronCircleRight,
  faDiagramNext,
  faForward,
  faForwardStep,
  faMailForward,
} from '@fortawesome/free-solid-svg-icons';
import {DateTime} from 'luxon';
import {
  longCommonTimeFormat,
  shortCommonTimeFormat,
} from '../../../../../constants/common/dateFormat';
import {useTranslation} from 'react-i18next';
import React from 'react';
import classesSharedColor from '../../../../shared/sharedColorConfigurations.module.scss';
export interface TrainingTimeInformationComponentProperties {
  startDate?: DateTime;
  duration?: string;
}
export default function TrainingTimeInformationComponent({
  startDate,
  duration,
}: TrainingTimeInformationComponentProperties) {
  const {t} = useTranslation();
  if (startDate != null) {
    return (
      <div className="flex" style={{position: 'absolute', top: '30px'}}>
        <div className="px-2" style={{color: 'white'}} color="white">
          <span className={classesSharedColor.sidebarColor + ' mx-2'}>{t('TrainingStart')}:</span>
          {startDate.toFormat(longCommonTimeFormat)}
        </div>
        {duration != null && duration.length > 2 && (
          <div className="px-2" style={{color: 'white'}} color="white">
            <span className={classesSharedColor.sidebarColor + ' mx-2'}>
              {t('PreviousDuration')}:
            </span>
            {duration}
          </div>
        )}
      </div>
    );
  }

  return <></>;
}
