import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import SessionWrapper from '../../components/trainings/sessions/sessions/sessionWrapper.component';
import {SessionDto} from '../../models/trainingModel';
import {SetFormula, getCurrentUserInfo} from '../../actions/account/account.actions';
import {useAppDispatch} from '../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../store/account/slices/login.slice';
import {deleteSession, getSessions} from '../../actions/trainings/session.action';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import {MealDTO} from '../../models/mealModel';
import {getMeals} from '../../actions/meals/meal.action';
import WeightInput from '../../components/common/weightInput.component';
import {SelectCustomInput} from '../../components/common/input/selectInput.component';
import {CommonSelectInput} from '../../components/common/input/commonSelectInput.component';
import {createKeyValuePairsFromEnum} from '../../utils/enumUtil';
import {KcalFormulaEnum} from '../../models/accountModel';
import {keyValuePair} from '../../models/common/keyValuePair';

function ProfilePage() {
  const [lastSessions, SetLastSessions] = useState<SessionDto[]>();
  const [lastMeals, SetMeals] = useState<MealDTO[]>();

  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  const userId = useSelector(selectCurrentUserId);

  const refreshHandler = () => {
    dispatch(getSessions(userId)).then(result => {
      SetLastSessions(result);
    });
    dispatch(getMeals('', userId)).then(result => {
      SetMeals(result);
    });
  };

  useEffect(() => {
    dispatch(getCurrentUserInfo());
    refreshHandler();
  }, []);

  return <div className="content flex-row w-full  row px-2"></div>;
}

export default ProfilePage;
