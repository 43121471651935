import {FoodDTO} from '../../../models/mealModel';
import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../..';
interface locationState {
  cities: string[];
  countries: string[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: locationState = {
  cities: ['Cherkassy', 'Kiev'],
  countries: ['Ukraine', 'Poland'],
  count: 0,
  loading: false,
  error: '',
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // foodFetching(state) {
    //   state.loading = true
    // },
    // foodFetchingSuccess(state, action: PayloadAction<FoodDTO[]>) {
    //   state.error = ''
    //   state.foods = action.payload
    //   state.count = action.payload.length
    //   state.loading = false
    // },
    // foodFetchingError(state, action: PayloadAction<Error>) {
    //   state.loading = false
    //   state.error = action.payload.message
    // },
  },
});
export const selectCities = (state: RootState) => state.locationReducer.cities;
export const selectCountries = (state: RootState) => state.locationReducer.countries;
export default locationSlice.reducer;
