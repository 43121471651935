import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {WeightModifierDto} from '../../../models/strict/mealStrictModel';
import {useAppDispatch} from '../../../hooks/redux';
import WeightModifiersInput from './weightModifiersInput/weightModifiersInput.component';
import PlateIcon from '../../common/icon/plateIcon/plateIcon.component';

export interface weightModifiersWrapperProperties {
  selectWeightModifier: (params: WeightModifierDto) => void;
  userId: string;
}

export default function WeightModifiersWrapper({
  selectWeightModifier,
  userId,
}: weightModifiersWrapperProperties) {
  const {t} = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '20px',
      }}
      className="col-12   my-2 relative">
      <WeightModifiersInput
        userId={userId}
        getNewWeightModifier={function (params: WeightModifierDto): void {
          selectWeightModifier(params);
        }}></WeightModifiersInput>
    </div>
  );
}
