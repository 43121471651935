/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {FoodDTO, FoodMealDTO} from '../../../models/mealModel';
import FoodInput from '../foods/foodInput/foodInput';
import {AddEditFoodMealComponent} from './addEditFoodMeal/AddEditFoodMealComponent';
import {FoodMealObject, MealObject} from '../../../models/strict/mealStrictModel';
import {FoodComponent} from '../foods/foodComponent/foodComponent';
import {FoodMealComponent} from './foodMeal/foodMealComponent';
import ModalWrapper from '../../common/modal/modalWrapper/modalWrapper.component';
import {Scrollbar} from 'react-scrollbars-custom';
import ScrollableBody from '../../common/scrollbar/scrollbar.component';
export interface FoodListProperties {
  list: FoodMealDTO[];
  addFoodHandler: (params: any) => any;
  removeFoodHandler: (params: any) => any;
}
export function FoodMealList({list, addFoodHandler, removeFoodHandler}: FoodListProperties) {
  const [foodMeal, SetFoodMeal] = useState<FoodMealDTO>(new FoodMealObject());
  function onClickHandler(object: any) {
    console.log(object);
  }
  function renderFoodList() {
    if (list == null || list.length === 0) {
      return <p className="text-center"></p>;
    }

    return list.map(food => (
      <FoodMealComponent
        key={Math.floor(Math.random() * 100000)}
        onClickHandler={onClickHandler}
        food={food}
        removeHandler={removeFoodHandler}
      />
    ));
  }

  function CloseHandler(newFoodMeal: FoodMealObject) {
    addFoodHandler(newFoodMeal);
    SetFoodMeal(new FoodMealObject());
  }
  return (
    <div className="mb-4 ">
      <AddEditFoodMealComponent
        food={foodMeal}
        saveHandler={CloseHandler}
        addMealHandler={newFoodMeal => {
          addFoodHandler(newFoodMeal);
          SetFoodMeal(new FoodMealObject());
        }}
      />
      {/* {!foodMeal && <span onClick={AddNewMeal}>add food meal</span>} */}
      {list != null && list.length != 0 && (
        <ul
          style={{
            padding: 0,
            overflowX: 'hidden',
            // minWidth: '500px',
            maxHeight: '200px',
            height: 'fit-content',
          }}>
          <ScrollableBody height="100px">{renderFoodList()}</ScrollableBody>
        </ul>
      )}
    </div>
  );
}
