import {Totals} from '../components/meals/meals/totalMealDayComponent/totalMealDay.component';
import {ProfileConstants} from '../constants/account/account.constants';
import {KcalFormulaEnum, SexEnum, UserInfoDTO} from '../models/accountModel';
import {MealDTO} from '../models/mealModel';
export function GetProteinsForUser(user: UserInfoDTO, weight: number): number {
  return 0.8 * (weight ? weight : ProfileConstants.DefaultWeight);
}

export function GetFatsForUser(ccal: number): number {
  return (ccal * 0.3) / 4;
  // return 0.3 * (weight ? weight : ProfileConstants.DefaultWeight);
}

export function GetCarbohydratesForUser(ccal: number): number {
  return (ccal * 0.4) / 4;
  // return 0.5 * (weight ? weight : ProfileConstants.DefaultWeight);
}
export function GetCaloriesForUser(user: UserInfoDTO, weight: number) {
  let age =
    new Date().getFullYear() -
    new Date(user.birthOfDate ? user.birthOfDate : ProfileConstants.DefaultBirth).getFullYear();
  if (age > 60) {
    age = 60;
  }
  if (user.kcalFormulaEnum == KcalFormulaEnum.HarrisBenedict) {
    if (user.sex == SexEnum.Value1) {
      return (
        10 * (weight ? weight : ProfileConstants.DefaultWeight) +
        6.25 * (user.height ? user.height : ProfileConstants.DefaultHeight) -
        5 * age -
        161
      );
    } else {
      return (
        10 * (weight ? weight : ProfileConstants.DefaultWeight) +
        6.25 * (user.height ? user.height : ProfileConstants.DefaultHeight) -
        5 * age +
        5
      );
    }
  } else {
    if (user.sex == SexEnum.Value1) {
      return (
        655 +
        9.6 * (weight ? weight : ProfileConstants.DefaultWeight) +
        1.8 * (user.height ? user.height : ProfileConstants.DefaultHeight) -
        4.7 * age
      );
    } else {
      return (
        66 +
        13.7 * (user.weights ? weight : ProfileConstants.DefaultWeight) +
        5 * (user.height ? user.height : ProfileConstants.DefaultHeight) -
        6.8 * age
      );
    }
  }
}
export function GetTotalCalories(meals: MealDTO[]): number {
  const calories = meals.reduce((accumulator, object) => {
    return accumulator + object.calories!;
  }, 0);
  return calories;
}
export function createTotals(user: UserInfoDTO, weight: number): Totals {
  const ccal = GetCaloriesForUser(user, weight);
  const totals: Totals = {
    proteins: GetProteinsForUser(user, weight),
    fats: GetFatsForUser(ccal),
    carbohydrates: GetCarbohydratesForUser(ccal),
    calories: ccal,
  };

  return totals;
}
