import React from 'react';
import {MealDTO} from '../../../../models/mealModel';
import {useAppDispatch} from '../../../../hooks/redux';
import {MealComponent} from '../mealComponent/mealComponent';
import Speedometer from '../../../common/speedometer/speedometer';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
import SpeedometerInfoPanel from '../../../common/speedometerInfoPanel/speedometerInfoPanel.component';
import {useTranslation} from 'react-i18next';
import CircleInfoPanel from '../../../common/circleInfoPanel/circleInfoPanel.component';
import classes from './totalMealDay.module.scss';
export interface TotalMealDayComponentProperties {
  list: MealDTO[];
  total: Totals;
}
export interface Totals {
  proteins: number;
  fats: number;
  carbohydrates: number;
  calories: number;
}
export default function TotalMealDayComponent({list, total}: TotalMealDayComponentProperties) {
  console.log(list);
  const totals = list.reduce<Totals>(
    (acc, meal: MealDTO) => {
      acc.proteins += meal.proteins || 0;
      acc.fats += meal.fats || 0;
      acc.carbohydrates += meal.carbohydrates || 0;
      acc.calories += meal.calories || 0;
      return acc;
    },
    {proteins: 0, fats: 0, carbohydrates: 0, calories: 0},
  );
  const {t} = useTranslation();
  return (
    <div
      className="mb-4 relative"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%',
      }}>
      {list && list.length > 0 && (
        <div
          style={{
            display: 'flex',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            color: 'white',
            flexDirection: 'column',
            gridGap: '.75rem 1rem',
            height: '100%',
            placeItems: 'center',
            marginTop: '80px',
          }}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              minHeight: '110px',
            }}>
            <div className={classes.leftProgress}>
              <CircleInfoPanel
                value={Math.round(totals.fats)}
                maxValue={total.fats}
                size={110}
                text={t('fats')}></CircleInfoPanel>
            </div>

            <div className={classes.centerProgress}>
              <CircleInfoPanel
                value={Math.round(totals.carbohydrates)}
                maxValue={total.carbohydrates}
                size={100}
                text={t('carb')}></CircleInfoPanel>
            </div>

            <div className={classes.rightProgress}>
              <CircleInfoPanel
                value={Math.round(totals.proteins)}
                maxValue={total.proteins}
                size={110}
                text={t('prot')}></CircleInfoPanel>
            </div>
          </div>
          <div>
            <CircleInfoPanel
              value={Math.round(totals.calories)}
              maxValue={total.calories}
              size={190}
              text={t('kcal')}></CircleInfoPanel>
          </div>
        </div>
      )}
    </div>
  );
}
