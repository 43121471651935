import React from 'react';
import classes from './exlamationMark.module.scss';
export interface exlamationMarkProperties {
  onClickHandler?: () => void;
  height?: number;
  width?: number;
  wrapperStyle?: React.CSSProperties;
  margin?: string;
}
export default function ExlamationMarkIcon({
  height = 30,
  width = 30,
  wrapperStyle,
  onClickHandler,
}: exlamationMarkProperties) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      style={wrapperStyle ?? {position: 'absolute', right: 0, width: 60}}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0C6.71621 0 0 6.71621 0 15C0 23.2847 6.71621 30 15 30C23.2847 30 30 23.2857 30 15C30 6.71621 23.2846 0 15 0Z"
        fill="#F05050"
      />
      <path
        d="M13.112 8.04512C13.112 7.13952 13.9249 6.57886 14.9992 6.57886C16.0473 6.57886 16.8863 7.16102 16.8864 8.04512V16.3513C16.8864 17.2345 16.0473 17.8167 14.9992 17.8167C13.9248 17.8167 13.112 17.2579 13.112 16.3513V8.04512Z"
        fill="white"
      />
      <path
        d="M13.127 21.561C13.127 20.528 13.967 19.688 14.9992 19.688C16.0314 19.688 16.8704 20.5279 16.8705 21.561C16.8705 22.5923 16.0314 23.4323 14.9992 23.4323C13.967 23.4323 13.127 22.5923 13.127 21.561Z"
        fill="white"
      />
    </svg>
  );
}
