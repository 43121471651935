import {MealDTO} from '../../../models/mealModel';
import {MealComponent} from './mealComponent/mealComponent';
import {useAppDispatch} from '../../../hooks/redux';
import React from 'react';
export interface MealListProperties {
  list?: MealDTO[];
  isShortDate?: boolean;
  clickDeleteHandler: (meal: MealDTO) => void;
}
export default function MealList({list, clickDeleteHandler}: MealListProperties) {
  const dispatch = useAppDispatch();
  function renderMealList() {
    if (list?.length === 0) {
      return <p className="text-center">No results!</p>;
    }

    return list?.map(meal => (
      <MealComponent key={meal.id} clickDeleteHandler={clickDeleteHandler} meal={meal} />
    ));
  }

  return (
    <div
      className="mb-4 relative"
      style={{background: 'rgb(37, 89, 80)', boxShadow: 'black 0px 0px 6px 0px inset'}}>
      {list && list.length > 0 && <div>{renderMealList()}</div>}
    </div>
  );
}
