import React from 'react';
import Tag from './tag/tag.component';
import {TrainingDescriptionDto} from '../../../models/trainingModel';

interface TagListProps {
  elements: TrainingDescriptionDto[];
  onTagClick: (label: TrainingDescriptionDto) => void;
}

const TagList: React.FC<TagListProps> = ({elements, onTagClick}) => {
  return (
    <div>
      {elements.map((element, index) => (
        <Tag key={index} label={element} onClick={onTagClick} />
      ))}
    </div>
  );
};

export default TagList;
