import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '../../../../hooks/debounce';
import axios from 'axios';
import classes from './addWeightModifiers.module.scss';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import {
  createWeightModifier,
  filterWeightModifiers,
  getWeightModifierById,
} from '../../../../actions/meals/weightModifier.actions';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalWrapper from '../../../common/modal/modalWrapper/modalWrapper.component';
import {
  MacronutrientsObject,
  WeightModifierDto,
  WeightModifierFilter,
} from '../../../../models/strict/mealStrictModel';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import AddBtn from '../../../common/buttons/addBtn.component';
import {HelperSection} from '../../../common/helperSection/helperSection.component';

export interface addWeightModifiersProperties {
  initName: string;
  getNewWeightModifier: (params: WeightModifierDto) => void;
  userId: string;
}

export default function AddWeightModifiers({
  initName,
  getNewWeightModifier,
  userId,
}: addWeightModifiersProperties) {
  // const [value, setValue] = useState('');
  const defaultWeightModifier: WeightModifierDto = {
    id: '',
    ownerUserId: '',
    name: '',
    description: '',
    weight: 0,
  };

  const [weightModifier, setWeightModifier] = useState<WeightModifierDto>(defaultWeightModifier);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useAppDispatch();
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const {t} = useTranslation();

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    const {name, value} = event.target;

    setWeightModifier(prev => ({
      ...prev,
      [name]: name === 'weight' ? parseFloat(value) : value, // Convert weight to number if necessary
    }));
  }
  useEffect(() => {
    setWeightModifier(prev => {
      if (prev != null) {
        prev.name = initName;
      }
      return prev;
    });
  }, [initName]);
  return (
    <div
      className="row relative col-12 border-primary-custom  darkModalBackground color-white custom-border   p-4"
      style={{margin: 0}}>
      <HelperSection helperValue={'weightModifier'} />

      <div className="col-5 flex my-4" style={{display: 'flex', justifyContent: 'space-around'}}>
        <label className="text-white mx-2" htmlFor="weight">
          {t('name')}
        </label>
        <input
          className={classesModal.inputModal + ' w-full  outline-0 h-[42px]'}
          type="text"
          name="name"
          onChange={changeHandler}
          value={weightModifier?.name ?? ''}
          placeholder="Name of plates"
        />
      </div>
      <div className="col-5 flex my-4">
        <label className="text-white mx-2" htmlFor="weight">
          {t('weight')}
        </label>
        <input
          className={classesModal.inputModal + ' w-full  outline-0 h-[42px]'}
          type="number"
          name="weight"
          onChange={changeHandler}
          placeholder=""
          value={weightModifier?.weight ?? ''}></input>
      </div>
      {weightModifier?.weight > 0 && weightModifier?.name && (
        <AddBtn
          onClickHandler={function (): void {
            weightModifier.ownerUserId = userId;
            dispatch(createWeightModifier(weightModifier))
              .then(f => {
                if (f) {
                  getNewWeightModifier(f);
                }
              })
              .finally(() => {
                setWeightModifier(defaultWeightModifier);
              });
          }}
        />
      )}
    </div>
  );
}
