import BaseLogo from './baseLogo';
import classes from './logos.module.css';
import React from 'react';
export interface MAvatarProperties {
  onClickHandler?: () => void;
  height?: number;
  width?: number;
  withoutText?: boolean;
  aditionalClass?: string;
}
export default function MAvatar({
  height = 60,
  width = 160,
  onClickHandler,
  withoutText = false,
  aditionalClass = '',
}: MAvatarProperties) {
  if (withoutText) {
    return (
      <BaseLogo
        height={height}
        width={width}
        className={classes.mAvatar + ' ' + aditionalClass}
        onClickHandler={onClickHandler}
      />
    );
  }
  return (
    <BaseLogo
      height={height}
      width={width}
      className={classes.mAvatar + ' ' + aditionalClass}
      onClickHandler={onClickHandler}
    />
  );
}
