import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '../../../../hooks/debounce';
import {FoodDTO} from '../../../../models/mealModel';
import axios from 'axios';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import {addFood, getFoods} from '../../../../actions/meals/food.actions';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import classesList from '../../../common/scss/list.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classes from './foodInput.module.scss';
import {faBarcode, faSearch} from '@fortawesome/free-solid-svg-icons';
import FoodListPresentation from '../foodListPresentation/foodListPresentation.component';
import BarCodeButton from '../barCodeButton/barCodeButton.component';

export interface FoodInputProperties {
  getNewFood: (params: FoodDTO) => void;
  onClickBarScannerHandler?: () => void;
  restaurantId?: string;
  userId?: string;
  minWidth?: number | string;
  maxWidth?: number | string;
}

export default function FoodInput({
  getNewFood,
  restaurantId,
  userId,
  minWidth = 'auto',
  maxWidth = 400,
  onClickBarScannerHandler,
}: FoodInputProperties) {
  const [value, setValue] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const debounced = useDebounce<string>(value, 500);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const {foods} = useAppSelector(state => state.foodReducer);

  async function searchFoods(search: string) {
    if (abortController) {
      abortController.abort();
    }
    const controller = new AbortController();
    setAbortController(controller);
    dispatch(getFoods(controller.signal, search, restaurantId));
  }

  const {t} = useTranslation();

  useEffect(() => {
    if (debounced.length >= 3) {
      searchFoods(debounced).then(() => setDropdown(true));
    } else {
      setDropdown(false);
    }
  }, [debounced]);

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function renderDropdown() {
    if (foods.length === 0 || foods == undefined) {
      return <p className="text-center"> </p>;
    }

    return (
      <FoodListPresentation
        foods={foods}
        onClickHandler={selectedFood => {
          getNewFood(selectedFood);
          setValue('');
        }}
      />
    );
  }

  return (
    <div className="mb-4 my-2 relative">
      {/* <label className="text-white" htmlFor="weight">
        {t('Food')}{' '}
      </label> */}

      <div
        className="row w-full flex my-4"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          padding: 0,
        }}>
        <div
          className={' col-12  w-full outline-0 h-[42px]'}
          style={{alignItems: 'center', position: 'relative'}}>
          <FontAwesomeIcon className={classes.searchIcon} icon={faSearch}></FontAwesomeIcon>
          <input
            className={classesModal.inputModal + ' col-12  w-full outline-0 h-[42px]'}
            type="text"
            onChange={changeHandler}
            value={value}
            style={{paddingLeft: '35px'}}
            placeholder={t('SearchForFood')}
          />
          {value && value.length > 2 && (
            <button
              onClick={() => {
                searchFoods('');
                setValue('');
              }}
              className={classes.cancelBtn}>
              {t('Cancel')}
            </button>
          )}
        </div>
        {onClickBarScannerHandler && (
          <BarCodeButton
            onClickBarScannerHandler={onClickBarScannerHandler}
            debounced={debounced}
          />
        )}
        {dropdown && <ul className={classesList.listWrapper}>{renderDropdown()}</ul>}
      </div>
    </div>
  );
}
