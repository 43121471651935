import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {faClockRotateLeft} from '@fortawesome/free-solid-svg-icons';
import {sharedIconProperties} from '../sharedProperties.component';
import classes from './addHistoryIcon.module.scss';
export default function AddHistoryIcon({onClickHandler, color}: sharedIconProperties) {
  return (
    <span className={classes.wrapperSymbol} onClick={onClickHandler}>
      <FontAwesomeIcon icon={faClockRotateLeft} />
      <div className={classes.plusSymbol}>+</div>
    </span>
  );
}
