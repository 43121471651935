import React, {ChangeEvent, useEffect, useState, useTransition} from 'react';
import {CreateMealDTO, FoodDTO, MealDTO} from '../../../models/mealModel';
import MealList from './mealList';
import {
  CreateMealObject,
  CreateUpdateFoodMealObject,
  FoodMealObject,
  MacronutrientsObject,
  MealObject,
} from '../../../models/strict/mealStrictModel';
import AddEditMealComponent from './addEditMealComponent/addEditMealComponent';
import {addMeals, deleteMeal, getMeals} from '../../../actions/meals/meal.action';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../../store/account/slices/login.slice';
import {UserInfoDTO} from '../../../models/accountModel';
import {GetCaloriesForUser} from '../../../utils/mealUtil';
import RestaurantWrapper from '../restaurants/restaurantWrapper.component';
import ModalWrapper from '../../common/modal/modalWrapper/modalWrapper.component';
import {DateTime} from 'luxon';
import {largeCommonDateFormat} from '../../../constants/common/dateFormat';
import {useTranslation} from 'react-i18next';
import AddHistoryIcon from '../../common/icon/addHistoryIcon/addHistoryIcon.component';
import {getLastDateForUser} from '../../../utils/accountUtil';
import {ProfileConstants} from '../../../constants/account/account.constants';
export interface MealWrapperProperties {
  user: UserInfoDTO;
  isForToday?: boolean;
  weight?: number;
  list?: MealDTO[];
  isShortDate?: boolean;
  choosenDate?: DateTime;
  refreshHandle: () => void;
}
export default function MealWrapper({
  user,
  isForToday,
  weight,
  list,
  isShortDate,
  choosenDate,
  refreshHandle,
}: MealWrapperProperties) {
  const [NewMeal, setNewMeal] = useState<MealDTO>();
  const {t} = useTranslation();
  const userId = useSelector(selectCurrentUserId);
  const [totalCalories, setTotalCallories] = useState<number>();
  const [eatedCalories, setEatedCallories] = useState<number>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const lastDate = getLastDateForUser(user);
    let userWeight = ProfileConstants.DefaultWeight;
    if (lastDate != null) {
      userWeight = user?.weights![lastDate];
    }
    setTotalCallories(Math.round(GetCaloriesForUser(user, userWeight)));
  }, [weight]);
  useEffect(() => {
    if (list) {
      {
        setEatedCallories(
          Math.round(
            list
              .filter(
                f =>
                  f.eatTime != null &&
                  DateTime.fromISO(f.eatTime).startOf('day') == DateTime.now().startOf('day'),
              )
              .reduce((accumulator, object) => {
                return accumulator + object.calories!;
              }, 0),
          ),
        );
      }
    }
  }, [list]);
  function AddNewMeal() {
    setNewMeal(new MealObject());
  }
  function SaveAddNewMeal(NewFoodMeal: MealDTO) {
    const requestDTO = new CreateMealObject(
      undefined,
      undefined,
      NewFoodMeal.eatTime,
      NewFoodMeal.foods?.map(
        f =>
          new CreateUpdateFoodMealObject(
            undefined,
            undefined,
            f.food?.id ? f.food.id : undefined,
            f.weight,
          ),
      ),
    );
    requestDTO.eatTime = DateTime.fromFormat(requestDTO.eatTime!, largeCommonDateFormat).toISO()!;
    dispatch(addMeals(requestDTO));
    setNewMeal(undefined);
    refreshHandle();
  }
  function DeleteMeal(meal: MealDTO) {
    if (meal != null) {
      dispatch(deleteMeal(meal.id!)).then(() => {
        refreshHandle();
      });
    }
  }
  function CloseAddingMeal() {
    setNewMeal(undefined);
  }
  return (
    <div className="mb-4 " style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <span style={{color: 'white'}}>{t('Meal')}</span>
        <div style={{display: 'flex'}}>
          <AddHistoryIcon color="white" onClickHandler={AddNewMeal} />
        </div>
      </div>
      {NewMeal && (
        <ModalWrapper handleClose={CloseAddingMeal} isOpen={NewMeal != null}>
          <AddEditMealComponent
            meal={NewMeal}
            choosenDate={choosenDate}
            saveHandler={SaveAddNewMeal}></AddEditMealComponent>
        </ModalWrapper>
      )}

      {list && <MealList list={list} isShortDate={isShortDate} clickDeleteHandler={DeleteMeal} />}
      {isForToday && (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <span style={{color: 'white'}}>
            {t('Total')}: {totalCalories}
          </span>
          <span style={{color: 'white'}}>
            {' '}
            {t('Ate')}: {eatedCalories}
          </span>
        </div>
      )}
    </div>
  );
}
