import {DateTime} from 'luxon';
import React, {ChangeEvent, useEffect, useState} from 'react';

import AddEditSessionComponent from '../sessions/createSession/addEditSession.component';
import {SessionDto, TrainingPlanDto} from '../../../models/trainingModel';
import {addTrainingPlans, updateTrainingPlan} from '../../../actions/trainings/trainingPlan.action';
import {useAppDispatch} from '../../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {SessionList} from '../sessions/sessions/sessionsList.component';
import {TrainingPlanObject} from '../../../models/strict/sessionStrictModel';
import {selectCurrentUserId} from '../../../store/account/slices/login.slice';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import classesModal from '../../common/modal/styles/modalShared.module.scss';

export interface AddEditTrainingPlanComponentProperties {
  modificatedTrainingPlan: TrainingPlanDto;
  closeHandler: () => void;
}
export default function AddEditTrainingPlanComponent({
  modificatedTrainingPlan,
  closeHandler,
}: AddEditTrainingPlanComponentProperties) {
  const [TrainingPlan, setTrainingPlan] = useState<TrainingPlanDto>(modificatedTrainingPlan);
  const dispatch = useAppDispatch();
  const currentUserId = useSelector(selectCurrentUserId);
  useEffect(() => {
    //modificatedTrainingPlan.session!.userId = currentUserId
    modificatedTrainingPlan.userId = currentUserId;
    setTrainingPlan(modificatedTrainingPlan);
  }, [modificatedTrainingPlan]);
  function addSessions(session: SessionDto) {
    setTrainingPlan(prev => ({...prev, session: session}));
  }
  function saveHandler() {
    if (TrainingPlan.id == null) {
      dispatch(addTrainingPlans(TrainingPlan));
    } else {
      dispatch(updateTrainingPlan(TrainingPlan));
    }
    closeHandler();
  }
  const changeHandlerTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTrainingPlan(prev => ({...prev, [event.target.name]: event.target.value}));
  };
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setTrainingPlan(prev => ({...prev, [event.target.name]: event.target.value}));
  };
  const {t} = useTranslation();
  return (
    <div className="greyMenuBackground px-4">
      <div className=" ">
        <div className=" ">
          <div className="row   justify-content-center  ">
            <div className=" col-12">
              <label htmlFor="name" style={{color: 'white'}} className=" my-2 col-12">
                {t('Name')}
              </label>
              <input
                type="textarea"
                value={TrainingPlan.name ?? ''}
                id="name"
                className={classesModal.inputModal + ' w-full'}
                name="name"
                placeholder={t('Input name of your training plan...')}
                onChange={changeHandler}
              />
            </div>
            <div className="col-12  mb-4">
              <label htmlFor="description" style={{color: 'white'}} className="mr-2 my-2 col-12">
                {t('Description')}
              </label>
              <textarea
                value={TrainingPlan.description ?? ''}
                id="description"
                className={classesModal.inputModal + ' w-full'}
                name="description"
                placeholder={t('Describe your training plan...')}
                onChange={changeHandlerTextArea}
              />
            </div>
          </div>
        </div>
      </div>
      {TrainingPlan.session && (
        <AddEditSessionComponent
          onSetChangeHandler={updatedSet => {
            setTrainingPlan((prev: TrainingPlanDto) => {
              const updatedSession = {...prev.session};
              if (updatedSet.id == null) {
                updatedSession.sets?.push(updatedSet);
              } else {
                if (updatedSession.sets && updatedSession.sets.length && updatedSet) {
                  const index = updatedSession.sets.findIndex(f => f.id == updatedSet.id);
                  if (index != null) {
                    updatedSession.sets[index] = updatedSet;
                  }
                }
              }
              return {...prev, session: updatedSession};
            });
          }}
          modificatedSession={TrainingPlan.session}
          saveHandler={addSessions}></AddEditSessionComponent>
      )}

      <p
        className={classesModal.saveBtn}
        onClick={() => {
          saveHandler();
        }}>
        +
      </p>
      {/* {modificatedTrainingPlan.id!=null &&
          <div className='col-6'>
            
          
        Create Training Plan from session
        <SessionList  /> 
          </div>
           }  */}
    </div>
  );
}
