import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {login, register} from '../../actions/account/login.actions';
import {useNavigate} from 'react-router-dom';
import {CreateUserCommand, LoginUserCommand, SexEnum} from '../../models/accountModel';
import MainLogo from '../../components/common/logos/mainLogo';
import AuthWrapperLogo from './components/authSocialWrapper.component';
import {AuthButton} from './components/authButton/authButton.component';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {
  ValidationConfig,
  dateOfBirthValidationConfig,
  emailValidationConfig,
  emptyControlTextValidationConfig,
  heightValidationConfig,
  passwordValidationConfig,
  simpleTextValidationConfig,
  weightValidationConfig,
} from '../../components/common/configurations/validationConfigurations';
import {AuthTextInput} from './components/authInputs/authInputs.component';
import {AuthSelect} from './components/authSelect.component';
import {keyValuePair} from '../../models/common/keyValuePair';
import AuthPageLayout from '../../layouts/auth.layout';
import {getRegistrationState} from '../../actions/account/group.actions';

export function RegistrationPage() {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
    setValue,
    getFieldState,
  } = useForm<CreateUserCommand>({
    mode: 'onBlur',
  });
  const emailConfig: ValidationConfig = emailValidationConfig(control);
  const passwordConfig: ValidationConfig = passwordValidationConfig(control);
  const heightConfig: ValidationConfig = heightValidationConfig(control);
  const weightConfig: ValidationConfig = weightValidationConfig(control);
  const firstNameConfig: ValidationConfig = simpleTextValidationConfig(control, 'firstName');
  const lastNameConfig: ValidationConfig = simpleTextValidationConfig(control, 'lastName');
  const dateOfBirthConfig: ValidationConfig = dateOfBirthValidationConfig(control);
  const sexConfig: ValidationConfig = emptyControlTextValidationConfig(control, 'sex');
  const {t} = useTranslation();
  const [form, setForm] = useState<CreateUserCommand>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    birthOfDate: '',
    height: 0,
    weight: 0,
    sex: SexEnum.Value0,
  });
  const sexData: keyValuePair[] = [
    {key: 0, value: t('SEX_NONE')},
    {key: 1, value: t('SEX_MALE')},
    {key: 2, value: t('SEX_FEMALE')},
  ];

  const isFormValid = () => {
    // return form.password.trim().length && form.username.trim().length
    return true;
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid()) {
      await dispatch(register(form));
      // navigate('/')
    } else {
      alert('Form is invalid!');
    }
  };

  const changeHandler = (name: string, value: string | number) => {
    setForm(prev => ({...prev, [name]: value}));
  };

  const registerHandler = async () => {
    await dispatch(register(form));
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');
    const emailParam = queryParams.get('email');
    if (codeParam != null && emailParam != null) {
      changeHandler('email', emailParam);
      changeHandler('code', codeParam);
      dispatch(getRegistrationState(emailParam, codeParam)).then(f => {
        if (f?.firstName != null) {
          changeHandler('firstName', f?.firstName);
        }
        if (f?.lastName != null) {
          changeHandler('lastName', f.lastName);
        }
        if (f?.sex != null) {
          changeHandler('sex', f.sex);
        }
      });
    }
  }, []);
  return (
    <AuthPageLayout>
      <div className="row col-12" style={{padding: 0}}>
        <form onSubmit={handleSubmit(registerHandler)}>
          <div className="row" style={{padding: 0, margin: '0px'}}>
            <AuthTextInput
              wrapperClassName="col-6"
              props={firstNameConfig}
              labelValue="First Name"
              inputValue={form.firstName ?? ''}
              placeholder={t('FirstName')}
              className="col-12"
              onUpdate={value => {
                changeHandler('firstName', value);
              }}></AuthTextInput>
            <AuthTextInput
              wrapperClassName="col-6"
              props={lastNameConfig}
              inputValue={form.lastName ?? ''}
              labelValue="Last Name"
              placeholder={t('LastName')}
              className="col-12"
              onUpdate={value => {
                changeHandler('lastName', value);
              }}></AuthTextInput>
            <AuthTextInput
              wrapperClassName="col-6"
              props={emailConfig}
              labelValue="Email"
              placeholder={t('Email')}
              inputValue={form.email ?? ''}
              className="col-12"
              onUpdate={value => {
                changeHandler('email', value);
              }}></AuthTextInput>
            <AuthTextInput
              wrapperClassName="col-6"
              props={passwordConfig}
              labelValue="Password"
              placeholder={t('Password')}
              className="col-12"
              onUpdate={value => {
                changeHandler('password', value);
              }}
              type="password"></AuthTextInput>
            <AuthTextInput
              wrapperClassName="col-6"
              props={heightConfig}
              labelValue="Height"
              placeholder={t('Height')}
              className="col-12"
              onUpdate={value => {
                changeHandler('height', value);
              }}
              type="number"></AuthTextInput>
            <AuthTextInput
              wrapperClassName="col-6"
              props={weightConfig}
              labelValue="Weight"
              placeholder={t('Weight')}
              className="col-12"
              onUpdate={value => {
                changeHandler('weight', value);
              }}
              type="number"></AuthTextInput>
            <AuthTextInput
              wrapperClassName="col-6"
              props={dateOfBirthConfig}
              labelValue="birthOfDate"
              placeholder={t('birthOfDate')}
              className="col-12"
              onUpdate={value => {
                changeHandler('birthOfDate', value);
              }}
              type="date"></AuthTextInput>
            <AuthSelect
              wrapperClassName="col-6"
              // inputValue={form.sex ?? SexEnum.Value0}
              props={sexConfig}
              className="col-12"
              data={sexData}
              onUpdate={data => {
                console.log(data);
              }}
            />
          </div>
          <AuthButton labelValue={t('signup')} isSubmit={true} />
        </form>
        <div className="row col-12" style={{marginTop: 30, marginBottom: 80}}>
          <div className="col-12 text-center">
            <span>
              <a href="signin"> {t('have_acount_redirect_login')} </a>
            </span>
          </div>
        </div>
      </div>
    </AuthPageLayout>
  );
}
