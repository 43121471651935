export class TranslationDto {
  id: string;
  translationDatas: TranslationDataDto[];

  constructor(id: string, translationDatas: TranslationDataDto[]) {
    this.id = id;
    this.translationDatas = translationDatas;
  }
}
export class TranslationDataDto {
  id?: string;
  translationId: string;
  locale: string;
  value: string;

  constructor(id: string | undefined, translationId: string, locale: string, value: string) {
    this.id = id;
    this.translationId = translationId;
    this.locale = locale;
    this.value = value;
  }
}
