import React, {InputHTMLAttributes, useEffect, useRef, useState} from 'react';
import {
  CreateSessionDto,
  SessionDto,
  TrainingDto,
  TrainingDescriptionDto,
  TrainingPlanDto,
} from '../../../../models/trainingModel';

import {Session} from 'inspector';
import ChillTimeComponent from './chillTime/chillTime.component';
import TrainingTimeComponent from './trainingTime/trainingTime.component';
import {
  SessionObject,
  SetObject,
  TrainingDescriptionObject,
} from '../../../../models/strict/sessionStrictModel';
import {DateTime, Duration, Zone} from 'luxon';
import {addSessions} from '../../../../actions/trainings/session.action';
import {useAppDispatch} from '../../../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../../../store/account/slices/login.slice';
import {TrainingMode} from '../../../../constants/training/enum/trainingMode';
import SummaryComponent from './summary/summary.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faForward} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '@mui/material';
import {TrainingKeys} from '../../../../constants/training/trainingKeys';
import {StateOfTraining} from '../../../../models/common/stateOfTraining';
import TrainingTimeInformationComponent from './trainingTimeInformation/trainingTimeInformation.component';
import {
  longCommonTimeFormat,
  longCommonTimeFormat12,
} from '../../../../constants/common/dateFormat';
import CurrentSetTrainingComponent from '../setTraining/currentSetTrainingcomponent';
import classes from './manuallyTraining.module.scss';
import classesColor from '../../../shared/sharedColorConfigurations.module.scss';
export interface ManuallyTrainingComponentProperties {
  trainingPlan: TrainingPlanDto | undefined | null;
  sessionLast?: StateOfTraining;
}
export default function ManuallyTrainingComponent({
  trainingPlan,
  sessionLast,
}: ManuallyTrainingComponentProperties) {
  const [selectedTrainingPlan, SetSelectedTrainingPlan] = useState<
    TrainingPlanDto | undefined | null
  >(trainingPlan);
  const [trainingMode, setTrainingMode] = useState<TrainingMode>(TrainingMode.NONE);
  const [session, setSession] = useState<CreateSessionDto | undefined>();

  const [IsAutoMove, setIsAutoMove] = useState<boolean>(false);
  const [nextTraining, setNextTraining] = useState<TrainingDto>();
  const [currentTraining, setCurrentTraining] = useState<TrainingDto | undefined>();
  const [prevTraining, setPrevTraining] = useState<TrainingDto | undefined>();
  const [startDate, setStartDate] = useState<DateTime | undefined>();
  const [duration, setDuration] = useState<string>();
  const usedTrainingDescription = useRef<TrainingDescriptionDto[]>([]);
  const lastUpdatedDate = useRef<DateTime>(DateTime.now());
  const userId = useSelector(selectCurrentUserId);
  const setI = useRef(0);
  const trainingI = useRef(0);

  // const [session, setSession] = useState<SessionDto>()
  // useEffect(() => {
  //   console.log(session)
  // }, [session])
  const dispatch = useAppDispatch();
  const setNextTrainingWrapper = (nextSession: SessionDto) => {
    if (nextSession && nextSession.sets && nextSession.sets.length > setI.current) {
      const currentSet = nextSession.sets[setI.current];
      const indexNextTraining = trainingI.current + 1;
      if (currentSet.trainings && currentSet.trainings.length > indexNextTraining) {
        setNextTraining(currentSet.trainings[indexNextTraining]);
        return;
      }
      const indexOfNextSet = setI.current + 1;
      if (indexOfNextSet < nextSession.sets.length) {
        const nextSet = nextSession.sets[indexOfNextSet];
        if (nextSet.trainings && nextSet.trainings.length) {
          trainingI.current = 0;
          setNextTraining(nextSet.trainings[0]);
          return;
        }
      }
    }
  };
  const retrieveSession = () => {
    if (sessionLast != null && sessionLast != undefined) {
      SetSelectedTrainingPlan(sessionLast.selectedTrainingPlan);
      setTrainingMode(sessionLast.trainingMode);
      setSession(sessionLast.session);
      setIsAutoMove(sessionLast.IsAutoMove);
      setNextTraining(sessionLast.nextTraining);
      usedTrainingDescription.current = sessionLast.usedTrainingDescription;
      lastUpdatedDate.current = sessionLast.lastUpdatedDate;
      setI.current = sessionLast.setI;
      trainingI.current = sessionLast.trainingI;
      setCurrentTraining(sessionLast.currentTraining);
    }
  };
  const saveCurrentSession = () => {
    const sessionObject = new StateOfTraining(
      selectedTrainingPlan,
      trainingMode,
      session,
      IsAutoMove,
      nextTraining,
      currentTraining,
      usedTrainingDescription.current,
      lastUpdatedDate.current,
      userId,
      setI.current,
      trainingI.current,
    );
    localStorage.setItem(TrainingKeys.sessionKey, JSON.stringify(sessionObject));
  };
  const addTraining = function (training: TrainingDto) {
    training.chillTime;
    let durationTraining = DateTime.now().diff(lastUpdatedDate.current);
    if (training.chillTime) {
      const chillTime = DateTime.fromFormat(training.chillTime, longCommonTimeFormat12);
      durationTraining = durationTraining.minus(
        Duration.fromObject({
          hour: chillTime.hour,
          minutes: chillTime.minute,
          second: chillTime.second,
        }),
      );
    }
    training.duration = durationTraining.toFormat(longCommonTimeFormat12);
    lastUpdatedDate.current = DateTime.now();
    session?.sets[0]?.trainings?.push(training);
    saveCurrentSession();
  };
  useEffect(() => {
    if (sessionLast != null && sessionLast != undefined) {
      retrieveSession();
    } else if (session == null) {
      const sessionObject = new SessionObject();

      sessionObject?.sets.push(new SetObject());
      if (trainingPlan != null) {
        setCurrentTraining(trainingPlan?.session?.sets?.[0]?.trainings?.[0]);
        if (trainingPlan.session != null) {
          setNextTrainingWrapper(trainingPlan?.session);
        }
      }
      setSession(sessionObject);
    }
  }, []);
  useEffect(() => {
    retrieveSession();
  }, [sessionLast]);

  const finishSession = () => {
    setTrainingMode(TrainingMode.FINISH);
    if (session != null) {
      session.userId = userId;
      dispatch(addSessions(session));
      localStorage.removeItem(TrainingKeys.sessionKey);
    }
  };
  const setTraining = (training: TrainingDto) => {
    const existingTraining = usedTrainingDescription.current.find(
      f => f.id == training.trainingDescriptionId,
    );

    if (existingTraining == null) {
      const trainingDescription = new TrainingDescriptionObject();
      trainingDescription.id = training.trainingDescriptionId;
      trainingDescription.name = training.customTrainingName;
      trainingDescription.category = training.trainingDescription?.category;
      trainingDescription.typeOfTrainingDescription =
        training.trainingDescription?.typeOfTrainingDescription;
      usedTrainingDescription.current.push(trainingDescription);
    }
    training.trainingDescription = undefined;
    training.id = undefined;
    addTraining(training);
    setPrevTraining(currentTraining);
    if (trainingPlan != null) {
      const sessionTrainingPlan = trainingPlan.session;
      if (
        sessionTrainingPlan &&
        sessionTrainingPlan.sets &&
        sessionTrainingPlan.sets.length > setI.current
      ) {
        const currentSet = sessionTrainingPlan.sets[setI.current];
        if (currentSet.trainings && currentSet.trainings.length > ++trainingI.current) {
          setCurrentTraining(currentSet.trainings[trainingI.current]);
          setNextTrainingWrapper(sessionTrainingPlan);
          return;
        }
        if (++setI.current < sessionTrainingPlan.sets.length) {
          const nextSet = sessionTrainingPlan.sets[setI.current];
          if (nextSet.trainings && nextSet.trainings.length) {
            trainingI.current = 0;
            setCurrentTraining(nextSet.trainings[0]);
            setNextTrainingWrapper(sessionTrainingPlan);
            return;
          }
        }
      }
      finishSession();
    }
  };
  const setDurationWrapper = () => {
    let acc = 0;
    session?.sets?.forEach(item => {
      item.trainings.forEach(cTraining => {
        if (cTraining.duration) {
          acc += DateTime.fromFormat(cTraining.duration, longCommonTimeFormat).second;
        }
        if (cTraining.chillTime) {
          acc += DateTime.fromFormat(cTraining.chillTime, longCommonTimeFormat).second;
        }
        return currentTraining;
      });
      return item;
    });
    setDuration(DateTime.fromSeconds(acc + 1, {zone: 'utc'}).toFormat(longCommonTimeFormat));
  };

  const {t} = useTranslation();
  const renderComponent = () => {
    switch (trainingMode) {
      case TrainingMode.NONE:
        return (
          <div className="flex  height-full justify-content-center align-items-center ">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <div>
                <h2 className={classesColor.sidebarColor}>{t('ConfirmBeginTraining')}</h2>
                <div className={classes.startButtonWrapper}>
                  <div
                    className="flex  border-custom border-primary-alt circle justify-content-center align-items-center background-primary cursor-pointer"
                    style={{
                      boxShadow: 'inset 1px 1px 5px 1px black',
                      padding: '20px',
                      width: '80px',
                      borderWidth: '10px',
                    }}
                    onClick={() => {
                      setStartDate(DateTime.now);
                      setTrainingMode(TrainingMode.TRAINING);
                    }}>
                    <FontAwesomeIcon icon={faForward} />
                  </div>
                </div>
                {trainingPlan != null && (
                  <div className="flex-column justify-content-center align-items-center flex mt-3">
                    <label className={classesColor.sidebarColor + ' my-2'}>
                      {' '}
                      {t('AutoCompleteTraining')}
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={IsAutoMove}
                      onChange={() => {
                        setIsAutoMove(!IsAutoMove);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      case TrainingMode.CHILL_TIME:
        return (
          <div>
            <ChillTimeComponent
              previousTrainingDescriptions={usedTrainingDescription.current}
              isAutoMove={IsAutoMove}
              onClickHandler={training => {
                setDurationWrapper();
                setTrainingMode(TrainingMode.TRAINING);
                setTraining(training);
              }}
              prevTraining={prevTraining}
              nextTraining={nextTraining}
              currentTraining={currentTraining}
              finishHandler={training => {
                if (training?.trainingDescriptionId != null) {
                  addTraining(training);
                }
                finishSession();
              }}
            />
            <CurrentSetTrainingComponent
              currentTrainingId={currentTraining?.id}
              currentSet={session?.sets[setI.current]}
            />
          </div>
        );

      case TrainingMode.TRAINING:
        return (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div>
              <TrainingTimeComponent
                isAutoMove={IsAutoMove}
                prevTraining={prevTraining}
                currentTraining={currentTraining}
                nextTraining={nextTraining}
                onClickHandler={() => {
                  setDurationWrapper();
                  setTrainingMode(TrainingMode.CHILL_TIME);
                }}
              />
              <CurrentSetTrainingComponent
                currentTrainingId={currentTraining?.id}
                currentSet={session?.sets[setI.current]}
              />
            </div>
          </div>
        );

      case TrainingMode.FINISH:
        return <SummaryComponent session={session}></SummaryComponent>;
      default:
        return null;
    }
  };

  return (
    <div>
      <TrainingTimeInformationComponent startDate={startDate} duration={duration} />
      {renderComponent()}
    </div>
  );
}
