import React, {ChangeEvent, useEffect, useState} from 'react';
import {FoodMealList} from '../../foodMeals/foodMealsList';
import {MealDTO} from '../../../../models/mealModel';
import {DateTime} from 'luxon';
import classes from './mealComponent.module.scss';
import {
  largeCommonDateFormat,
  shortCommonTimeFormat,
} from '../../../../constants/common/dateFormat';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
export interface MealComponentProperties {
  meal?: MealDTO;
  isShortDate?: boolean;
  clickDeleteHandler: (meal: MealDTO) => void;
}
export function MealComponent({meal, isShortDate, clickDeleteHandler}: MealComponentProperties) {
  const [IsCollapsed, SetCollapse] = useState<boolean>(false);
  if (meal != null) {
    return (
      <div
        key={meal.id}
        // onClick={() => navigate(`/airport/${airport.id}`)}
        className={' ' + classes.mealComponent}
        onClick={() => {
          SetCollapse(!IsCollapsed);
        }}>
        {meal.eatTime &&
          DateTime.fromISO(meal.eatTime).toFormat(
            isShortDate ? shortCommonTimeFormat : largeCommonDateFormat,
          )}
        <span className="color-white" style={{margin: 10}}>
          {meal.calories?.toFixed(2).toString()}
        </span>
        <span className="color-white" style={{margin: 10}}>
          {meal.carbohydrates?.toFixed(2)}
        </span>
        <span className="color-white" style={{margin: 10}}>
          {meal.fats?.toFixed(2)}
        </span>
        <span className="color-white" style={{margin: 10}}>
          {meal.proteins?.toFixed(2)}
        </span>
        {meal.foods && IsCollapsed && (
          <FoodMealList
            list={meal.foods}
            addFoodHandler={() => {
              console.log('');
            }}
            removeFoodHandler={() => {
              console.log('clicked');
            }}
          />
        )}
        <span
          className="color-white"
          onClick={e => {
            e.stopPropagation();
            clickDeleteHandler(meal);
          }}>
          <FontAwesomeIcon icon={faXmark} />
        </span>
      </div>
    );
  } else {
    return <div></div>;
  }
}
