import React, {ChangeEvent, useEffect, useState} from 'react';
import {UserInfoDTO} from '../../../models/accountModel';
import {
  CreateRestaurantPortionDTO,
  RestaurantDTO,
  RestaurantPortionDTO,
} from '../../../models/mealModel';
import {FoodList} from '../foods/foodList';
import {AddEditFoodComponent} from '../foods/foodAddUpdateComponent/AddEditFoodComponent';
import {addFood, getFoods} from '../../../actions/meals/food.actions';
import {
  FoodDTOObject,
  MacronutrientsObject,
  RestaurantPortionObject,
} from '../../../models/strict/mealStrictModel';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {
  addRestaurantPortion,
  getRestaurantPortion,
  getRestaurantPortions,
} from '../../../actions/meals/restaurantPortion.actions';
import {RestaurantPortionComponent} from '../restaurantMeals/RestaurantPortionComponent/restaurantPortions.component';
import {RestaurantPortionListComponent} from '../restaurantMeals/restaurantPortionsList.component';
export interface RestaurantComponentProperties {
  restaurant: RestaurantDTO;
}
export function RestaurantComponent({restaurant}: RestaurantComponentProperties) {
  const [isAddFood, setAddFood] = useState<boolean>(false);
  const [IsCollapsed, SetCollapse] = useState<boolean>(false);
  const [restaurauntItem, SetRestaurantItem] = useState<RestaurantDTO>(restaurant);
  const [restaurantPortion, setRestaurantPortion] = useState<CreateRestaurantPortionDTO>(
    new RestaurantPortionObject(),
  );

  const dispatch = useAppDispatch();
  const getFoodsForRestaurant = () => {
    dispatch(getRestaurantPortions(restaurant.id!)).then(f => {
      console.log(f);
      console.log(restaurauntItem);
      SetRestaurantItem(previous => ({
        ...previous,
        foods: f,
      }));
    });
  };
  function SetWeightForPortion(event: ChangeEvent<HTMLInputElement>) {
    setRestaurantPortion(previous => ({
      ...previous,
      weight: Number.parseInt(event.target.value),
    }));
  }
  function AddPortion() {
    restaurantPortion.restaurantId = restaurant.id;
    dispatch(addRestaurantPortion(restaurantPortion)).then(() => {
      getFoodsForRestaurant();
    });
  }
  if (restaurant != null) {
    return (
      <span
        key={restaurant.id}
        className="cursor-pointer hover:bg-gray-500 hover:text-white py-2 px-4"
        onClick={event => {
          event.stopPropagation();
          getFoodsForRestaurant();
          SetCollapse(!IsCollapsed);
        }}>
        {' '}
        <span>{restaurauntItem.name}</span>
        <span>{restaurauntItem.description}</span>
        {IsCollapsed && (
          <div
            onClick={event => {
              event.stopPropagation();
            }}>
            <button
              onClick={() => {
                setAddFood(true);
              }}>
              {' '}
              Add food
            </button>
            {isAddFood && (
              <div>
                <AddEditFoodComponent
                  saveHandler={food => {
                    setRestaurantPortion(previous => ({
                      ...previous,
                      food: food,
                    }));
                  }}
                  food={new FoodDTOObject(undefined, undefined, new MacronutrientsObject())}
                />

                <input
                  type="number"
                  id="weight"
                  className="border"
                  name="weight"
                  onChange={SetWeightForPortion}
                />
                <button
                  onClick={() => {
                    AddPortion();
                  }}></button>
              </div>
            )}

            {restaurauntItem.foods && (
              <RestaurantPortionListComponent list={restaurauntItem.foods} />
            )}
          </div>
        )}
      </span>
    );
  } else {
    return <div></div>;
  }
}
