import {createSlice} from '@reduxjs/toolkit';
import {TrainingDescriptionDto} from '../../../models/trainingModel';
import type {PayloadAction} from '@reduxjs/toolkit';
interface trainingDescriptionState {
  trainingDescriptions: TrainingDescriptionDto[];
  trainingDescriptionsContainer: TrainingDescriptionDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: trainingDescriptionState = {
  trainingDescriptions: [],
  trainingDescriptionsContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const trainingDescriptionSlice = createSlice({
  name: 'trainingDescription',
  initialState,
  reducers: {
    trainingDescriptionFetching(state) {
      state.loading = true;
    },
    trainingDescriptionFetchingSuccess(state, action: PayloadAction<TrainingDescriptionDto>) {
      state.error = '';
      state.loading = false;
    },
    trainingDescriptionFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    trainingDescriptionsFetchingSuccess(state, action: PayloadAction<TrainingDescriptionDto[]>) {
      state.error = '';
      state.trainingDescriptions = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    trainingDescriptionsFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default trainingDescriptionSlice.reducer;
