import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {ScheduleDto} from '../../../models/trainingModel';
import {ScheduleObject} from '../../../models/strict/sessionStrictModel';
interface ScheduleState {
  Schedule: ScheduleDto;
  Schedules: ScheduleDto[];
  SchedulesContainer: ScheduleDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: ScheduleState = {
  Schedule: new ScheduleObject(),
  Schedules: [],
  SchedulesContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const scheduleSlice = createSlice({
  name: 'Schedule',
  initialState,
  reducers: {
    ScheduleFetching(state) {
      state.loading = true;
    },
    ScheduleFetchingSuccess(state, action: PayloadAction<ScheduleDto>) {
      state.error = '';
      state.Schedule = action.payload;
      state.loading = false;
    },
    ScheduleFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    SchedulesFetchingSuccess(state, action: PayloadAction<ScheduleDto[]>) {
      state.error = '';
      state.Schedules = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    SchedulesFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default scheduleSlice.reducer;
