import {DateTime} from 'luxon';
import {TrainingMode} from '../../constants/training/enum/trainingMode';
import {
  CreateSessionDto,
  TrainingDto,
  TrainingDescriptionDto,
  TrainingPlanDto,
} from '../trainingModel';

export class StateOfTraining {
  constructor(
    public selectedTrainingPlan: TrainingPlanDto | undefined | null,
    public trainingMode: TrainingMode,
    public session: CreateSessionDto | undefined,

    public IsAutoMove: boolean,
    public nextTraining: TrainingDto | undefined,
    public currentTraining: TrainingDto | undefined,
    public usedTrainingDescription: TrainingDescriptionDto[],
    public lastUpdatedDate: DateTime,
    public userId: string,
    public setI: number,
    public trainingI: number,
  ) {}
}
