import React from 'react';
import {DateTime} from 'luxon';
import {DatePicker} from '@mui/x-date-pickers';
import TextField, {TextFieldProps} from '@mui/material/TextField';

export interface DateNavigatorProps {
  currentDate: DateTime;
  onDateChange: (newDate: DateTime) => void;
}

export default function DateNavigator({currentDate, onDateChange}: DateNavigatorProps) {
  const handlePreviousDay = () => {
    onDateChange(currentDate.minus({days: 1}));
  };

  const handleNextDay = () => {
    onDateChange(currentDate.plus({days: 1}));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      onDateChange(DateTime.fromJSDate(date));
    }
  };

  return (
    <div className="flex justify-between w-full my-2">
      <button onClick={handlePreviousDay}>&lt; Previous Day</button>

      <DatePicker
        label="Controlled picker"
        value={currentDate}
        onChange={newValue => {
          if (newValue != null) {
            onDateChange(newValue);
          }
        }}
      />

      <button onClick={handleNextDay}>Next Day &gt;</button>
    </div>
  );
}
