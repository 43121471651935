import {DateTime} from 'luxon';
import React, {useEffect, useRef, useState} from 'react';
import {
  CreateSessionDto,
  CreateTrainingDto,
  SessionDto,
  SetDto,
  TrainingDto,
  TrainingDescriptionDto,
} from '../../../../models/trainingModel';
import {Session} from 'inspector';
import Timer from '../../../common/timer/timer.component';
import AddEditTrainingComponent from '../../trainings/addEditTraining/addEditTraining.component';
import TrainingDescriptionInput from '../../trainingDescription/trainingDescriptionInput/trainingDescriptionInput.component';
import {TrainingObject} from '../../../../models/strict/sessionStrictModel';
import TagList from '../../tag/tagList.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {set} from 'react-hook-form';
import {purple} from '@mui/material/colors';

export interface CurrentSetTrainingProperties {
  currentTrainingId?: string;
  currentSet: SetDto | undefined;
}
export default function CurrentSetTrainingComponent({
  currentTrainingId,
  currentSet,
}: CurrentSetTrainingProperties) {
  useEffect(() => {
    // Your useEffect code here
  }, []);
  function renderCommonPart(trainingElement: TrainingDto | CreateTrainingDto) {
    return (
      <>
        <td className="mx-2 color-white  ">{trainingElement.counts}</td>
        <td className="mx-2 color-white ">
          <FontAwesomeIcon icon={faClock} />
          {trainingElement.duration}
        </td>
        <td className="mx-2 color-white ">
          <FontAwesomeIcon icon={faClock} />
          {trainingElement.chillTime}
        </td>
      </>
    );
  }
  const {t} = useTranslation();
  return (
    <div>
      {currentSet?.trainings.map(f => {
        if (f.id == currentTrainingId) {
          return (
            <div key={f.id} style={{background: 'purple'}}>
              {f.trainingDescription?.name}
            </div>
          );
        } else {
          return <div key={f.id}>{f.trainingDescription?.name}</div>;
        }
      })}
    </div>
  );
}
