import React from 'react';
import {TrainingDescriptionDto} from '../../../../models/trainingModel';
import classesSharedComponent from '../../../shared/sharedComponent.module.scss';
interface TagProps {
  label: TrainingDescriptionDto;
  onClick: (label: TrainingDescriptionDto) => void;
}

const Tag: React.FC<TagProps> = ({label, onClick}) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <span
      onClick={handleClick}
      className={'mx-2  ' + classesSharedComponent.buttonPrimary}
      style={{}}>
      {label.name}
    </span>
  );
};

export default Tag;
