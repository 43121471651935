import React, {useState} from 'react';
import {addSessions} from '../../../actions/trainings/session.action';
import {CreateSessionObject, SessionObject} from '../../../models/strict/sessionStrictModel';
import {SessionDto, SetDto} from '../../../models/trainingModel';
import {largeCommonDateFormat} from '../../../constants/common/dateFormat';
import {DateTime} from 'luxon';
import {useAppDispatch} from '../../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectCurrentUserId} from '../../../store/account/slices/login.slice';
import AddHistoryIcon from '../../common/icon/addHistoryIcon/addHistoryIcon.component';
import PlayCustomIcon from '../../common/icon/playIcon.component';
import ModalWrapper from '../../common/modal/modalWrapper/modalWrapper.component';
import AddEditSessionComponent from './createSession/addEditSession.component';

export interface SessionAddPanelComponentProperties {
  list?: SessionDto[];
  choosenDate?: DateTime;
  refreshHandle: () => void;
}
export default function SessionAddPanelComponent({
  choosenDate,
  refreshHandle,
}: SessionAddPanelComponentProperties) {
  const userId = useSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  const [NewSession, setNewSession] = useState<SessionDto>();
  function SaveAddNewSession(newSession: SessionDto) {
    const requestDto = new CreateSessionObject(newSession.sets, undefined, userId);
    requestDto.dateTime = DateTime.fromFormat(newSession.dateTime!, largeCommonDateFormat)
      .toUTC()
      .toISO()!;
    dispatch(addSessions(requestDto));
    setNewSession(undefined);
    refreshHandle();
  }
  function CloseAddingSession() {
    setNewSession(undefined);
  }
  function AddNewSession() {
    const session = new SessionObject();
    session.sets = [];
    if (choosenDate != null) {
      session.dateTime = choosenDate.toFormat(largeCommonDateFormat);
    }
    setNewSession(session);
  }
  return (
    <>
      <div className="mr-2 flex align-items-center">
        <AddHistoryIcon color="white" onClickHandler={AddNewSession} />
      </div>
      <div className="mr-2 flex align-items-center ">
        <PlayCustomIcon
          color="white"
          onClickHandler={() => {
            window.location.href = 'realtimeTrainings';
          }}></PlayCustomIcon>
      </div>
      {NewSession && (
        <ModalWrapper handleClose={CloseAddingSession} isOpen={NewSession != null}>
          <AddEditSessionComponent
            modificatedSession={NewSession}
            saveHandler={SaveAddNewSession}
            onSetChangeHandler={function (params: SetDto) {
              throw new Error('Function not implemented.');
            }}></AddEditSessionComponent>
        </ModalWrapper>
      )}
    </>
  );
}
