import {FoodDTO} from '../../models/mealModel';
import {AppDispatch} from '../../store';
import axios from '../../axios';
import {foodSlice} from '../../store/meals/slices/food.slice';
import {AuthApi} from './constants';
import {ResponseProductDataDto} from '../../models/strict/mealStrictModel';
export const FOOD_API_URL = AuthApi.MealApiPrefix + '/foods';
const OPEN_FOOD_FACTS_API_URL = 'https://world.openfoodfacts.org/api/v0/product';
export const getFoods = (
  signal: AbortSignal,
  foodName?: string,
  restaurantId?: string,
  userId?: string,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<FoodDTO[]>(
        FOOD_API_URL + '/filter',
        {
          paginationRequest: {
            currentPage: 0,
            pageSize: 50,
          },
          name: foodName,
          userId: userId,
          restaurantId: restaurantId,
        },
        {signal},
      );

      if (response.status >= 300) {
        dispatch(foodSlice.actions.foodFetchingError);
      } else {
        dispatch(foodSlice.actions.foodFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
      return null;
    }
  };
};
export const getFoodByCode = (barCode: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<FoodDTO>(FOOD_API_URL + '/getFoodByBarCode', {
        params: {
          barCode,
        },
      });

      if (response.status >= 300) {
      } else {
        return response.data;
      }
    } catch (e) {
      return null;
      console.log('Error Login', e);
    }
  };
};
export const getFoodByCodeExternalService = (barCode: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<{product: ResponseProductDataDto}>(
        `${OPEN_FOOD_FACTS_API_URL}/${barCode}.json`,
      );

      if (response.status >= 300 || !response.data.product) {
        return null;
      } else {
        console.log(response.data.product);
        return response;
      }
    } catch (e) {
      console.error('Error fetching food data:', e);
      return null;
    }
  };
};
export const addFood = (food: FoodDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post(FOOD_API_URL + '/createFood', food);
      if (response.status >= 300) {
        throw new Error(`Failed to add food, status code: ${response.status}`);
      }
      return response.data;
    } catch (e) {
      console.log('Error adding food', e);
      throw e;
    }
  };
};
export const UpdateFood = (food: FoodDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put(FOOD_API_URL, food);
      if (response.status >= 300) {
        dispatch(foodSlice.actions.foodFetchingError);
      } else {
        dispatch(foodSlice.actions.foodFetchingSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const DeleteFood = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete(FOOD_API_URL, {
        params: {
          foodId: id,
        },
      });
      if (response.status >= 300) {
        dispatch(foodSlice.actions.foodFetchingError);
      } else {
        dispatch(foodSlice.actions.foodFetchingSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
