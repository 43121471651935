import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getMeals} from '../../actions/meals/meal.action';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../store/account/slices/login.slice';
import RestaurantWrapper from '../../components/meals/restaurants/restaurantWrapper.component';
import {getCurrentUserInfo} from '../../actions/account/account.actions';
function RestaurantMgmtPage() {
  const params = useParams<'id'>();
  const dispatch = useAppDispatch();
  const user = useSelector(selectCurrentUser);
  useEffect(() => {
    if (user == null) {
      dispatch(getCurrentUserInfo());
    }
  }, []);
  return (
    <div className="content">
      <RestaurantWrapper user={user} />
    </div>
  );
}

export default RestaurantMgmtPage;
