import {DateTime} from 'luxon';
import React, {useEffect, useRef, useState} from 'react';
import {
  CreateSessionDto,
  CreateTrainingDto,
  SessionDto,
  TrainingDto,
  TrainingDescriptionDto,
} from '../../../../../models/trainingModel';
import {Session} from 'inspector';
import Timer from '../../../../common/timer/timer.component';
import AddEditTrainingComponent from '../../../trainings/addEditTraining/addEditTraining.component';
import TrainingDescriptionInput from '../../../trainingDescription/trainingDescriptionInput/trainingDescriptionInput.component';
import {TrainingObject} from '../../../../../models/strict/sessionStrictModel';
import TagList from '../../../tag/tagList.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import classes from '../manuallyTraining.module.scss';
import classesColor from '../../../../shared/sharedColorConfigurations.module.scss';
export interface SummaryProperties {
  session: SessionDto | CreateSessionDto | undefined;
}
export default function SummaryComponent({session}: SummaryProperties) {
  useEffect(() => {
    // Your useEffect code here
  }, []);
  function renderCommonPart(trainingElement: TrainingDto | CreateTrainingDto) {
    return (
      <>
        <td className="mx-2 color-white  ">{trainingElement.counts}</td>
        <td className="mx-2 color-white ">
          <FontAwesomeIcon icon={faClock} className={classesColor.sidebarColor + ' mx-1'} />
          {trainingElement.duration}
        </td>
        <td className="mx-2 color-white ">
          {trainingElement.chillTime != null && (
            <>
              <FontAwesomeIcon className={classesColor.sidebarColor + ' mx-1'} icon={faClock} />
              {trainingElement.chillTime}
            </>
          )}
        </td>
      </>
    );
  }
  const {t} = useTranslation();
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <div className={classes.trainingInfoWrapper}>
          <div className="ml-0 row col-11 justify-content-center  pt-0 pb-2    ">
            <h1 className={'mb-4 mt-0 text-center ' + classes.sidebarColor}>{t('Summary')} </h1>
            <table className="col-12" style={{height: 'fit-content', maxHeight: '800px'}}>
              <thead className={classes.sidebarColor} style={{textAlign: 'center'}}>
                <th className={classes.sidebarColor}>{t('Name')}</th>
                <th className={classes.sidebarColor}>{t('Count')}</th>
                <th className={classes.sidebarColor}>{t('Duration')}</th>
                <th className={classes.sidebarColor}>{t('ChilloutTime')}</th>
              </thead>
              <tbody style={{textAlign: 'center'}}>
                {session != null &&
                  session.sets?.map((setElement, setIndex) =>
                    setElement.trainings.map((trainingElement, trainingIndex) => {
                      let tsxElement = null;

                      if ('trainingDescription' in trainingElement) {
                        const trainingDto = trainingElement as TrainingDto;
                        tsxElement = (
                          <tr key={trainingIndex}>
                            <td className="mx-2 color-white ">
                              {trainingDto.trainingDescription
                                ? trainingDto.trainingDescription.name
                                : trainingDto.customTrainingName}
                            </td>
                            {renderCommonPart(trainingDto)}
                          </tr>
                        );
                      } else if ('customName' in trainingElement) {
                        const createTrainingDto = trainingElement as CreateTrainingDto;
                        tsxElement = (
                          <tr key={trainingIndex}>
                            <td className="mx-2 color-white ">
                              {createTrainingDto.customTrainingName}
                            </td>
                            {renderCommonPart(createTrainingDto)}
                          </tr>
                        );
                      }
                      return tsxElement;
                    }),
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
