import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TranslationDataDto, TranslationDto} from '../../../models/strict/translationStrictModel';
import modalClasses from '../../common/modal/styles/modalShared.module.scss';
import colorConfigurations from '../../shared/sharedColorConfigurations.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {LocaleSelector} from '../../common/localeSelector/localeSelector.component';
export interface TranslationAddEditComponentProperties {
  translation: TranslationDataDto;
  onAddEditTranslationHandler: (translation: TranslationDataDto) => void;
}

export default function TranslationAddEditComponent({
  translation,
  onAddEditTranslationHandler,
}: TranslationAddEditComponentProperties) {
  const {t} = useTranslation();
  const [editedItem, setEditedItem] = useState<TranslationDataDto>();
  useEffect(() => {
    setEditedItem(translation);
  }, [translation]);
  if (editedItem != null) {
    return (
      <div className="row my-2" style={{alignItems: 'center'}}>
        <div className="col-5">
          <LocaleSelector
            className={modalClasses.inputModal + ' col-12'}
            onLocaleChange={function (value: string): void {
              setEditedItem(prev => {
                prev!.locale = value;
                return prev;
              });
            }}
            selectedLocaleKey={editedItem.locale}></LocaleSelector>
        </div>
        <div className="col-5">
          <input
            type="text"
            id="value"
            className={`${modalClasses.inputModal} w-full outline-0`}
            name="value"
            value={editedItem.value}
            onChange={event => {
              const newValue = event.target.value;
              setEditedItem(prev => ({
                ...prev,
                translationId: prev?.translationId ?? '',
                locale: prev?.locale ?? '',
                value: newValue,
              }));
            }}
          />
        </div>
        {editedItem.value && editedItem.locale && (
          <div className={'col-2 ' + colorConfigurations.sidebarColor}>
            <div
              onClick={() => {
                onAddEditTranslationHandler(editedItem);
              }}>
              <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <></>;
}
