import React, {useState} from 'react';
import {SessionDto, TrainingDto} from '../../../../../models/trainingModel';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCompress,
  faExpand,
  faStopwatch20,
  faTimeline,
} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';
import {selectCurrentUserId} from '../../../../../store/account/slices/login.slice';
import classes from './lastSession.module.scss';
import classesShared from '../../../../shared/sharedComponent.module.scss';
import {useTranslation} from 'react-i18next';
import SessionAddPanelComponent from '../../sessionAddPanel.component';

export interface LastSessionComponentProperties {
  session?: SessionDto;
  refreshHandle: () => void;
}

export function LastSessionComponent({session, refreshHandle}: LastSessionComponentProperties) {
  const [showAllTrainings, setShowAllTrainings] = useState<{[key: number]: boolean}>({}); // Track visibility of trainings for each set
  const userId = useSelector(selectCurrentUserId);
  const {t} = useTranslation();

  const GetTrainingInfo = (training: TrainingDto) => {
    if (training.counts != null && training.counts > 0) {
      return (
        <div style={{position: 'relative'}}>
          <div>{training.counts}</div>
        </div>
      );
    } else {
      return (
        <div style={{position: 'relative'}}>
          <div>
            <FontAwesomeIcon icon={faStopwatch20} className="mx-2" />
            {training.duration}
          </div>
        </div>
      );
    }
  };

  if (session != null) {
    return (
      <div className={classes.lastSessionWrapper}>
        <div className={classes.lastSessionWrapperHeader}>
          <div style={{display: 'flex'}}>
            <div className={classes.textInformation}>{t('LastSession')}:</div>
            {session.dateTime && DateTime.fromISO(session.dateTime).toFormat('yyyy LLL dd HH:mm')}
          </div>
          <div className="mb-4">
            <a className={classesShared.historicWrapper} href={'./history/' + userId}>
              <FontAwesomeIcon width={30} height={30} icon={faTimeline}></FontAwesomeIcon>
            </a>
          </div>
        </div>
        <div className={classes.lastSessionWrapperItems}>
          {session.sets?.map((set, setIndex) => {
            const showAll = showAllTrainings[setIndex] ?? false;
            const trainingsToShow = showAll ? set.trainings : set.trainings.slice(0, 5);

            return (
              <div key={setIndex} className={classes.lastSessionComponent}>
                {trainingsToShow.map((training, index) => (
                  <React.Fragment key={index}>
                    <div className={classes.trainingInfo}>
                      {training.customTrainingName}
                      {GetTrainingInfo(training)}
                    </div>
                    {index < trainingsToShow.length - 1 && (
                      <div className="mx-2">
                        <FontAwesomeIcon icon={faArrowRight} />
                      </div>
                    )}
                  </React.Fragment>
                ))}

                {/* Show More/Less button for each set */}
                {set.trainings.length > 5 && (
                  <>
                    <div className="mx-2">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    <div className={classes.trainingInfo}>
                      <button
                        onClick={() =>
                          setShowAllTrainings(prev => ({
                            ...prev,
                            [setIndex]: !showAll, // Toggle visibility for this set's trainings
                          }))
                        }>
                        {showAll ? (
                          <>
                            <div className={classes.trainingInfo} style={{minWidth: 'auto'}}>
                              <FontAwesomeIcon icon={faCompress} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={classes.trainingInfo} style={{minWidth: 'auto'}}>
                              <FontAwesomeIcon icon={faExpand} />
                            </div>
                          </>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
