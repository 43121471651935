import React, {useEffect} from 'react'; // Import MainLogo component here
import MainLogo from '../components/common/logos/mainLogo';
import '../app.scss';
import classes from './auth.module.scss';
interface AuthPageLayoutProps {
  children: React.ReactNode;
}

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({children}) => {
  return (
    <div className={classes.authWrapper}>
      <div className={classes.authInnerBlock}>
        <div style={{width: '100%'}}>{children}</div>
      </div>
    </div>
  );
};

export default AuthPageLayout;
