import React, {useTransition} from 'react';
import {useTranslation} from 'react-i18next';

const PrivacyPage: React.FC = () => {
  return (
    <div
      className="greyMenuBackground"
      style={{
        position: 'fixed',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 2,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <h1 style={{fontSize: '4rem'}}>Privacy</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '600px',
          overflowY: 'auto',
        }}>
        <h1>Welcome to Trackoon.</h1>
        We are committed to protecting your personal data and respecting your privacy. This Privacy
        Policy explains what information we collect, how we use it, and your rights regarding your
        data. By using our website, you agree to the terms of this policy.
        <h2>1. Information We Collect</h2>
        We collect different types of information depending on how you interact with our website.
        This includes: Personal Information: Name, email address, phone number, or any other
        information you provide when filling out forms or signing up for our services. Usage Data:
        Information about how you use our website, such as your IP address, browser type, device
        information, and browsing patterns. Cookies and Tracking Technologies: We use cookies to
        enhance your browsing experience, analyze traffic, and personalize content. You can manage
        your cookie preferences through your browser settings.
        <h2>2. How We Use Your Information We use</h2>
        your data for the following purposes: Providing and Improving Services: To offer our
        products and services and improve your overall experience on our site. Communication: To
        respond to your inquiries, send updates, and provide support. Marketing: To send promotional
        materials (if you have opted in), and to tailor advertisements based on your preferences.
        Analytics: To monitor and analyze trends, usage, and activity on our site.
        <h2>3. Sharing Your</h2>
        Information We do not sell your personal information. However, we may share your data with:
        Service Providers: Trusted third parties who help us operate our website and provide our
        services. Compliance and Legal Obligations: If required by law or to protect our rights and
        the safety of others.
        <h2>4. Data Security</h2>
        We use industry-standard measures to safeguard your data against unauthorized access,
        alteration, or destruction. However, please note that no method of transmission over the
        internet is 100% secure.
        <h2>5. Your Rights</h2>
        You have the following rights regarding your personal information: Access: Request a copy of
        the data we hold about you. Correction: Request corrections to any inaccurate or incomplete
        data. Deletion: Request the deletion of your data in certain circumstances. Objection:
        Object to the processing of your data based on legitimate interests or for direct marketing
        purposes. Data Portability: Receive your data in a structured, commonly used format.
        <h2>6.Cookies</h2>
        We use cookies and similar tracking technologies to track activity on our site and store
        certain information. You can instruct your browser to refuse all cookies or indicate when a
        cookie is being sent. For more information, refer to our Cookie Policy.
        <h2>7. Changes to This Policy</h2>
        We may update our Privacy Policy from time to time. Any changes will be posted on this page,
        so please check back periodically. If the changes are significant, we will provide a more
        prominent notice or request your consent as required by law.
        <h2>8. Contact Us</h2>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
        Email: ultwolf@gmail.com
      </div>
    </div>
  );
};

export default PrivacyPage;
