import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authReducer from './account/slices/login.slice';
import foodReducer from './meals/slices/food.slice';
import mealReducer from './meals/slices/meal.slice';
import userReducer from './account/slices/user.slice';
import restaurantReducer from './meals/slices/restaurant.slice';
import locationReducer from './common/slices/location.slice';
import sessionReducer from './trainings/slices/session.slice';
import trainingReducer from './trainings/slices/training.slice';
import trainingDescriptionReducer from './trainings/slices/trainingDescription.slice';
const rootReducer = combineReducers({
  authReducer,
  foodReducer,
  mealReducer,
  restaurantReducer,
  userReducer,
  locationReducer,
  sessionReducer,
  trainingDescriptionReducer,
  trainingReducer,
});

export function setupStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}),
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
