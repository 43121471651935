import React, {ChangeEvent, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../../store/account/slices/login.slice';
import {UserInfoDTO} from '../../../models/accountModel';
import {CreateRestaurantDTO, RestaurantDTO} from '../../../models/mealModel';
import {addRestaurant, getRestaurants} from '../../../actions/meals/restaurant.action';
import {LocationObject, RestaurantObject} from '../../../models/strict/mealStrictModel';
import AddEditRestaurantComponent from './addEditRestaurant.component';
import RestaurantList from './restaurantList.component';
import {useTranslation} from 'react-i18next';
export interface RestaurantWrapperProperties {
  user?: UserInfoDTO;
}
export default function RestaurantWrapper({user}: RestaurantWrapperProperties) {
  const {restaurants} = useAppSelector(state => state.restaurantReducer);
  const [NewRestaurant, setNewRestaurant] = useState<RestaurantDTO>();
  const userId = useSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  useEffect(() => {
    dispatch(getRestaurants());
  }, [dispatch]);

  function AddNewRestaurant() {
    const newRest = new RestaurantObject();
    newRest.location = new LocationObject();
    newRest.location.country = 'Ukraine';
    setNewRestaurant(newRest);
  }
  function CloseAddNewRestaurant(NewFoodRestaurant: RestaurantDTO) {
    console.log(NewFoodRestaurant);
    console.log(NewFoodRestaurant as CreateRestaurantDTO);
    NewFoodRestaurant.ownerUserId = userId;
    dispatch(addRestaurant(NewFoodRestaurant as CreateRestaurantDTO));
    dispatch(getRestaurants());
    setNewRestaurant(undefined);
  }
  return (
    <div className="mb-4 relative">
      <span onClick={AddNewRestaurant}>{t('create new Restaurant')}</span>
      {NewRestaurant && (
        <AddEditRestaurantComponent
          restaurant={NewRestaurant}
          saveHandler={CloseAddNewRestaurant}></AddEditRestaurantComponent>
      )}
      <RestaurantList list={restaurants} />
    </div>
  );
}
