import {AppDispatch} from '../../store';
import axios from '../../axios';
import {TrainingDescriptionDto} from '../../models/trainingModel';
import {trainingDescriptionSlice} from '../../store/trainings/slices/trainingDescription.slice';
import {TrainingApi} from './constant';
export const TRAININGDESCRIPTION_API_URL = TrainingApi.TrainingApiPrefix + '/trainingDescription';
export const getTrainingDescriptions = (name: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<TrainingDescriptionDto[]>(TRAININGDESCRIPTION_API_URL, {
        params: {
          name,
        },
      });
      if (response.status >= 300) {
        console.log('error');
      } else {
        dispatch(
          trainingDescriptionSlice.actions.trainingDescriptionsFetchingSuccess(response.data),
        );
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
