import React, {ChangeEvent, useEffect, useState} from 'react';

import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker, DateTimePicker, TimePicker} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';
import {largeCommonDateFormat, longCommonTimeFormat} from '../../../constants/common/dateFormat';
import {useAppDispatch} from '../../../hooks/redux';
import {
  TrainingDto,
  TrainingDescriptionDto,
  ScheduleDto,
  TrainingPlanDto,
  CreateScheduleDto,
} from '../../../models/trainingModel';
import TrainingDescriptionInput from '../trainingDescription/trainingDescriptionInput/trainingDescriptionInput.component';
import {duration} from '@mui/material';
import {
  CreateScheduleDtoObject,
  TimeSpanObject,
  TrainingObject,
} from '../../../models/strict/sessionStrictModel';

import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import TrainingDescriptionChoosed from '../trainingDescription/trainingDescriptionChoosed.component';
import {DateInputProps} from '../../../constants/common/dateInputProps';
import {getTrainingPlans} from '../../../actions/trainings/trainingPlan.action';
import {useSelector} from 'react-redux';
import {selectCurrentUser, selectCurrentUserId} from '../../../store/account/slices/login.slice';
import TrainingPlanWrapper from '../trainingPlan/trainingPlanWrapper/trainingPlanWrapper.component';
import {addSchedules} from '../../../actions/trainings/schedule.action';
import DayChoosingComponent from './dayChoosing.component';

export interface AddScheduleComponentProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveHandler: (params: any) => any;
}
export default function AddScheduleComponent({saveHandler}: AddScheduleComponentProperties) {
  const {t} = useTranslation();
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [newSchedule, setNewSchedule] = useState<CreateScheduleDto>(new CreateScheduleDtoObject());
  const [trainingPlans, setTrainingPlans] = useState<TrainingPlanDto[]>();
  const user = useSelector(selectCurrentUser);
  const userId = useSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTrainingPlans(userId)).then(f => setTrainingPlans(f));
  }, []);
  return (
    <div className="row col-12 relative" style={{marginLeft: 0}}>
      <p className="">{t('Training')}</p>
      <div
        className="row col-12 border-primary-custom py-4 primaryBackground custom-border"
        style={{marginLeft: 0}}>
        <div className="row col-12 " style={{marginLeft: 0}}>
          <TrainingPlanWrapper
            IsShowAddIcon={false}
            onSelectTrainingPlanHandler={trainingPlan => {
              setNewSchedule({...newSchedule, planId: trainingPlan.id});
            }}
            list={trainingPlans}
          />
        </div>
        <div>
          <input
            type="number"
            id="weight"
            className="border px-4 py-2 w-full outline-0 h-[42px]"
            name="counts"
            value={newSchedule.everyCount ?? 0}
            onChange={event => {
              setNewSchedule({...newSchedule, everyCount: Number.parseInt(event.target.value!)});
            }}
          />
        </div>
        <div style={{display: 'flex'}} className="py-4 justify-content-center">
          {weekdays.map((f, index) => {
            return (
              <DayChoosingComponent
                key={f}
                day={f}
                numberOfDay={index}
                onClickHandler={(value, state) => {
                  if (state) {
                    setNewSchedule({
                      ...newSchedule,
                      weekdays: newSchedule.weekdays ? [...newSchedule.weekdays, value] : [value],
                    });
                  } else {
                    setNewSchedule({
                      ...newSchedule,
                      weekdays: newSchedule.weekdays
                        ? newSchedule.weekdays.filter(day => day !== value)
                        : [],
                    });
                  }
                }}
              />
            );
          })}
        </div>
        <div className="col-6">
          <DateTimePicker
            label="Controlled picker"
            value={
              newSchedule?.scheduleDateStart
                ? DateTime.fromISO(newSchedule?.scheduleDateStart)
                : DateTime.now()
            }
            slotProps={DateInputProps.dateTimeSlots}
            onChange={newValue => {
              newValue
                ? setNewSchedule(prevState => ({
                    ...prevState,
                    scheduleDateStart: newValue.toUTC().toISO(),
                  }))
                : console.log(newValue);
            }}
          />
        </div>
        <div className="col-6">
          <DateTimePicker
            label="Controlled picker"
            value={
              newSchedule?.scheduleDateEnd
                ? DateTime.fromISO(newSchedule?.scheduleDateEnd)
                : DateTime.now()
            }
            slotProps={DateInputProps.dateTimeSlots}
            onChange={newValue => {
              newValue
                ? setNewSchedule(prevState => ({
                    ...prevState,
                    scheduleDateEnd: newValue.toUTC().toISO(),
                  }))
                : console.log(newValue);
            }}
          />
        </div>
        <span
          className="mt-3 top-0 right-0 absolute"
          onClick={() => {
            newSchedule.userId = userId;
            saveHandler(newSchedule);
          }}>
          <FontAwesomeIcon icon={faSave} size="lg" />
        </span>
      </div>
    </div>
  );
}
