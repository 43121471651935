import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '../../../../hooks/debounce';
import axios from 'axios';
import classes from './weightModifiersInput.module.scss';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux';
import {
  createWeightModifier,
  deleteWeightModifier,
  filterWeightModifiers,
  getWeightModifierById,
} from '../../../../actions/meals/weightModifier.actions';
import {useTranslation} from 'react-i18next';
import zIndex from '@mui/material/styles/zIndex';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import ScrollableBody from '../../../common/scrollbar/scrollbar.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalWrapper from '../../../common/modal/modalWrapper/modalWrapper.component';
import {
  MacronutrientsObject,
  WeightModifierDto,
  WeightModifierFilter,
} from '../../../../models/strict/mealStrictModel';
import classColors from '../../../shared/sharedColorConfigurations.module.scss';
import {faBarcode, faXmark} from '@fortawesome/free-solid-svg-icons';
import AddWeightModifiers from '../addWeightModifiers/addWeightModifiers.component';
import PlateIcon from '../../../common/icon/plateIcon/plateIcon.component';
import {relative} from 'path';

export interface weightModifiersInputProperties {
  getNewWeightModifier: (params: WeightModifierDto) => void;
  userId: string;
}

export default function WeightModifiersInput({
  getNewWeightModifier,
  userId,
}: weightModifiersInputProperties) {
  // const [value, setValue] = useState('');
  const defaultWeightModifier: WeightModifierDto = {
    id: '',
    ownerUserId: '',
    name: '',
    description: '',
    weight: 0,
  };
  const [weightModifier, setWeightModifier] = useState<WeightModifierDto>(defaultWeightModifier);
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const debounced = useDebounce<string>(weightModifier?.name ?? '', 500);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [allWeightModifiers, setAllWeightModifiers] = useState<WeightModifierDto[]>();
  const [weightModifiers, setWeightModifiers] = useState<WeightModifierDto[]>();
  const [isAddWeightModifiers, setIsAddWeightModifiers] = useState<boolean>(false);
  async function searchWeightModifiers(search: string) {
    setWeightModifiers(allWeightModifiers?.filter(f => f.name.includes(search, 0)));
  }
  const [isPlateUse, setIsPlateUse] = useState<boolean>(false);
  const {t} = useTranslation();
  useEffect(() => {
    if (abortController) {
      abortController.abort();
    }
    const controller = new AbortController();
    setAbortController(controller);
    const weightModifierFilter: WeightModifierFilter = {
      userId: userId,
    };
    dispatch(filterWeightModifiers(controller.signal, weightModifierFilter)).then(f => {
      setAllWeightModifiers(f);
      setWeightModifiers(f);
    });
  }, []);
  useEffect(() => {
    searchWeightModifiers(debounced).then(() => setDropdown(true));
  }, [debounced]);
  const getAddButton = () => {
    return (
      <li
        style={{borderRadius: '5px', textDecoration: 'none', listStyle: 'none'}}
        className="cursor-pointer background-primary text-white px-3 my-2 p-2"
        onClick={() => {
          setIsAddWeightModifiers(true);
        }}>
        +
      </li>
    );
  };
  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    const {name, value} = event.target;

    setWeightModifier(prev => ({
      ...prev,
      [name]: name === 'weight' ? parseFloat(value) : value, // Convert weight to number if necessary
    }));
  }

  function renderDropdown() {
    if (weightModifiers?.length === 0 || weightModifiers == undefined) {
      return (
        <ul className={classes.foodWrapper + '  ' + classes.dropdownItems}>
          <li className="text-center">{t('noResults')} </li>
          {getAddButton()}
        </ul>
      );
    }

    return (
      <div>
        <ul className={classes.foodWrapper + '  ' + classes.dropdownItems}>
          {weightModifiers.map((f, index) => {
            return (
              <li
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  listStyle: 'none',
                }}
                onClick={() => {
                  getNewWeightModifier(f);
                }}
                className={
                  classes.weightModifierLi +
                  ' cursor-pointer background-primary text-white px-3 my-2 p-2'
                }
                key={index}>
                <div className="mx-3">{f.name}</div>
                <div className="mx-3">{f.weight}</div>
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{position: 'absolute', right: '5px'}}
                  onClick={event => {
                    event.stopPropagation(); // Stop click event from bubbling up to the <li>
                    dispatch(deleteWeightModifier(f.id)).then(deletedId => {
                      const controller = new AbortController();
                      setAbortController(controller);
                      const weightModifierFilter = {
                        userId: userId,
                      };
                      dispatch(filterWeightModifiers(controller.signal, weightModifierFilter)).then(
                        l => {
                          setAllWeightModifiers(l);
                          setWeightModifiers(l);
                        },
                      );
                    });
                  }}
                />
              </li>
            );
          })}
          {getAddButton()}
        </ul>
      </div>
    );
  }

  return (
    <div className="  my-2 relative">
      {isAddWeightModifiers && (
        <ModalWrapper
          handleClose={() => {
            setIsAddWeightModifiers(false);
          }}
          isOpen={isAddWeightModifiers ?? false}>
          <AddWeightModifiers
            initName={weightModifier.name ?? ''}
            getNewWeightModifier={function (params: WeightModifierDto): void {
              getNewWeightModifier(params);
              setIsAddWeightModifiers(false);
            }}
            userId={userId}></AddWeightModifiers>
        </ModalWrapper>
      )}

      <button className={classes.plateIcon + ' mr-3'}>
        <PlateIcon
          classNaming={classColors.fillText}
          onClickHandler={() => {
            setIsPlateUse(!isPlateUse);
          }}></PlateIcon>
        <p style={{marginBottom: 0}} className="ml-2">
          {t('Plate')}
        </p>

        {isPlateUse && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              cursor: 'default',
              padding: '10px 20px',
              borderRadius: '5px',
              marginTop: '60%',
              background: 'var(--backgroundDarkerColor)',
              marginLeft: '-150%',
              border: '1px var(--backgroundColor) solid',
            }}>
            <div className="col-12 flex my-4" style={{minWidth: '200px', position: 'relative'}}>
              <label style={{verticalAlign: 'middle', marginRight: '20px'}}>{t('Plate')}</label>
              <input
                className={classesModal.inputModal + '    w-full outline-0 h-[42px]'}
                type="text"
                name="name"
                onChange={changeHandler}
                value={weightModifier?.name ?? ''}
                placeholder="Search for plate..."
              />
            </div>
            {dropdown && (
              <ul className={classes.weightModifierWrapper + '  ' + classes.dropdownItems}>
                {renderDropdown()}
              </ul>
            )}
          </div>
        )}
      </button>
    </div>
  );
}
