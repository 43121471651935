import React from 'react';
import styles from './circleProgress.module.scss';
import classesColor from '../../shared/sharedColorConfigurations.module.scss';
interface CircleProgressProps {
  value: number;
  maxValue: number;
  text?: string;
  size?: number;
  thickness?: number;
  borderThickness?: number;
}

export default function CircleProgress({
  value,
  maxValue,
  text,
  size = 100,
  thickness = 6,
  borderThickness = 1,
}: CircleProgressProps) {
  const clampedValue = Math.min(Math.max(value, 0), maxValue);
  const progress = (clampedValue / maxValue) * 100;

  return (
    <div
      className={styles.container}
      style={
        {
          '--size': `${size}px`,
          '--thickness': `${thickness}px`,
          '--border-thickness': `${borderThickness}px`,
          '--progress': `${progress}%`,
        } as React.CSSProperties
      }>
      <div className={styles.innerBorder}> </div>
      <div className={styles.progressRing}></div>
      <div className={styles.centerText}>
        <span> {Math.round(value)}</span>
        <span
          className={classesColor.sidebarColor + ''}
          style={{borderTop: '2px var(--primaryColor) solid'}}>
          {Math.round(maxValue)}
        </span>
        <span> {text}</span>
      </div>
    </div>
  );
}
