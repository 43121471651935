/* eslint-disable @typescript-eslint/no-explicit-any */
import {useDebounce} from '../../hooks/debounce';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks/redux';
import {selectCities} from '../../store/common/slices/location.slice';
import {useSelector} from 'react-redux';
import {UserInfoDTO} from '../../models/accountModel';
import {getLastDateForUser} from '../../utils/accountUtil';
import {DateTime} from 'luxon';
import {shortCommonDateFormat} from '../../constants/common/dateFormat';
import {WeightPerDayObject} from '../../models/strict/accountStrictModel';
import {SetWeightOfDay} from '../../actions/account/account.actions';

import {useTranslation} from 'react-i18next';
export interface WeightInputProperties {
  user: UserInfoDTO;
  date?: DateTime;
}
export default function WeightInput({user, date}: WeightInputProperties) {
  const dispatch = useAppDispatch();
  const [weight, setWeight] = useState<number>(0);
  const [isKg, setIsKg] = useState<boolean>(true);
  const [cancelToken, setCancelToken] = useState<any>(null);
  const {t} = useTranslation();
  useEffect(() => {
    if (user?.weights != null) {
      let dateWeight = 0;
      if (date != null) {
        dateWeight = user?.weights![date.toFormat(shortCommonDateFormat)];
      }
      if (dateWeight == 0) {
        const lastDate = getLastDateForUser(user);
        if (lastDate != null) {
          dateWeight = user?.weights![lastDate];
        } else {
          dateWeight = 0;
        }
      }
      setWeight(dateWeight);
    }
  }, [user]);

  const handleWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newWeight = parseFloat(event.target.value);
      if (isNaN(newWeight)) return;
      if (!isKg) {
        newWeight = newWeight / 2.20462;
      }

      setWeight(newWeight);
      if (cancelToken) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      let weightDate = DateTime.now();
      if (date != null) {
        weightDate = date;
      }
      dispatch(
        SetWeightOfDay(
          new WeightPerDayObject(
            weightDate.startOf('day').toFormat(shortCommonDateFormat),
            newWeight,
          ),
        ),
      );
    },
    [isKg, cancelToken],
  );

  const toggleUnit = () => {
    const newWeight = isKg ? (weight * 2.20462).toFixed(2) : (weight / 2.20462).toFixed(2);
    setWeight(parseFloat(newWeight));
    setIsKg(!isKg);
  };

  return (
    <div className="mb-4 relative">
      <input
        type="number"
        min={isKg ? 30 : (30 * 2.20462).toFixed(2)}
        max={isKg ? 180 : (180 * 2.20462).toFixed(2)}
        value={isKg ? weight : (weight * 2.20462).toFixed(2)}
        onChange={handleWeightChange}
        className="border px-4 py-2  outline-0 h-[42px]"
      />
      <button onClick={toggleUnit} className="toggle-button-class">
        {isKg ? t('kg') : t('lbs')}
      </button>
    </div>
  );
}
