import React, {ChangeEvent, useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import classes from './trainingComponent.module.scss';
import {TrainingDto} from '../../../../models/trainingModel';
import AddEditTrainingComponent from '../addEditTraining/addEditTraining.component';
import {TrainingObject} from '../../../../models/strict/sessionStrictModel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faXmark} from '@fortawesome/free-solid-svg-icons';
export interface TrainingComponentProperties {
  training?: TrainingDto;
  clickDeleteHandler: (Training: TrainingDto) => void;
  clickAddHandler: (Training: TrainingDto) => void;
  onClickHandler?: (Training: TrainingDto) => void;
}
export function TrainingComponent({
  training,
  onClickHandler,
  clickDeleteHandler,
  clickAddHandler,
}: TrainingComponentProperties) {
  if (training != null) {
    return (
      <tr
        onClickCapture={() => {
          if (onClickHandler != null) {
            onClickHandler(training);
          }
        }}
        key={training.id}
        // onClick={() => navigate(`/airport/${airport.id}`)}
        className={classes.trainingComponent}
        onClick={() => {}}>
        {training.trainingDescription?.name && (
          <td style={{width: 'fit-content'}} className="px-2">
            {training.trainingDescription?.name}
          </td>
        )}
        {training.customTrainingName && (
          <td style={{width: 'fit-content'}} className="mx-2">
            {training.customTrainingName}
          </td>
        )}
        <td className="mx-2">{training.counts}</td>
        <td className="mx-2">{training.duration}</td>
        <td className="mx-2">{training.chillTime}</td>
        <div className="action-wrapper">
          <p
            onClick={e => {
              e.stopPropagation();
              clickDeleteHandler(training);
            }}>
            <FontAwesomeIcon icon={faEdit} />
          </p>
          <p
            onClick={e => {
              e.stopPropagation();
              clickDeleteHandler(training);
            }}>
            <FontAwesomeIcon icon={faXmark} />
          </p>
        </div>
      </tr>
    );
  } else {
    return <div></div>;
  }
}
