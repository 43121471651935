import {AppDispatch} from '../../store';
import axios from '../../axios';
import {queries} from '@testing-library/react';
import {CreateRestaurantDTO, RestaurantDTO} from '../../models/mealModel';
import {restaurantSlice} from '../../store/meals/slices/restaurant.slice';
import {AuthApi} from './constants';
export const MEAL_API_URL = AuthApi.MealApiPrefix + '/restaurants/';
export const getRestaurants = (
  city?: string,
  name?: string,
  country?: string,
  currentPage: number = 0,
  pageSize: number = 20,
  orderBy: string = 'name',
  isDesc: boolean = true,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const requestBody = {
        paginationRequest: {
          currentPage,
          pageSize,
        },
        orderBy,
        isDesc,
        city,
        country,
        name,
      };

      const response = await axios.post<RestaurantDTO[]>(MEAL_API_URL + 'filter', requestBody);

      if (response.status >= 300) {
        dispatch(restaurantSlice.actions.restaurantFetchingError);
      } else {
        dispatch(restaurantSlice.actions.restaurantsFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error fetching restaurants', e);
    }
  };
};
export const getRestaurant = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<RestaurantDTO>(MEAL_API_URL + id);
      if (response.status >= 300) {
        dispatch(restaurantSlice.actions.restaurantFetchingError);
      } else {
        dispatch(restaurantSlice.actions.restaurantFetchingSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addRestaurant = (restaurant: CreateRestaurantDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<RestaurantDTO[]>(MEAL_API_URL, restaurant);
      if (response.status >= 300) {
        dispatch(restaurantSlice.actions.restaurantFetchingError);
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
