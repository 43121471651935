import React, {ChangeEvent, useEffect, useState, useTransition} from 'react';
import {useNavigate} from 'react-router-dom';
import {faBarcode, faDroplet, faVial} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalWrapper from '../../common/modal/modalWrapper/modalWrapper.component';
import {useTheme} from '../themeContext/themeContext';
import {useTranslation} from 'react-i18next';
import classes from './themeList.module.scss';
import {HelperSection} from '../../common/helperSection/helperSection.component';
export interface ThemeListProperties {}

export default function ThemeList() {
  const {theme, toggleTheme} = useTheme();
  const {t} = useTranslation();

  const themes = [
    {name: 'bloody', icon: faDroplet, label: 'Bloody', color: 'var(--primaryColor)'},
    {name: 'light', icon: faDroplet, label: 'Light', color: 'var(--primaryColor)'},
    {name: 'dark', icon: faDroplet, label: 'Dark', color: 'var(--primaryColor)'},
    {name: 'forest', icon: faDroplet, label: 'Forest', color: 'var(--primaryColor)'},
    {name: 'royal', icon: faDroplet, label: 'Royal', color: 'var(--primaryColor)'},
    {name: 'solar', icon: faDroplet, label: 'Solar', color: 'var(--primaryColor)'},
    {name: 'ocean', icon: faDroplet, label: 'Ocean', color: 'var(--primaryColor)'},
  ] as const;
  return (
    <>
      <div
        style={{
          minWidth: '500px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '10px 20px ',
        }}
        className={
          'relative col-12 border-primary-custom pt-4 pb-4 darkModalBackground color-white custom-border   px-4 '
        }>
        <HelperSection helperValue={'SelectTheme'}></HelperSection>

        <div
          style={{marginTop: '30px', width: '100%', background: 'var(--primaryColor)'}}
          className={classes.themeWrapper}>
          {themes.map(({name, label, icon}) => (
            <button
              key={name}
              className={`${classes.baseTheme} ${classes[name + 'Theme']}`}
              onClick={() => toggleTheme(name)}>
              <FontAwesomeIcon icon={icon} size="2xl"></FontAwesomeIcon>
              <p style={{color: `var(--${name}TextColor)`}}>{t(label)}</p>
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
