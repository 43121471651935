import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import NoSleep from 'nosleep.js';
import drop from '../audio/tik_drop.wav';
import final from '../audio/final_drop.wav';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faPause,
  faPauseCircle,
  faPlayCircle,
  faVolumeHigh,
  faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons';
import {DateTime, Duration} from 'luxon';
import classes from './timer.module.scss';
interface TimerProps {
  recommendedTime?: string | null | undefined;
  onOvertimeHandler?: () => void;
}

const Timer: React.FC<TimerProps> = ({recommendedTime, onOvertimeHandler}) => {
  const [isPlaySound, setPlaySound] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const IsPlaySoundSet = 'IsPlaySound';
  const accumulateTime = useRef<number>(0);
  const noSleep = useRef<NoSleep | null>(null);
  const [wakeLockActive, setWakeLockActive] = useState<boolean>(false);
  const initiateTime = useRef<DateTime>(DateTime.now());
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioFinalRef = useRef<HTMLAudioElement | null>(null);
  const [time, setTime] = useState<number>(0);
  const audio = new Audio(drop);
  const audio_final = new Audio(final);
  const timerId = useRef<NodeJS.Timeout | null>(null);

  const setSound = () => {
    setPlaySound(!isPlaySound);
    localStorage.setItem(IsPlaySoundSet, !isPlaySound ? 'True' : 'False');
  };

  const formatTime = (seconds: number): string => {
    const duration = Duration.fromObject({seconds});
    return duration.toFormat('hh:mm:ss');
  };

  const getSound = () => {
    const isPlaySoundStorage = localStorage.getItem(IsPlaySoundSet);
    if (isPlaySoundStorage == null || isPlaySoundStorage === 'False') {
      return false;
    }
    return true;
  };

  const startTime = () => {
    if (timerId.current != null) {
      clearInterval(timerId.current);
    }
    timerId.current = setInterval(() => {
      const currentTime = DateTime.now();
      let elapsedTime = currentTime.diff(initiateTime.current, 'seconds').seconds;
      if (accumulateTime.current != null) {
        elapsedTime += accumulateTime.current;
      }
      setTime(elapsedTime);
      const playSound = getSound();
      if (playSound && audioRef.current) {
        audioRef.current.play();
      }
      if (formatTime(elapsedTime) === recommendedTime) {
        if (playSound && audioFinalRef.current) {
          audioFinalRef.current.play();
        }

        if (onOvertimeHandler != null) {
          onOvertimeHandler();
        }
      }
    }, 1000);
  };

  const changePlayStatus = () => {
    setIsStop(!isStop);
    if (!isStop) {
      if (timerId.current != null) {
        clearInterval(timerId.current);
      }
      accumulateTime.current = DateTime.now().diff(initiateTime.current, 'seconds').seconds;
    } else {
      initiateTime.current = DateTime.now();
      startTime();
    }
  };

  useEffect(() => {
    // Initialize NoSleep.js
    noSleep.current = new NoSleep();

    const enableNoSleep = () => {
      noSleep.current?.enable();
      setWakeLockActive(true);
      console.log('NoSleep.js enabled');
    };
    audioRef.current = new Audio(drop);
    audioFinalRef.current = new Audio(final);
    const disableNoSleep = () => {
      noSleep.current?.disable();
      setWakeLockActive(false);
      console.log('NoSleep.js disabled');
    };

    // Enable NoSleep.js when component mounts
    enableNoSleep();

    // Cleanup function to disable NoSleep.js when component unmounts
    return () => {
      disableNoSleep();
    };
  }, []);

  useEffect(() => {
    setPlaySound(getSound());
    startTime();

    return () => {
      if (timerId.current != null) {
        clearInterval(timerId.current);
      }
    };
  }, []);

  const {t} = useTranslation();
  return (
    <div
      className={'m-2'}
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}>
      <div className="mx-2" style={{textAlign: 'center'}}>
        <p>{t('TimeElapsed')}: </p>
        <p style={{color: 'white'}}>{formatTime(time)}</p>
      </div>
      <div className="flex justify-content-center mx-2" style={{textAlign: 'center'}}>
        <div
          style={{width: '35px'}}
          className={
            classes.timerIcon +
            'border-custom  circle flex justify-content-center p-2 align-items-center border-primary '
          }
          onClick={() => {
            changePlayStatus();
          }}>
          {isStop ? (
            <FontAwesomeIcon icon={faPauseCircle} />
          ) : (
            <FontAwesomeIcon icon={faPlayCircle} />
          )}
        </div>
        <div
          style={{width: '35px', textAlign: 'center'}}
          className={
            classes.timerIcon +
            'border-custom  circle flex justify-content-center p-2 align-items-center border-primary '
          }
          onClick={() => {
            setSound();
          }}>
          {isPlaySound ? (
            <FontAwesomeIcon icon={faVolumeHigh} />
          ) : (
            <FontAwesomeIcon icon={faVolumeXmark} />
          )}
        </div>
      </div>
      {recommendedTime && recommendedTime !== '00:00:00' && (
        <div className="mx-2" style={{textAlign: 'center'}}>
          <p>{t('RecommendedTime')}:</p>
          <p style={{color: 'white'}}>{recommendedTime}</p>
        </div>
      )}
    </div>
  );
};

export default Timer;
