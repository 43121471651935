import {AppDispatch} from '../../store';
import axios from '../../axios';
import {AddUserToGroupDto, GroupDto} from '../../models/strict/accountStrictModel';
import {groupSlice} from '../../store/account/slices/group.slice';
export const getGroups = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<GroupDto[]>('api/group/GetMyGroups', {});
      if (response.status >= 300) {
        dispatch(groupSlice.actions.groupFetchingError);
      } else {
        dispatch(groupSlice.actions.groupsFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getRegistrationState = (email: string, code: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<AddUserToGroupDto>('api/group/getRegistrationState', {
        params: {
          email: email,
          code: code,
        },
      });
      if (response.status <= 300) {
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const AddGroup = (Group: GroupDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post('api/group', Group);
      if (response.status >= 300) {
        dispatch(groupSlice.actions.groupFetchingError);
      } else {
        dispatch(groupSlice.actions.groupFetchingSuccess(response.data));
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const UpdateGroup = (Group: GroupDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put('api/group', Group);
      if (response.status >= 300) {
        dispatch(groupSlice.actions.groupFetchingError);
      } else {
        dispatch(groupSlice.actions.groupFetchingSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const DeleteGroup = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete('api/group', {
        params: {
          GroupId: id,
        },
      });
      if (response.status >= 300) {
        dispatch(groupSlice.actions.groupFetchingError);
      } else {
        dispatch(groupSlice.actions.groupFetchingSuccess(response.data));
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
