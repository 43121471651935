import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useState} from 'react';
import SessionWrapper from '../../components/trainings/sessions/sessions/sessionWrapper.component';
import {SessionDto} from '../../models/trainingModel';
import {SetFormula, getCurrentUserInfo, getUserInfo} from '../../actions/account/account.actions';
import {useAppDispatch} from '../../hooks/redux';
import {deleteSession, getSessions} from '../../actions/trainings/session.action';
import MealWrapper from '../../components/meals/meals/mealWrapper';
import {MealDTO} from '../../models/mealModel';
import {getMeals} from '../../actions/meals/meal.action';
import WeightInput from '../../components/common/weightInput.component';
import {CommonSelectInput} from '../../components/common/input/commonSelectInput.component';
import {createKeyValuePairsFromEnum} from '../../utils/enumUtil';
import {KcalFormulaEnum, UserInfoDTO} from '../../models/accountModel';
import {keyValuePair} from '../../models/common/keyValuePair';
import {DateTime} from 'luxon';
import DateNavigator from '../../components/common/dateNavigator.component';
export interface UserProfilePageComponentProperties {
  id?: string;
}
function UserProfilePage({id}: UserProfilePageComponentProperties) {
  const [lastSessions, SetLastSessions] = useState<SessionDto[]>();
  const [lastMeals, SetMeals] = useState<MealDTO[]>();
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<UserInfoDTO>();
  const refreshHandler = () => {
    dispatch(getSessions(id)).then(result => {
      SetLastSessions(result);
    });
    dispatch(getMeals('', id)).then(result => {
      SetMeals(result);
    });
  };

  useEffect(() => {
    dispatch(getUserInfo(id)).then(f => {
      if (f != null) {
        setUser(f);
      }
    });
    refreshHandler();
  }, []);

  const getSelectedKeyValuePair = (): keyValuePair | undefined => {
    const keyValuePairs = createKeyValuePairsFromEnum(KcalFormulaEnum);
    return keyValuePairs.find(pair => pair.key === user?.kcalFormulaEnum);
  };

  if (user != null) {
    return (
      <div className="content flex-row w-full  row px-2">
        {currentDate != null && (
          <DateNavigator
            currentDate={currentDate}
            onDateChange={(newDate: DateTime) => {
              setCurrentDate(newDate);
            }}
          />
        )}
        <div className="flex" style={{justifyContent: 'space-between'}}>
          <WeightInput user={user} />
          <CommonSelectInput
            selectedValue={getSelectedKeyValuePair()}
            inputValues={createKeyValuePairsFromEnum(KcalFormulaEnum)}
            onUpdate={function (value: string): void {
              const newValue = Number.parseInt(value);
              dispatch(SetFormula(newValue));
            }}
            error={false}
            errorMessage={''}
          />
        </div>
        <div
          className="col-xs-12 col-lg-5 primaryBackground color-white p-2 border-custom mx-2"
          style={{height: 'fit-content'}}>
          <MealWrapper
            refreshHandle={() => {
              refreshHandler();
            }}
            isForToday={true}
            user={user}
            list={lastMeals}
            weight={user.weights?.last}
          />
        </div>

        <div
          className="col-xs-12 primaryBackground color-white border-custom  p-2  col-lg-5 mx-2"
          style={{height: 'fit-content'}}>
          <SessionWrapper
            refreshHandle={() => {
              refreshHandler();
            }}
            onDeleteHandler={(sessionId: string) => {
              dispatch(deleteSession(sessionId)).then(f => {
                refreshHandler();
              });
            }}
            user={user}
            list={lastSessions}
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default UserProfilePage;
