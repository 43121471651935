import React, {useState} from 'react';
import {keyValuePair} from '../../../models/common/keyValuePair';
import {CommonSelectInput} from '../input/commonSelectInput.component';
import {useTranslation} from 'react-i18next';
import {locales} from './localelist';

interface LocaleSelectorProps {
  onLocaleChange: (value: string) => void;
  selectedLocale?: keyValuePair | undefined;
  selectedLocaleKey?: string | undefined;
  className: string;
}

export function LocaleSelector({
  onLocaleChange,
  selectedLocale,
  className,
  selectedLocaleKey,
}: LocaleSelectorProps) {
  const {t} = useTranslation();
  return (
    <CommonSelectInput
      inputValues={locales}
      selectedValue={selectedLocale}
      selectedKey={selectedLocaleKey}
      onUpdate={onLocaleChange}
      error={false}
      errorMessage=""
      className={className}
      labelValue={t('SELECT_LANGUAGE')}
    />
  );
}
