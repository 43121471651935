import {createSlice} from '@reduxjs/toolkit';
import {SessionDto} from '../../../models/trainingModel';
import {SessionObject} from '../../../models/strict/sessionStrictModel';
import type {PayloadAction} from '@reduxjs/toolkit';
interface sessionState {
  session: SessionDto;
  sessions: SessionDto[];
  sessionsContainer: SessionDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: sessionState = {
  session: new SessionObject([]),
  sessions: [],
  sessionsContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    sessionFetching(state) {
      state.loading = true;
    },
    sessionFetchingSuccess(state, action: PayloadAction<SessionDto>) {
      state.error = '';
      state.session = action.payload;
      state.loading = false;
    },
    sessionFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    sessionsFetchingSuccess(state, action: PayloadAction<SessionDto[]>) {
      state.error = '';
      state.sessions = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    sessionsFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default sessionSlice.reducer;
