import React from 'react';
import classes from './plateIcon.module.scss';
export interface plateProperties {
  onClickHandler?: () => void;
  height?: number;
  width?: number;
  wrapperStyle?: React.CSSProperties;
  margin?: string;
  classNaming?: string;
}
export default function PlateIcon({
  height = 30,
  width = 30,
  classNaming,
  wrapperStyle,
  onClickHandler,
}: plateProperties) {
  return (
    <svg
      onClick={onClickHandler}
      className={classNaming + ' ' + classes.plateIcon}
      width={width}
      height={height}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511 511"
      xmlSpace="preserve">
      <g>
        <path
          d="M509.956,195.683c-1.35-2.282-3.804-3.683-6.456-3.683H7.5c-2.652,0-5.106,1.4-6.456,3.683
          c-1.35,2.283-1.394,5.108-0.116,7.432c9.115,16.573,34.091,37.352,65.181,54.228c19.151,10.395,55.829,27.4,93.891,29.453v8.705
          c0,12.958,10.542,23.5,23.5,23.5h144c12.958,0,23.5-10.542,23.5-23.5v-8.705c38.062-2.053,74.74-19.058,93.891-29.453
          c31.09-16.876,56.066-37.655,65.181-54.228C511.35,200.791,511.306,197.965,509.956,195.683z M327.5,304h-144
          c-4.687,0-8.5-3.813-8.5-8.5V287h161v8.5C336,300.187,332.187,304,327.5,304z M343.5,272h-176c-52.233,0-119.003-36.655-145.105-65
          h466.211C462.503,235.345,395.734,272,343.5,272z"
        />
        <path
          d="M295.5,255h48c7.969,0,16.552-0.931,25.509-2.768c4.058-0.832,6.672-4.796,5.84-8.854s-4.802-6.67-8.854-5.84
          C358.028,239.172,350.459,240,343.5,240h-48c-4.142,0-7.5,3.358-7.5,7.5S291.358,255,295.5,255z"
        />
        <path
          d="M399.505,242.548c0.929,0,1.875-0.174,2.79-0.542c2.854-1.145,5.757-2.375,8.629-3.658
       c3.782-1.688,5.479-6.124,3.791-9.906c-1.688-3.783-6.124-5.481-9.906-3.791c-2.698,1.205-5.423,2.359-8.099,3.434
       c-3.845,1.542-5.71,5.909-4.168,9.753C393.716,240.768,396.53,242.548,399.505,242.548z"
        />
      </g>
    </svg>
  );
}
