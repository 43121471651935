import {keyValuePair} from '../models/common/keyValuePair';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createKeyValuePairsFromEnum(enumObj: any): keyValuePair[] {
  const keyValuePairs: keyValuePair[] = [];

  for (const key in enumObj) {
    if (isNaN(Number(key))) {
      const value = enumObj[key];
      keyValuePairs.push(new keyValuePair(value, key));
    }
  }

  return keyValuePairs;
}
