import React, {ChangeEvent, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classes from './barCodeButton.module.scss';
import {faBarcode, faSearch} from '@fortawesome/free-solid-svg-icons';

export interface BarCodeButtonProperties {
  onClickBarScannerHandler: () => void;
  debounced: string;
}

export default function BarCodeButton({
  debounced,
  onClickBarScannerHandler,
}: BarCodeButtonProperties) {
  const {t} = useTranslation();

  return (
    <>
      {debounced?.length <= 3 && (
        <button
          className={classes.barCodeButton + ' ' + classesModal.addBtn}
          onClick={() => {
            onClickBarScannerHandler();
          }}>
          <FontAwesomeIcon icon={faBarcode} style={{color: 'white'}}></FontAwesomeIcon>
          <p className="my-2" style={{color: 'white'}}>
            {t('ScanBarCode')}
          </p>
        </button>
      )}
    </>
  );
}
