import {Route, Routes} from 'react-router-dom';
import AuthRoutes from './auth.routes';
import AnonymousRoutes from './anonymours.routes';
import React from 'react';
import NotFoundPage from '../pages/common/notFound.component';
export function Router() {
  return (
    <Routes>
      {AuthRoutes}
      {AnonymousRoutes}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default Router;
