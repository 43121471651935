import React, {useTransition} from 'react';
import {useTranslation} from 'react-i18next';

const NotFoundPage: React.FC = () => {
  const {t} = useTranslation();
  return (
    <div
      className="greyMenuBackground"
      style={{
        position: 'fixed',
        height: '100%',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 2,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <h1 style={{fontSize: '4rem'}}>404 - Oops!</h1>
      <div style={{display: 'flex'}}>
        <p>{t('LooksLost')}</p>
        <a style={{marginLeft: '10px'}} href="/">
          {' '}
          {t('GoMainPage')}
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
