import React, {useEffect, useState} from 'react';

import classes from './mealItemManager.module.scss';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCopy,
  faEdit,
  faScaleUnbalanced,
  faWeight,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import classColor from '../../../../shared/sharedColorConfigurations.module.scss';
import {MealDTO} from '../../../../../models/mealModel';
import {useAppDispatch} from '../../../../../hooks/redux';
import {shortCommonTimeFormat} from '../../../../../constants/common/dateFormat';

export interface MealItemProperties {
  meal: MealDTO;
  onEditMealClickHandler: (editedMeal: MealDTO) => void;
  onDeleteMealClickHandler: (deletedMeal: MealDTO) => void;
  onCopyMealClickHandler: (copyMeal: MealDTO) => void;
}
export default function MealItemManager({
  meal,
  onEditMealClickHandler,
  onDeleteMealClickHandler,
  onCopyMealClickHandler,
}: MealItemProperties) {
  const dispatch = useAppDispatch();
  const [isShowAdditionalInfo, setIsShowAdditionalInfo] = useState<boolean>(false);
  const {t} = useTranslation();

  return (
    <>
      <div
        key={meal.id}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
        <div style={{color: 'var( --textColor)', marginRight: '15px'}}>
          {meal.eatTime && DateTime.fromISO(meal.eatTime).toFormat(shortCommonTimeFormat)}
        </div>
        <li className={classes.mealInfo}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {meal.calories != null && !isNaN(meal.calories) && (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.mealInfoDetailInside}>{t('ccal')}</div>
                <div>{Math.floor(meal.calories)}</div>
              </div>
            )}
            {meal.carbohydrates != null && !isNaN(meal.carbohydrates) && (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.mealInfoDetailInside}>{t('carb')}</div>
                <div>{Math.floor(meal.carbohydrates)}</div>
              </div>
            )}
            {meal.proteins != null && !isNaN(meal.proteins) && (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.mealInfoDetailInside}>{t('protein')}</div>
                <div>{Math.floor(meal.proteins)}</div>
              </div>
            )}
            {meal.fats != null && !isNaN(meal.fats) && (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className={classes.mealInfoDetailInside}>{t('fat')}</div>
                <div>{Math.floor(meal.fats)}</div>
              </div>
            )}
          </div>
          <button
            className={classes.mealItemSchevron}
            onClick={() => {
              setIsShowAdditionalInfo(!isShowAdditionalInfo);
            }}>
            <FontAwesomeIcon
              icon={!isShowAdditionalInfo ? faChevronDown : faChevronUp}
              className={classColor.sidebarColor}></FontAwesomeIcon>
          </button>
        </li>
      </div>
      {isShowAdditionalInfo && meal && meal?.foods && meal?.foods?.length > 0 && (
        <ul
          className={classes.mealFoodInfoWrapper}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {meal.foods.map((f, mealIndex) => (
            <li
              className={classes.mealInfo + ' ' + classes.mealFoodInfo}
              key={mealIndex}
              style={{width: '100%', justifyContent: 'space-around'}}>
              <div title={f.food?.name || ''}>
                {f.food?.name && f.food?.name!.length > 20
                  ? `${f.food?.name!.slice(0, 10)}...`
                  : f.food?.name}
              </div>

              <div style={{display: 'flex', flexDirection: 'row'}}>
                {f.weight && f.food?.macronutrients?.calories && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('ccal')}</div>
                    <div>{Math.floor(f.food?.macronutrients?.calories * (f.weight / 100))}</div>
                  </div>
                )}
                {f.weight && f.food?.macronutrients?.carbohydrates && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('carb')}</div>
                    <div>
                      {Math.floor(f.food?.macronutrients?.carbohydrates * (f.weight / 100))}
                    </div>
                  </div>
                )}
                {f.weight && f.food?.macronutrients?.proteins && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('protein')}</div>
                    <div>{Math.floor(f.food?.macronutrients?.proteins * (f.weight / 100))}</div>
                  </div>
                )}
                {f.weight && f.food?.macronutrients?.fats && (
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div className={classes.mealInfoDetailInside}>{t('fat')}</div>
                    <div>{Math.floor(f.food?.macronutrients?.fats * (f.weight / 100))}</div>
                  </div>
                )}
              </div>
              {f.weight && (
                <div>
                  <FontAwesomeIcon
                    icon={faScaleUnbalanced}
                    className={classColor.sidebarBackgroundColor + ' mx-2'}
                  />
                  {f.weight}
                  {t('g')}
                </div>
              )}
            </li>
          ))}
          <li
            className={classes.mealItemButtonWrapper}
            style={{width: '100%', justifyContent: 'space-evenly', display: 'flex'}}>
            <button
              onClick={() => {
                onEditMealClickHandler(meal);
              }}>
              <FontAwesomeIcon
                icon={faEdit}
                className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
            </button>
            <button
              onClick={() => {
                onCopyMealClickHandler(meal);
              }}>
              <FontAwesomeIcon
                icon={faCopy}
                className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
            </button>
            <button
              onClick={() => {
                onDeleteMealClickHandler(meal);
              }}>
              <FontAwesomeIcon
                icon={faXmarkCircle}
                className={classes.mealInfoIcon + ' mx-2'}></FontAwesomeIcon>
            </button>
          </li>
        </ul>
      )}
    </>
  );
}
