import React, {useState, useEffect} from 'react';
import styles from './verticalSlider.module.scss'; // Assuming you have a CSS module
import classesColor from '../../shared/sharedColorConfigurations.module.scss'; // Color styles

interface VerticalSliderProps {
  numbers: number[];
  onUpdate: (value: number) => void;
}

export default function VerticalSlider({numbers, onUpdate}: VerticalSliderProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % numbers.length;
    setCurrentIndex(newIndex);
    onUpdate(numbers[newIndex]);
  };

  const handlePrev = () => {
    const newIndex = currentIndex === 0 ? numbers.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    onUpdate(numbers[newIndex]);
  };

  if (numbers.length > 1) {
    return (
      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          <div onClick={handlePrev} className={`${styles.slide}  `}>
            {currentIndex == 0 ? numbers[numbers.length - 1] : numbers[currentIndex - 1]}
          </div>
          <div className={`${styles.slide} ${styles.active}`}>{numbers[currentIndex]}</div>
          <div onClick={handleNext} className={`${styles.slide} `}>
            {currentIndex < numbers.length - 1 ? numbers[currentIndex + 1] : numbers[0]}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
