import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import classes from '../../../common/scss/list.module.scss';
import {useDebounce} from '../../../../hooks/debounce';
import {useAppDispatch} from '../../../../hooks/redux';
import {RestaurantDTO} from '../../../../models/mealModel';
import {getRestaurants} from '../../../../actions/meals/restaurant.action';
import {useTranslation} from 'react-i18next';
import classesModal from '../../../common/modal/styles/modalShared.module.scss';
export interface RestaurantInputProperties {
  selectRestaurant: (params: RestaurantDTO) => void;
  city?: string;
}
export default function RestaurantInput({selectRestaurant, city}: RestaurantInputProperties) {
  const [value, setValue] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const debounced = useDebounce<string>(value, 500);
  const [restaurants, SetRestaurants] = useState<RestaurantDTO[]>([]);
  async function searchRestaurants(search: string) {
    dispatch(getRestaurants(city, search)).then(f => {
      if (f == null) {
        SetRestaurants([]);
      } else {
        SetRestaurants(f);
      }
    });
  }
  const {t} = useTranslation();
  useEffect(() => {
    if (debounced.length >= 3) {
      searchRestaurants(debounced).then(() => setDropdown(true));
    } else {
      setDropdown(false);
    }
  }, [debounced]);

  function changeHandler(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function renderDropdown() {
    if (restaurants.length === 0) {
      return <p className="text-center">No results!</p>;
    }

    return restaurants.map(restaurant => (
      <li
        key={restaurant.id}
        onClick={() => {
          selectRestaurant(restaurant);
          setValue('');
        }}
        className={
          classes.searchedLi + ' cursor-pointer background-primary text-white px-3 my-2 p-2'
        }>
        {restaurant.name}
      </li>
    ));
  }

  return (
    <div className="darkModalBackground  p-4 relative">
      <label className="text-white" htmlFor="weight">
        {t('Restaurant')}{' '}
      </label>
      <div className="w-full flex my-4">
        <input
          className={classesModal.inputModal + '  w-full outline-0 h-[42px]'}
          type="text"
          onChange={changeHandler}
          value={value}
          placeholder="Search for restaurant..."
        />
      </div>

      {dropdown && <ul className={classes.listWrapper}>{renderDropdown()}</ul>}
    </div>
  );
}
