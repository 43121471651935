import React, {ChangeEvent, ChangeEventHandler, useState} from 'react';
import {CustomTextInput} from '../../../components/common/input/customTextInput.component';

import defaultClasses from '../../../components/common/input/commonInput.module.css';
import classes from '../../../layouts/auth.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons';
import ExlamationMarkIcon from '../../../components/common/icon/exlamationMark/exlamationMark.component';
import {UseControllerProps, useController} from 'react-hook-form';
import {keyValuePair} from '../../../models/common/keyValuePair';
interface AuthSelectInputProps {
  inputValue?: string;
  className?: string;
  wrapperClassName?: string;
  labelValue?: string;
  onUpdate: (value: string) => void;
  placeholder?: string;
  props: UseControllerProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: {key: any; value: any}[];
}

export function AuthSelect({
  inputValue,
  className,
  onUpdate,
  data,
  labelValue,
  placeholder,
  wrapperClassName = '',
  props,
}: AuthSelectInputProps) {
  const {field, fieldState} = useController(props);
  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    field.onChange(value);

    onUpdate(value);
  };
  const isError = (): boolean => fieldState.error?.message != null && fieldState.invalid;
  return (
    <div className={wrapperClassName} style={{position: 'relative', padding: '0px'}}>
      <div className={className} style={{position: 'relative', padding: '0px'}}>
        {labelValue != null && field?.value.length > 0 && (
          <label className={` col-12 ${isError() ? defaultClasses.error : ''}`}>{labelValue}</label>
        )}
        <select
          {...field}
          value={inputValue}
          placeholder={placeholder}
          onBlur={field.onBlur}
          style={{width: '100%', background: 'transparent'}}
          onChange={handleInputChange}
          className={classes.auth + ` ${isError() ? defaultClasses.error : ''}`}>
          {data.map(value => (
            <option
              style={{background: '#222222', margin: '10px 10px 0 0'}}
              key={value.key}
              value={value.key}>
              {value.value}
            </option>
          ))}
        </select>

        {isError() && (
          <ExlamationMarkIcon
            wrapperStyle={{
              position: 'absolute',
              right: 0,
              marginRight: 20,
              marginTop: 35,
              width: 50,
            }}
          />
        )}
      </div>
      {isError() && <p className={classes.errorMessage}>{fieldState.error?.message}</p>}
    </div>
  );
}
