import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import classesModal from '../../common/modal/styles/modalShared.module.scss';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';

export interface SaveBtnProperties {
  onClickHandler: () => void;
}

export default function SaveBtn({onClickHandler}: SaveBtnProperties) {
  return (
    <p
      className={classesModal.saveBtn}
      onClick={() => {
        onClickHandler();
      }}>
      +
    </p>
  );
}
