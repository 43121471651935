import {AppDispatch} from '../../store';
import axios from '../../axios';
import {scheduleSlice} from '../../store/trainings/slices/schedule.slice';

import {CreateScheduleDto, ScheduleDto} from '../../models/trainingModel';
import {TrainingApi} from './constant';
export const Schedule_API_URL = TrainingApi.TrainingApiPrefix + '/Schedule';
export const getSchedules = (userId?: string, from?: string, to?: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<ScheduleDto[]>(Schedule_API_URL + '/filter', {
        // paginationRequest: {
        //   currentPage: 0,
        //   pageSize: 50,
        // },
        from: from,
        to: to,
        userId: userId,
      });
      if (response.status >= 300) {
        dispatch(scheduleSlice.actions.ScheduleFetchingError);
      } else {
        return response.data;
      }
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const deleteSchedule = (ScheduleId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.delete(Schedule_API_URL + '/' + ScheduleId);
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addSchedules = (Schedule: CreateScheduleDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<ScheduleDto[]>(Schedule_API_URL, Schedule);
      return response;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
