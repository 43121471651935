import React, {useEffect, useState} from 'react';

import {TrainingComponent} from './TrainingComponent/trainingComponent.component';
import {TrainingDto} from '../../../models/trainingModel';

export interface TrainingListProperties {
  list?: TrainingDto[];
  clickDeleteHandler: (Training: TrainingDto, index: number) => void;
}
export function TrainingList({list, clickDeleteHandler}: TrainingListProperties) {
  useEffect(() => {
    console.log(list);
  }, []);
  function renderTrainingList() {
    return list!.map((Training: TrainingDto, index: number) => (
      <TrainingComponent
        key={index}
        training={Training}
        clickDeleteHandler={training => {
          clickDeleteHandler(training, index);
        }}
        clickAddHandler={function (AddTraining: TrainingDto): void {
          console.log(AddTraining);
          throw new Error('Function not implemented.');
        }}
      />
    ));
  }
  if (list == null || list.length === 0) {
    return <></>;
  }
  return (
    <div className="px-4 py-4 mb-4 relative my-2 background-primary flex justify-content-center ">
      {<table className="w-full px-4">{renderTrainingList()}</table>}
    </div>
  );
}
