import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ChangeEvent, useState} from 'react';
import {keyValuePair} from '../../../models/common/keyValuePair';
import ScrollableBody from '../scrollbar/scrollbar.component';

interface CustomTextInputProps {
  inputValues: keyValuePair[];
  className?: string;
  labelValue?: string;
  inputType?: string;
  selectedValue?: keyValuePair;
  selectedKey?: string;
  onUpdate: (value: string) => void;
  error: boolean;
  errorMessage: string;
}
export function SelectCustomInput({
  inputValues,
  selectedValue,
  selectedKey,
  onUpdate,
  error,
  errorMessage,
  inputType = 'text',
  className,
  labelValue,
}: CustomTextInputProps) {
  const handleInputChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    onUpdate(value);
  };

  return (
    <>
      {labelValue != null && <label className={className}>{labelValue}</label>}
      {inputValues && (
        <select
          placeholder=""
          onChange={handleInputChange}
          className={`${className} ${error ? 'error' : ''}`}>
          {inputValues.map(value => (
            <option
              className={className}
              key={value.key}
              value={value.key}
              selected={
                (selectedValue != undefined && selectedValue.key == value.key) ||
                (selectedKey != undefined && selectedKey == value.key)
              }>
              {value.value}
            </option>
          ))}
        </select>
      )}

      {error && <p className="error-message">{errorMessage}</p>}
    </>
  );
}
