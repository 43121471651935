/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {TrainingObject} from '../../../models/strict/sessionStrictModel';
import ModalWrapper from '../../common/modal/modalWrapper/modalWrapper.component';
import {SetDto, TrainingDto} from '../../../models/trainingModel';
import {TrainingComponent} from '../trainings/TrainingComponent/trainingComponent.component';
import AddEditTrainingComponent from '../trainings/addEditTraining/addEditTraining.component';
import {TrainingList} from '../trainings/trainingsList.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
export interface SetProperties {
  set: SetDto; // sets
  addTrainingHandler?: (params: any) => any;
  editSetHandler?: (params: SetDto) => any;
  removeTrainingHandler: (params: TrainingDto, set: SetDto, index: number) => any;
  isItEC: boolean;
}
export function SetComponent({
  set,
  isItEC,
  addTrainingHandler,
  editSetHandler,
  removeTrainingHandler,
}: SetProperties) {
  return (
    <div style={{border: '1px black solid'}} className="relative">
      {editSetHandler != null && set.id != null && (
        <div className="ml-2 mt-2 absolute right-0 top-0">
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => {
              editSetHandler(set);
            }}
          />
        </div>
      )}
      {set.trainings && (
        <TrainingList
          list={set.trainings}
          clickDeleteHandler={(training, index) => {
            if (removeTrainingHandler != null) {
              removeTrainingHandler(training, set, index);
            }
          }}
        />
      )}

      {isItEC && (
        <AddEditTrainingComponent
          trainingInput={new TrainingObject()}
          saveHandler={training => {
            console.log(training);
          }}
        />
      )}
    </div>
  );
}
