import React from 'react';
import {createSlice} from '@reduxjs/toolkit';
import {TrainingDto} from '../../../models/trainingModel';
import type {PayloadAction} from '@reduxjs/toolkit';
interface trainingState {
  trainings: TrainingDto[];
  trainingsContainer: TrainingDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: trainingState = {
  trainings: [],
  trainingsContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    trainingFetching(state) {
      state.loading = true;
    },
    trainingFetchingSuccess(state, action: PayloadAction<TrainingDto>) {
      state.error = '';
      state.loading = false;
    },
    trainingFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    trainingsFetchingSuccess(state, action: PayloadAction<TrainingDto[]>) {
      state.error = '';
      state.trainings = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    trainingsFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default trainingSlice.reducer;
