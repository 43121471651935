/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode} from 'react';
import classes from './modalWrapper.module.scss';
import {Box, Modal} from '@mui/material';
import ScrollableBody from '../../scrollbar/scrollbar.component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
export interface ModalWrapperProperties {
  handleClose: (params: any) => any;
  children: React.ReactNode;
  headerName?: string;
  hideButtonPanel?: boolean;
  isOpen: boolean;
}
export default function ModalWrapper({
  handleClose,
  children,
  headerName,
  isOpen,
}: ModalWrapperProperties) {
  const wrapperHandleClose = () => {
    if (handleClose != null) {
      handleClose(undefined);
    }
  };
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // border: '1px solid var(--primaryColor)',
    border: '1px solid rgba(255,255,255,0.5)',
  };
  return (
    <Modal
      open={isOpen}
      onClose={wrapperHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style} className={classes.modalBox}>
        <div className={classes.buttonWrapper}>
          <button>
            <FontAwesomeIcon
              icon={faXmark}
              className={classes.closeIcon}
              onClick={() => {
                wrapperHandleClose();
              }}
            />
          </button>
        </div>
        {/* <ScrollableBody maxHeight="100vh" minHeight="fit-content" height="fit-content"> */}
        {children}
        {/* </ScrollableBody> */}
      </Box>
    </Modal>
  );
}
