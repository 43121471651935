import React, {ChangeEvent, useEffect, useState} from 'react';
import {UseControllerProps, useController} from 'react-hook-form';
import classesAuth from '../../../../layouts/auth.module.scss';
import ExlamationMarkIcon from '../../../../components/common/icon/exlamationMark/exlamationMark.component';
import defaultClasses from '../../../../components/common/input/commonInput.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import classes from './authPasswordInput.module.scss';
interface AuthPasswordInputProps {
  onUpdate: (value: string | number) => void;
  labelValue: string;
  className?: string;
  props: UseControllerProps;
  placeholder?: string;
  autocomplete?: string;
  name?: string;
}

export function AuthPasswordInput({
  onUpdate,
  labelValue,
  props,
  className,
  placeholder,
  autocomplete,
  name,
}: AuthPasswordInputProps) {
  const {field, fieldState} = useController(props);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    field.onChange(value);
    onUpdate(value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(prev => !prev);
  };

  const isError = (): boolean => fieldState.error?.message != null && fieldState.invalid;

  return (
    <div className={classesAuth.wrapperInput}>
      <div
        style={{
          position: 'relative',
          padding: '0px',
          margin: '0px',
          height: '100%',
          alignItems: 'center',
        }}
        className={classesAuth.auth + ' ' + className}>
        {field?.value.length > 0 && (
          <label className={classesAuth.auth + ` col-12 ${isError() ? classesAuth.error : ''}`}>
            {labelValue}
          </label>
        )}
        <div className={classes.eyePasswordBtnWrapper}>
          {isError() && (
            <ExlamationMarkIcon
              wrapperStyle={{
                width: 50,
              }}
            />
          )}
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className={classes.eyePasswordBtn}>
            {isPasswordVisible ? (
              <FontAwesomeIcon icon={faEye} />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} />
            )}
          </button>
        </div>
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          {...field}
          placeholder={placeholder}
          onBlur={field.onBlur}
          onChange={handleInputChange}
          className={`${classesAuth.auth} ${classesAuth.authWrapperInnerInput} ${
            isError() ? defaultClasses.error : ''
          }`}
          style={{width: '100%'}}
          name={name}
          autoComplete={autocomplete || 'off'}
        />
      </div>
      {isError() && (
        <p style={{marginBottom: 0}} className={classesAuth.authErrorMessage}>
          {fieldState.error?.message}
        </p>
      )}
    </div>
  );
}
