import {AppDispatch} from '../../store';
import axios from '../../axios';
import {queries} from '@testing-library/react';
import {RestaurantPortionDTO} from '../../models/mealModel';
export const getRestaurantPortions = (restaurantId?: string, name?: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<RestaurantPortionDTO[]>('api/restaurantPortion', {
        params: {
          restaurantId: restaurantId,
          name: name,
        },
      });
      return response.data;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const getRestaurantPortion = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<RestaurantPortionDTO>('api/restaurantPortion/' + id);
      return response.data;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
export const addRestaurantPortion = (RestaurantPortion: RestaurantPortionDTO) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<RestaurantPortionDTO[]>(
        'api/restaurantPortion',
        RestaurantPortion,
      );
      return response.data;
    } catch (e) {
      console.log('Error Login', e);
    }
  };
};
