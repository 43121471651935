import {createSlice} from '@reduxjs/toolkit';
import {TrainingPlanDto} from '../../../models/trainingModel';
import type {PayloadAction} from '@reduxjs/toolkit';
interface trainingPlanState {
  trainingPlans: TrainingPlanDto[];
  trainingPlansContainer: TrainingPlanDto[];
  count: number;
  loading: boolean;
  error: string;
}

const initialState: trainingPlanState = {
  trainingPlans: [],
  trainingPlansContainer: [],
  count: 0,
  loading: false,
  error: '',
};

export const trainingPlanSlice = createSlice({
  name: 'trainingPlan',
  initialState,
  reducers: {
    trainingPlanFetching(state) {
      state.loading = true;
    },
    trainingPlanFetchingSuccess(state, action: PayloadAction<TrainingPlanDto>) {
      state.error = '';
      state.loading = false;
    },
    trainingPlanFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
    trainingPlansFetchingSuccess(state, action: PayloadAction<TrainingPlanDto[]>) {
      state.error = '';
      state.trainingPlans = action.payload;
      state.count = action.payload.length;
      state.loading = false;
    },
    trainingPlansFetchingError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default trainingPlanSlice.reducer;
