import {DateTime} from 'luxon';
import {
  CreateMealDTO,
  CreateUpdateFoodMealDTO,
  FoodDTO,
  FoodMealDTO,
  Macronutrients,
  MealDTO,
  RestaurantDTO,
  Location,
  RestaurantPortionDTO,
} from '../mealModel';
export class MealDayPerPage {
  constructor(
    public date: DateTime,
    public isRequested: boolean,
    public meals?: MealDTO[],
  ) {}
}
export interface NutrimentsDto {
  energy: number;
  carbohydrates: number;
  proteins: number;
  fat: number;
}

export interface ResponseProductDataDto {
  name: string;
  brands?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nutriments: Record<string, any>;
  nutrimentsParsed: NutrimentsDto;
}
export class MealObject implements MealDTO {
  constructor(
    public id?: string,
    public userId?: string,
    public macronutrients?: MacronutrientsObject,
    public eatTime?: string,
    public foods?: FoodMealObject[],
  ) {}
}
export class CreateMealObject implements CreateMealDTO {
  constructor(
    public id?: string,
    public userId?: string,
    public eatTime?: string,
    public foods?: CreateUpdateFoodMealObject[],
  ) {}
}

export class CreateUpdateFoodMealObject implements CreateUpdateFoodMealDTO {
  constructor(
    public id?: string,
    public mealId?: string,
    public foodId?: string,
    public weight?: number,
  ) {}
}
export class MacronutrientsObject implements Macronutrients {
  constructor(
    public proteins?: number,
    public fats?: number,
    public carbohydrates?: number,
    public calories?: number,
  ) {}
}
export class FoodMealObject implements FoodMealDTO {
  constructor(
    public id?: string,
    public mealId?: string,
    public foodId?: string,
    public weight?: number,
    public food?: FoodDTO,
  ) {}
}
export interface UpdateMealDTO {
  id: string;
  /** @format uuid */
  userId?: string;
  /** @format date-time */
  eatTime?: string;
  foods?: CreateUpdateFoodMealDTO[] | null;
}
export class FoodDTOObject implements FoodDTO {
  constructor(
    public name?: string,
    public description?: string,
    public macronutrients?: Macronutrients,
    public restaurantId?: string,
    public userId?: string,
    public id?: string,
  ) {}
}
export class RestaurantPortionObject implements RestaurantPortionDTO {
  constructor(
    public id?: string,
    public food?: FoodDTO,
    public weight?: number,
  ) {}
}

export class RestaurantObject implements RestaurantDTO {
  constructor(
    public id?: string,
    public ownerUserId?: string,
    public name?: string | null,
    public description?: string | null,
    public location?: LocationObject,
    public foods?: FoodDTOObject[],
    public isDeleted?: boolean,
    public deletedAt?: string | null,
  ) {}
}
export class LocationObject implements Location {
  constructor(
    public country?: string | null,
    public city?: string | null,
    public latitude?: number,
    public longitude?: number,
  ) {}
}
export interface CreateWeightModifierDto {
  ownerUserId: string;
  name: string;
  description?: string;
  weight: number;
}
export interface WeightModifierDto {
  id: string;
  ownerUserId: string;
  name: string;
  description?: string;
  weight: number;
}
export interface WeightModifierFilter {
  name?: string;
  userId: string;
}
