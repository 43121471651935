import axios from '../../axios';

import {
  CreateWeightModifierDto,
  WeightModifierDto,
  WeightModifierFilter,
} from '../../models/strict/mealStrictModel';
import {AuthApi} from './constants';
import {AppDispatch} from '../../store';

// Set base API URL and headers
const weightModifierApiUrl = `${AuthApi.MealApiPrefix}/WeightModifier`;

export const filterWeightModifiers = (signal: AbortSignal, filter: WeightModifierFilter) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<WeightModifierDto[]>(
        weightModifierApiUrl + `/filter`,
        filter,
        {
          headers: {'Content-Type': 'application/json'},
        },
      );
      // Dispatch results as needed
      return response.data;
    } catch (error) {
      console.error('Error filtering weight modifiers', error);
    }
  };
};
export const getWeightModifierById = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get<WeightModifierDto>(`${weightModifierApiUrl}/${id}`, {
        headers: {'Content-Type': 'application/json'},
      });
      return response.data;
    } catch (error) {
      console.error('Error getting weight modifier by ID', error);
    }
  };
};
export const createWeightModifier = (weightModifier: CreateWeightModifierDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post<WeightModifierDto>(weightModifierApiUrl, weightModifier, {
        headers: {'Content-Type': 'application/json'},
      });
      return response.data;
    } catch (error) {
      console.error('Error creating weight modifier', error);
    }
  };
};
export const updateWeightModifier = (weightModifier: WeightModifierDto) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put<WeightModifierDto>(weightModifierApiUrl, weightModifier, {
        headers: {'Content-Type': 'application/json'},
      });
    } catch (error) {
      console.error('Error updating weight modifier', error);
    }
  };
};
export const deleteWeightModifier = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await axios.delete(`${weightModifierApiUrl}/${id}`, {
        headers: {'Content-Type': 'application/json'},
      });
    } catch (error) {
      console.error('Error deleting weight modifier', error);
    }
  };
};
