import React, {ReactNode} from 'react';
import {Scrollbar} from 'react-scrollbars-custom';
import classes from './scrollbar.module.scss';
interface ScrollableWrapperProps {
  children: ReactNode;
  minHeight?: string;
  maxHeight?: string;
  height?: string;
  className?: string;
}

const ScrollableBody: React.FC<ScrollableWrapperProps> = ({
  children,
  minHeight,
  maxHeight = '200px',
  height = 'auto',
  className,
}) => {
  return (
    <Scrollbar
      style={{maxHeight: maxHeight ?? '', height: height ?? '', minHeight: minHeight ?? ''}}
      thumbYProps={{
        className: classes.customScrollable,
      }}
      thumbXProps={{
        className: classes.customScrollable,
      }}
      trackYProps={{style: {marginLeft: '10px'}}}>
      <div className={className} style={{paddingRight: '15px'}}>
        {children}
      </div>
    </Scrollbar>
  );
};

export default ScrollableBody;
