import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AddUserToGroupDto,
  getRoleName,
  UserGroupRoleEnum,
} from '../../../../models/strict/accountStrictModel';
import classModal from '../../../common/modal/styles/modalShared.module.scss';
import classStruct from '../../../shared/sharedComponent.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {SexEnum} from '../../../../models/accountModel';

export interface AddUserToGroupComponentProperties {
  saveHandler: (newEn: AddUserToGroupDto) => void;
  groupId: string;
}

export default function AddUserToGroupComponent({
  groupId,
  saveHandler,
}: AddUserToGroupComponentProperties) {
  const {t} = useTranslation();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [roles, setRoles] = useState<number[]>([]);
  const [sex, setSex] = useState<SexEnum>(SexEnum.Value0);

  const handleRoleChange = (role: UserGroupRoleEnum, isChecked: boolean) => {
    const roleValue = role as number;
    setRoles(prevRoles => {
      if (isChecked) {
        return [...prevRoles, roleValue];
      } else {
        return prevRoles.filter(r => r !== roleValue);
      }
    });
  };

  const handleSubmit = () => {
    const newUser = new AddUserToGroupDto(
      firstName,
      lastName,
      sex,
      email,
      roles,
      groupId,
      expirationDate,
    );
    saveHandler(newUser);
  };

  return (
    <div
      className={'row col-12 px-4 py-2 pb-0 relative color-white ' + classModal.backgroundModal}
      style={{marginLeft: 0}}>
      <p>{t('InviteGroupText')}</p>

      {/* First Name */}
      <div className="col-6">
        <label htmlFor="firstName" className="my-4">
          {t('FirstName')}
        </label>
        <input
          id="firstName"
          className={
            'border px-4 py-2 w-full outline-0 h-[42px] ' + classStruct.transparentInputGrey
          }
          name="firstName"
          type="text"
          required
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
        />
      </div>

      {/* Last Name */}
      <div className="col-6">
        <label htmlFor="lastName" className="my-4">
          {t('LastName')}
        </label>
        <input
          id="lastName"
          className={
            'border px-4 py-2 w-full outline-0 h-[42px] ' + classStruct.transparentInputGrey
          }
          name="lastName"
          type="text"
          required
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />
      </div>

      {/* Email */}
      <div className="col-6">
        <label htmlFor="email" className="my-4">
          {t('Email')}
        </label>
        <input
          id="email"
          className={
            'border px-4 py-2 w-full outline-0 h-[42px] ' + classStruct.transparentInputGrey
          }
          name="email"
          type="email"
          required
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
      </div>

      {/* Expiration Date */}
      <div className="col-6">
        <label htmlFor="expirationDate" className="my-4">
          {t('ExpirationDate')}
        </label>
        <input
          id="expirationDate"
          className={
            'border px-4 py-2 w-full outline-0 h-[42px] ' + classStruct.transparentInputGrey
          }
          name="expirationDate"
          type="date"
          value={expirationDate ?? undefined}
          onChange={event => setExpirationDate(event.target.value)}
        />
      </div>

      {/* Sex */}
      <div className="col-6">
        <label htmlFor="sex" className="my-4">
          {t('Sex')}
        </label>
        <select
          id="sex"
          className={
            'border px-4 py-2 w-full outline-0 h-[42px] ' + classStruct.transparentInputGrey
          }
          value={sex}
          onChange={event => setSex(Number(event.target.value) as SexEnum)}>
          <option value={SexEnum.Value0}>{t('Sex0')}</option>
          <option value={SexEnum.Value1}>{t('Sex1')}</option>
          <option value={SexEnum.Value2}>{t('Sex2')}</option>
        </select>
      </div>

      {/* Roles */}
      <div className="col-12 my-4">
        <label className="my-4">{t('Roles')}</label>
        <div>
          {Object.values(UserGroupRoleEnum)
            .filter(value => typeof value === 'number')
            .map(role => (
              <label key={role} className="mr-4">
                <input
                  type="checkbox"
                  value={role}
                  onChange={event =>
                    handleRoleChange(role as UserGroupRoleEnum, event.target.checked)
                  }
                />
                {t(getRoleName(role as UserGroupRoleEnum))}
              </label>
            ))}
        </div>
      </div>

      {/* Save Button */}
      <button className={classModal.saveBtn} onClick={handleSubmit}>
        +
      </button>
    </div>
  );
}
